import React, { Fragment, useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import HeaderText from "../../../views/components/HeaderText";
import ModalInsert from "../../../views/components/ModalInsert";
import InputText from "../../../views/components/InputText";
import ParagraphText from "../../../views/components/ParagraphText";
import Datepicker from "../../../views/components/Datepicker";
import dayjs from "dayjs";
import { formatPriceWithCurrencySymbol } from "../../../shared/utility";
import { closeModal } from "../../../state/reducers/global";
import SkeletonRows from "../../../views/components/Skeletons/SkeletonRows";
import BidInputError from "../../../views/components/BidInput/child-components/BidInputError";
import * as consts from "./const";
import Switch from "react-switch";
import COLOURS from "../../../views/theme/base/colours";
import BucketTypeFilter from "../../../views/components/BucketTypeFilter";
import Button from "../../../views/components/Button";
import { useMediaQuery } from "react-responsive";
import breakpoints from "../../../views/theme/breakpoints";
import { TIME_REGEX } from "../../../shared/utility";
import Icon from "../../../views/components/Icon";

const VendorVehicleRecordPublishModal = ({
  className,
  salesInformationData,
  salesInformationFetching,
  onConfirmHandle,
  profileInformationData,
  profileInformationFetching,
  primaryInformationData,
  primaryInformationFetching,
}) => {
  const dispatch = useDispatch();
  const isDesktop = useMediaQuery({
    query: `(min-width: ${breakpoints.LARGE_DESKTOP})`,
  });
  const [biddingEndDate, setBiddingEndDate] = useState("");
  const [validBiddingEndDate, setValidBiddingEndDate] = useState(true);
  const [biddingEndTime, setBiddingEndTime] = useState("");
  const [validBiddingEndTime, setValidBiddingEndTime] = useState(true);
  const [biddingEndDateTime, setBiddingEndDateTime] = useState("");
  const [publishSessionType, setPublishSessionType] = useState("FixedPrice");
  const [allowBuyNow, setAllowBuyNow] = useState(true);
  const [validation, setValidation] = useState([]);
  const [validationSuggestions, setValidationSuggestions] = useState([]);

  const handleDateChange = (date) => {
    const finalFormattedDate = dayjs(date).format("YYYY-MM-DD");
    validBiddingEndDateCheck(finalFormattedDate);
    setBiddingEndDate(finalFormattedDate);
  };

  const onCancel = () => {
    dispatch(closeModal());
  };

  const validBiddingEndDateCheck = (date) => {
    if (!date || !dayjs(date, "YYYY-MM-DD", true).isValid()) {
      setValidBiddingEndDate(false);
    } else {
      setValidBiddingEndDate(true);
    }
  };

  const buyNowPercentage =
    salesInformationData?.salesInfo?.currentValuation !== null
      ? (
          (salesInformationData?.salesInfo?.buyNowPrice /
            salesInformationData?.salesInfo?.currentValuation) *
          100
        ).toFixed(0)
      : "-";

  const radioOptions = [
    {
      label: consts.sessionTypeLabels.fixedPrice,
      value: "FixedPrice",
    },
    {
      label: consts.sessionTypeLabels.timed,
      value: "Timed",
    },
    {
      label: consts.sessionTypeLabels.openEnded,
      value: "OpenEnded",
    },
  ];

  const salesInformationDetail = [
    {
      label: consts.headers.buyNowPrice,
      value: salesInformationData?.salesInfo?.buyNowPrice,
      extra: ` (${buyNowPercentage}%)`,
    },
    {
      label: consts.headers.bidStartAtPrice,
      value: salesInformationData?.salesInfo?.bidStartAtPrice,
    },
    {
      label: consts.headers.auctionReservePrice,
      value: salesInformationData?.salesInfo?.auctionReservePrice,
    },
    {
      label: consts.headers.onlineReservePrice,
      value: salesInformationData?.salesInfo?.onlineReservePrice,
    },
    {
      label: consts.headers.retailPrice,
      value: salesInformationData?.salesInfo?.retailPrice,
    },
    {
      label: consts.headers.capValuation,
      value: salesInformationData?.salesInfo?.currentValuation,
    },
  ];

  const validData =
    publishSessionType &&
    (publishSessionType !== "Timed" ||
      (biddingEndDate &&
        validBiddingEndDate &&
        biddingEndTime &&
        validBiddingEndTime)) &&
    validation.every((item) => item.value);

  const handleTimeChange = (e) => {
    let value = e.target.value.replace(/[^0-9]/g, "");
    if (value.length >= 3) {
      value = value.slice(0, 2) + ":" + value.slice(2, 4);
    }
    const isValidTime = TIME_REGEX.test(value);
    setBiddingEndTime(value);
    setValidBiddingEndTime(isValidTime);
  };

  const checkValidTimeValue = (time) => {
    const isValidTime = TIME_REGEX.test(time);
    if (isValidTime) {
      const formattedTime = dayjs(time, "HH:mm").format("HH:mm");
      setBiddingEndTime(formattedTime);
    }
    setValidBiddingEndTime(isValidTime);
  };

  const checkPublishPrerequisite = (
    salesInfo,
    primaryInfo,
    publishSessionType
  ) => {
    const validation = [];

    // check if VAT status is not unknown
    validation.push({
      label: consts.validationLabels.vatStatus,
      value: salesInfo.vatStatus && salesInfo.vatStatus !== "Unknown",
    });

    // check if sales account is not null
    validation.push({
      label: consts.validationLabels.salesAccount,
      value: !!salesInfo.salesAccount,
    });

    if (publishSessionType === "FixedPrice" || allowBuyNow) {
      // check if buy now price is not 0
      validation.push({
        label: consts.validationLabels.buyNowPrice,
        value:
          salesInfo.buyNowPrice !== 0 || publishSessionType === "FixedPrice",
      });
    }

    if (publishSessionType !== "FixedPrice") {
      // check if auction reserve price is not 0
      validation.push({
        label: consts.validationLabels.auctionReservePrice,
        value:
          salesInfo.auctionReservePrice !== 0 ||
          publishSessionType === "FixedPrice",
      });

      if (primaryInfo.keyInfo.hasOnlineProfile) {
        // check if profile has FBS
        validation.push({
          label: consts.validationLabels.biddingWithFbs,
          value: !profileInformationData?.some((profile) =>
            profile.profile?.some((item) => item.finalBiddingSession === true)
          ),
        });
      }
    }
    if (
      primaryInfo.keyInfo &&
      !primaryInfo.keyInfo.hasOnlineProfile &&
      !primaryInfo.keyInfo.allocatedToSite
    ) {
      // check if profile has online profile or allocated to site -- Once backend has been tweaked this will need to be updated
      validation.push({
        label: consts.validationLabels.profileOrSite,
        value:
          primaryInfo.keyInfo.hasOnlineProfile ||
          primaryInfo.keyInfo.allocatedToSite,
      });
    }

    return validation;
  };

  const checkPublishSuggestions = (salesInfo, primaryInfo) => {
    const suggestions = [];

    // check if CAP code is not null (which actually checks capId)
    if (!primaryInfo.keyInfo.capId) {
      suggestions.push(consts.validationSuggestionMessages.capCodeRecommended);
    }

    // check if price is under CAP
    if (salesInfo.currentValuation) {
      const percentage = (
        ((salesInfo.buyNowPrice || 0) / salesInfo.currentValuation) *
        100
      ).toFixed(0);
      if (percentage < 85) {
        suggestions.push(consts.validationSuggestionMessages.capValuationUnder);
      } else if (percentage > 120) {
        suggestions.push(consts.validationSuggestionMessages.capValuationOver);
      }
    }

    return suggestions;
  };
  useEffect(() => {
    const validation =
      salesInformationData &&
      primaryInformationData &&
      checkPublishPrerequisite(
        salesInformationData.salesInfo,
        primaryInformationData,
        publishSessionType
      );
    setValidation(validation);

    const suggestions =
      salesInformationData &&
      primaryInformationData &&
      checkPublishSuggestions(
        salesInformationData.salesInfo,
        primaryInformationData
      );
    setValidationSuggestions(suggestions);

    if (validBiddingEndDate && validBiddingEndTime) {
      const mergedDateTime = dayjs(
        `${biddingEndDate}T${biddingEndTime}`
      ).format("YYYY-MM-DDTHH:mm");
      setBiddingEndDateTime(mergedDateTime);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    salesInformationData,
    primaryInformationData,
    publishSessionType,
    allowBuyNow,
    validBiddingEndDate,
    validBiddingEndTime,
    biddingEndDate,
    biddingEndTime,
  ]);

  const publishData = {
    publishSessionType,
    biddingEndDateTime,
    allowBuyNow,
  };

  return (
    <Fragment>
      <ModalInsert layout="flex">
        <div className={className}>
          <div className="publish-modal-content">
            <div className="publish-modal-header">
              <HeaderText>{consts.headers.publish}</HeaderText>
            </div>
            <div className="publish-modal-body">
              <div className="detail-list">
                <div style={{ marginBottom: "2rem" }}>
                  <BucketTypeFilter
                    action={setPublishSessionType}
                    selectedValue={publishSessionType}
                    options={radioOptions}
                    autoScaleOptions={true}
                  />
                </div>
                {publishSessionType === "Timed" && (
                  <>
                    <div className="row">
                      <ParagraphText>
                        {consts.headers.biddingEndDate}
                      </ParagraphText>
                      <Datepicker
                        getDate={handleDateChange}
                        defaultDate={biddingEndDate || ""}
                        setValidValue={setValidBiddingEndDate}
                        primary
                        noDateInPast
                      />
                    </div>
                    <div className="row">
                      <ParagraphText>
                        {consts.headers.biddingEndTime}
                      </ParagraphText>
                      <div>
                        <InputText
                          value={biddingEndTime}
                          onChange={handleTimeChange}
                          placeholder="HH:mm"
                          onBlur={() => {
                            checkValidTimeValue(biddingEndTime);
                          }}
                          validValue={validBiddingEndTime}
                        />
                      </div>
                    </div>
                  </>
                )}
                {publishSessionType !== "FixedPrice" && (
                  <div className="row">
                    <ParagraphText>{consts.headers.allowBuyNow}</ParagraphText>
                    <Switch
                      onChange={() => setAllowBuyNow(!allowBuyNow)}
                      checked={allowBuyNow}
                      uncheckedIcon={false}
                      checkedIcon={false}
                      onColor={COLOURS.PRIMARY.base}
                    />
                  </div>
                )}
              </div>
              <div className="detail-list">
                {!salesInformationFetching && salesInformationData ? (
                  <>
                    {salesInformationDetail.map(
                      ({ label, value, extra = "" }, index) => (
                        <div className="detail-list__row" key={index}>
                          <ParagraphText>{label}</ParagraphText>
                          <ParagraphText>
                            {formatPriceWithCurrencySymbol(value)}
                            {extra}
                          </ParagraphText>
                        </div>
                      )
                    )}
                    {!validBiddingEndDate && (
                      <BidInputError errorMessage={consts.errors.validDate} />
                    )}
                    {!validBiddingEndTime && (
                      <BidInputError errorMessage={consts.errors.validTime} />
                    )}
                    {biddingEndDate &&
                      dayjs(biddingEndDate).isBefore(dayjs(), "day") && (
                        <BidInputError
                          errorMessage={consts.errors.dateInPast}
                        />
                      )}
                    {biddingEndTime &&
                      dayjs(`${biddingEndDate}T${biddingEndTime}`).isBefore(
                        dayjs()
                      ) && (
                        <BidInputError
                          errorMessage={consts.errors.timeInPast}
                        />
                      )}
                  </>
                ) : (
                  [...Array(3)].map((_, i) => (
                    <div key={i} style={{ paddingBottom: "1rem" }}>
                      <SkeletonRows nrOfRows={1} paddingNone />
                    </div>
                  ))
                )}
              </div>
              <div className="detail-list">
                {!profileInformationFetching &&
                !salesInformationFetching &&
                !primaryInformationFetching &&
                profileInformationData &&
                salesInformationData &&
                primaryInformationData ? (
                  <>
                    {validation.map(({ label, value }, index) => (
                      <div
                        className="detail-list__row icon-container"
                        key={index}
                      >
                        <div className="icon-circle">
                          <Icon
                            type={value ? "check-circle" : "error-circle"}
                            strokeColour={
                              value ? COLOURS.GREEN.base : COLOURS.RED.base
                            }
                            width="20"
                            height="20"
                          />
                        </div>
                        <ParagraphText>{label}</ParagraphText>
                      </div>
                    ))}
                    {validationSuggestions &&
                      validationSuggestions.map((suggestion, index) => (
                        <div
                          className="detail-list__row icon-container"
                          key={index}
                        >
                          <div className="icon-circle">
                            <Icon
                              type="info-circle"
                              strokeColour={COLOURS.PRIMARY.base}
                              width="20"
                              height="20"
                            />
                          </div>
                          <ParagraphText>{suggestion}</ParagraphText>
                        </div>
                      ))}
                  </>
                ) : (
                  [...Array(5)].map((_, i) => (
                    <div key={i} style={{ paddingBottom: "1rem" }}>
                      <SkeletonRows nrOfRows={1} paddingNone />
                    </div>
                  ))
                )}
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flex: 1,
                justifyContent: isDesktop ? "flex-end" : "space-between",
              }}
            >
              <Button
                ghost
                content={consts.actions.close}
                action={onCancel}
                style={{ flex: 1 }}
              />
              <Button
                primary
                download
                content={consts.actions.confirm}
                action={() => onConfirmHandle(publishData)}
                disabled={!validData}
                style={{ flex: 1 }}
              />
            </div>
          </div>
        </div>
      </ModalInsert>
    </Fragment>
  );
};

export default VendorVehicleRecordPublishModal;
