import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import AddressPicker from "../../components/AddressPicker";
import { closeModal } from "../../../state/reducers/global";
import {
  find,
  capture,
  clearSelectedAddress,
} from "../../../state/reducers/addressLookup";
import {
  setBuyerAddress,
  setAccountAddress,
  setBillingAddress,
  setDefaultPurchaseAddress,
} from "../../../state/reducers/buyerInformation";

const AccountAdministrationAddressModalInsert = () => {
  const addressLookup = useSelector((state) => state.addressLookup);
  const addressSelection = useSelector(
    (state) => state.buyerInformation.addressSelection
  );
  const dispatch = useDispatch();
  const onFind = (text, container) => dispatch(find(text, container));
  const onCapture = (id) => dispatch(capture(id));
  const onCancel = () => dispatch(closeModal());
  const [removeCompanyName, setRemoveCompanyName] = useState(false);

  useEffect(() => {
    switch (addressSelection) {
      case "buyer-address":
      case "account-address":
      case "billing-address":
        setRemoveCompanyName(true);
        break;
      case "default-purchase-address":
        setRemoveCompanyName(false);
        break;
      default:
        break;
    }
  }, [addressSelection]);

  const onConfirmLocation = (location) => {
    const addressArray = location.address.split("\n");
    switch (addressSelection) {
      case "buyer-address":
        dispatch(
          setBuyerAddress({
            postalAddress: addressArray,
            postCode: location.postCode,
          })
        );
        break;
      case "account-address":
        dispatch(setAccountAddress(addressArray));
        break;
      case "billing-address":
        dispatch(setBillingAddress(addressArray));
        break;
      case "default-purchase-address":
        dispatch(setDefaultPurchaseAddress(addressArray));
        break;
      default:
        break;
    }
    dispatch(closeModal());
  };

  useEffect(() => {
    dispatch(clearSelectedAddress());
  }, [dispatch]);

  return (
    <AddressPicker
      onFind={onFind}
      onCapture={onCapture}
      foundLocations={
        addressLookup.searchResult && addressLookup.searchResult.Items
      }
      selectedLocation={
        addressLookup.selectedAddress && addressLookup.selectedAddress.Items
      }
      onCancel={onCancel}
      onConfirmLocation={onConfirmLocation}
      splitCompanyNameFromAddress={removeCompanyName}
    />
  );
};

export default AccountAdministrationAddressModalInsert;
