export const mainHeader = {
    DRIVER: "Modify Driver Information",
    CUSTOMER: "Modify Customer Information",
    LOCATION: "Modify Location"
}

export const labels = {
    CONTACT: "Contact",
    LOCATION: "Location Name",
    PRIMARY_NUM: "Primary Number",
    SECONDARY_NUM: "Secondary Number",
    EMAIL: "Email",
    NOTES: "Notes"
}