import { combineReducers } from 'redux';
import vehicleDetails, { fetchVehiclePrimaryDetails, fetchAdditionalEquipment } from "./vendor-vehicle/vehicleDetails";
import salesInformation, { fetchVehicleSalesInfo } from "./vendor-vehicle/salesInformation";
import eventHistory, { fetchVehicleEventHistory } from "./vendor-vehicle/eventHistory";
import profileSummary, { fetchVehicleRecordProfiles } from "./vendor-vehicle/profiles";
import purchaseInformation, { fetchVehiclePurchaseInfo } from "./vendor-vehicle/purchaseInformation";
import biddingInformation, { fetchVehicleBiddingInfo } from "./vendor-vehicle/biddingInformation";
import inspections, { fetchVehicleInspections } from "./vendor-vehicle/inspections";
import vehicleLocation, { fetchLocation } from "./vendor-vehicle/location";
import genericActiveState from "./vendor-vehicle/genericActiveState";
import vehicleHold from "./vendor-vehicle/vehicleHold";
import vehicleImageDetails from "./vendor-vehicle/vehicleImages";

import { fetchAuctionSalesForVehicle } from "./auction";
import {
  getStandardEquipment,
  getTechnicalSpecification,
  fetchServiceHistory,
  fetchDocuments,
} from "./vehicle";
import { fetchActionItems } from './actions';

import {
  ACTION_AREAS,
  VENDOR_VEHICLE_LOCATION_MAP,
  VENDOR_VEHICLE_NAVIGATION_OPTIONS,
} from "../../shared/utility";


export const genericFetchEventForNavigation =
  (activeNavOption, vehicleId) => (dispatch, getState) => {
    let actionArea = null;

    switch (activeNavOption) {
      case VENDOR_VEHICLE_NAVIGATION_OPTIONS.SALES_INFO:
        dispatch(fetchVehicleSalesInfo(vehicleId));
        actionArea = ACTION_AREAS.SALES_INFO;
        break;
      case VENDOR_VEHICLE_NAVIGATION_OPTIONS.PURCHASE_INFO:
        dispatch(fetchVehiclePurchaseInfo(vehicleId));
        actionArea = ACTION_AREAS.PURCHASE_INFO;
        break;
      case VENDOR_VEHICLE_NAVIGATION_OPTIONS.EVENT_HISTORY:
        dispatch(fetchVehicleEventHistory(vehicleId));
        actionArea = ACTION_AREAS.EVENT_HISTORY;
        break;
      case VENDOR_VEHICLE_NAVIGATION_OPTIONS.PROFILES:
        dispatch(fetchVehicleRecordProfiles(vehicleId));
        actionArea = ACTION_AREAS.PROFILES;
        break;
      case VENDOR_VEHICLE_NAVIGATION_OPTIONS.EQUIPMENT_AND_SPEC:
      case VENDOR_VEHICLE_NAVIGATION_OPTIONS.STANDARD_EQUIPMENT:
        const hasStandardEquipment =
          !!getState().vehicle.standardEquipmentData.standardEquipment;
        if (!hasStandardEquipment) {
          dispatch(getStandardEquipment(vehicleId));
        }
        actionArea = ACTION_AREAS.STANDARD_EQUIPMENT;
        break;
      case VENDOR_VEHICLE_NAVIGATION_OPTIONS.TECHNICAL_SPECIFICATION:
        const hasTechnicalSpecification =
          !!getState().vehicle.technicalSpecificationData.data;
        if (!hasTechnicalSpecification) {
          dispatch(getTechnicalSpecification(vehicleId));
        }
        actionArea = ACTION_AREAS.TECHNICAL_SPECIFICATION;
        break;
      case VENDOR_VEHICLE_NAVIGATION_OPTIONS.MAINTENANCE_HISTORY:
        const hasServiceHistory =
          !!getState().vehicle.serviceHistoryData.serviceHistory;
        if (!hasServiceHistory) {
          dispatch(fetchServiceHistory(vehicleId));
        }
        actionArea = ACTION_AREAS.MAINTENANCE_HISTORY;
        break;
      case VENDOR_VEHICLE_NAVIGATION_OPTIONS.AUCTION_SALES:
        dispatch(fetchAuctionSalesForVehicle(vehicleId));
        actionArea = ACTION_AREAS.AUCTION_SALES;
        break;
      case VENDOR_VEHICLE_NAVIGATION_OPTIONS.BIDDING_INFO:
        dispatch(fetchVehicleBiddingInfo(vehicleId));
        actionArea = ACTION_AREAS.BIDDING_INFO;
        break;
      case VENDOR_VEHICLE_NAVIGATION_OPTIONS.INSPECTION_REPORT:
        dispatch(fetchVehicleInspections(vehicleId));
        actionArea = ACTION_AREAS.INSPECTION_REPORT;
        break;
      case VENDOR_VEHICLE_NAVIGATION_OPTIONS.DOCUMENTATION:
        dispatch(fetchDocuments(vehicleId));
        actionArea = ACTION_AREAS.DOCUMENTATION;
        break;
      case VENDOR_VEHICLE_NAVIGATION_OPTIONS.VEHICLE_LOCATION:
        dispatch(fetchLocation(vehicleId, VENDOR_VEHICLE_LOCATION_MAP.HISTORY));
        actionArea = ACTION_AREAS.VEHICLE_LOCATION;
        break;
      case VENDOR_VEHICLE_NAVIGATION_OPTIONS.DRIVER_CUSTOMER:
        dispatch(fetchLocation(vehicleId, VENDOR_VEHICLE_LOCATION_MAP.DRIVER_AND_CUSTOMER_LOCATION));
        actionArea = ACTION_AREAS.DRIVER_CUSTOMER;
        break;
      case VENDOR_VEHICLE_NAVIGATION_OPTIONS.ADDITIONAL_EQUIPMENT:
        dispatch(fetchAdditionalEquipment(vehicleId));
        actionArea = ACTION_AREAS.ADDITIONAL_EQUIPMENT;
        break;
      default:
        dispatch(fetchVehiclePrimaryDetails(vehicleId));
        actionArea = ACTION_AREAS.VEHICLE_DETAILS;
        break;
    }
    return dispatch(fetchActionItems(vehicleId, actionArea));
  };

const vendorVehicleRecord = combineReducers({
  vehicleDetails,
  salesInformation,
  eventHistory,
  profileSummary,
  purchaseInformation,
  biddingInformation,
  inspections,
  vehicleLocation,
  genericActiveState,
  vehicleHold,
  vehicleImageDetails,
});

export default vendorVehicleRecord;