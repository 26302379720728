import React, { useContext } from "react";
import PropTypes from "prop-types";
import { ThemeContext } from "styled-components";

const List = ({ width, height, strokeColour, strokeWidth }) => {
  const theme = useContext(ThemeContext);
  const stroke = strokeColour ? strokeColour : theme.COLOURS.PRIMARY.base;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill="none"
      stroke={stroke}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={strokeWidth}
      viewBox="0 0 24 24"
    >
      <path d="M8 6L21 6"></path>
      <path d="M8 12L21 12"></path>
      <path d="M8 18L21 18"></path>
      <path d="M3 6L3.01 6"></path>
      <path d="M3 12L3.01 12"></path>
      <path d="M3 18L3.01 18"></path>
    </svg>
  );
};

List.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  strokeColour: PropTypes.string,
  strokeWidth: PropTypes.string,
};

List.defaultProps = {
  width: "24",
  height: "24",
  strokeWidth: "2",
};

export default List;
