import React from "react";
import PropTypes from "prop-types";
import Panel from "../../Panel";
import ModalInsert from "../../ModalInsert";

const SkeletonHoldModal = (props) => {
  return (
    <div className={props.className}>
      <ModalInsert width="90rem">
        <Panel flex="0" flexDesktop="1.5">
          <div
            style={{ display: "grid", gridTemplateColumns: "repeat(2, 1fr)" }}
          >
            <div className="skeleton-checkbox-filter">
              {[...Array(5)].map((item, index) => (
                <span key={`skeleton-checkbox-filter-${index}`}>
                  <span className="skeleton-checkbox-filter__checkbox" />
                  <span className="skeleton-checkbox-filter__label" />
                </span>
              ))}
            </div>
            <div className="skeleton-checkbox-filter">
              {[...Array(5)].map((item, index) => (
                <span key={`skeleton-checkbox-filter-${index}`}>
                  <span className="skeleton-checkbox-filter__checkbox" />
                  <span className="skeleton-checkbox-filter__label" />
                </span>
              ))}
            </div>
          </div>
        </Panel>
        <div className="hold-modal-bottom">
          <div className="hold-modal-content-no-shimmer" />
          <div className="hold-modal-content" />
          <Panel
            style={{ height: "100%", borderRadius: "0.5rem" }}
            padding="REGULAR"
            actions={[
              {
                content: "Close",
                action: () => {},
                type: "ghost",
              },
              {
                content: "Confirm",
                action: () => {},
                type: "primary",
                disabled: true,
              },
            ]}
          />
        </div>
      </ModalInsert>
    </div>
  );
};

SkeletonHoldModal.propTypes = {
  className: PropTypes.string,
};

export default SkeletonHoldModal;
