import React, { Fragment } from "react";
import HeaderText from "../../../views/components/HeaderText";
import ModalInsert from "../../../views/components/ModalInsert/ModalInsert";
import ParagraphText from "../../../views/components/ParagraphText";
import { modalOptions } from "./const";
import TextArea from "../../../views/components/TextArea";
import Checkbox from "../../../views/components/Checkbox";
import Panel from "../../../views/components/Panel/Panel";
import SkeletonHoldModal from "../../../views/components/Skeletons/SkeletonHoldModal";

const VendorVehicleRecordHoldModal = ({
  className,
  checkboxes,
  checkBoxHandler,
  additionalHoldReason,
  vehicleAdditionalHoldReason,
  handleAdditionalHoldReasonChange,
  onCancel,
  onConfirmHandle,
  checkboxOrTextareaEmpty,
  isFetchingVendorHoldDetails,
  isFetchingVehicleHoldDetails,
  holdType,
}) => {
  return (
    <Fragment>
      <ModalInsert layout={"flex"}>
        {!isFetchingVendorHoldDetails && !isFetchingVehicleHoldDetails ? (
          <div>
            <div className={className}>
              <div className="hold-modal-content">
                <div className="hold-modal-header">
                  <HeaderText>
                    {holdType === "hold"
                      ? modalOptions.HOLD_VEHICLE
                      : modalOptions.MODIFY_HOLD}
                  </HeaderText>
                </div>
                <div className="checkbox-container">
                  {checkboxes &&
                    Object.keys(checkboxes).map((checkbox, index) => (
                      <div key={index}>
                        <Checkbox
                          key={index}
                          isChecked={checkboxes[checkbox]}
                          onInputChange={() => checkBoxHandler(checkbox)}
                        >
                          {checkbox}
                        </Checkbox>
                      </div>
                    ))}
                </div>
                <div className="hold-modal-bottom">
                  <div>
                    <ParagraphText>
                      {modalOptions.ADDITIONAL_HOLD_REASON}
                    </ParagraphText>
                    <TextArea
                      resize="none"
                      maximumCharactersBottom={100}
                      rows="3"
                      cols="10"
                      value={
                        additionalHoldReason || vehicleAdditionalHoldReason
                      }
                      onChange={handleAdditionalHoldReasonChange}
                    />
                  </div>
                  <Panel
                    style={{ height: "100%", borderRadius: "0.5rem" }}
                    actions={[
                      {
                        content: "Close",
                        action: onCancel,
                        type: "ghost",
                      },
                      {
                        content: "Confirm",
                        type: "primary",
                        action: onConfirmHandle,
                        disabled: checkboxOrTextareaEmpty(),
                      },
                    ]}
                  />
                </div>
              </div>
            </div>
          </div>
        ) : (
          <SkeletonHoldModal />
        )}
      </ModalInsert>
    </Fragment>
  );
};

export default VendorVehicleRecordHoldModal;
