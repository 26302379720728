import React, { Fragment, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { closeModal } from "../../../state/reducers/global";
import ModalInsert from "../../../views/components/ModalInsert";
import Panel from "../../../views/components/Panel";
import LocationEditModal from "../../components/LocationEditModal";
import { EMAIL_REGEX, UK_TELEPHONE_REGEX } from "../../../shared/utility";
import { updateLocation } from "../../../state/reducers/vendor-vehicle/location";
import { setActiveConfirmModalOptions } from "../../../state/reducers/vendor-vehicle/genericActiveState";

const LocationEditModalInsert = () => {
  const dispatch = useDispatch();
  const vehicleReference = useSelector(
    (state) => state.vendorVehicleRecord.genericActiveState.activeVehicleReference
  );

  const driverAndCustomerData = useSelector(
    (state) => state.vendorVehicleRecord.vehicleLocation?.data?.driver_and_customer_location
  );

  const historyLocationData = useSelector(
    (state) => state.vendorVehicleRecord.vehicleLocation?.data?.history
  );

  const locationType = useSelector(
    (state) => state.vendorVehicleRecord.genericActiveState.locationType
  );

  const [data, setData] = useState(null);
  const [locationName, setLocationName] = useState("");
  const [contactName, setContactName] = useState("");
  const [address, setAddress] = useState("");
  const [primaryNumber, setPrimaryNumber] = useState("");
  const [validPrimaryNumber, setValidPrimaryNumber] = useState(true);
  const [secondaryNumber, setSecondaryNumber] = useState("");
  const [validSecondaryNumber, setValidSecondaryNumber] = useState(true);
  const [email, setEmail] = useState("");
  const [validEmail, setValidEmail] = useState(true);
  const [updatedAddress, setUpdatedAddress] = useState("");
  const [validAddress, setValidAddress] = useState(true);
  const [notes, setNotes] = useState("");

  useEffect(() => {
    if (driverAndCustomerData && (locationType === "Driver" || locationType === "Customer")) {
      const newData = locationType === "Driver" ? driverAndCustomerData[0] : driverAndCustomerData[1];
      setData(newData);
      setLocationName(newData.locationName || "");
      setContactName(newData.contactName || "");
      setAddress(newData.contactAddress || "");
      setPrimaryNumber(newData.contactTelephone || "");
      setSecondaryNumber(newData.contactSMS || "");
      setEmail(newData.contactEmail || "");
      setNotes(newData.notes || "");
    } else if (historyLocationData?.[0]) {
      const historyData = historyLocationData[0];
      setData(historyData);
      setLocationName(historyData.locationName || "");
      setContactName(historyData.contactName || "");
      setAddress(historyData.contactAddress || "");
      setPrimaryNumber(historyData.contactTelephone || "");
      setSecondaryNumber(historyData.contactSMS || "");
      setEmail(historyData.contactEmail || "");
      setNotes(historyData.notes || "");
    }
  }, [driverAndCustomerData, locationType, historyLocationData]);

  const onChangeContactName = (e) => setContactName(e.target.value);
  const onChangeLocationName = (e) => setLocationName(e.target.value);
  const onChangeNotes = (e) => setNotes(e.target.value);

  const onChangePrimaryNumber = (e) => {
    const inputValue = e.target.value.trim();
    setPrimaryNumber(inputValue);
    if (inputValue && UK_TELEPHONE_REGEX.test(inputValue)) {
      setValidPrimaryNumber(true);
    } else if (inputValue === "") {
      setValidPrimaryNumber(true); 
    } else {
      setValidPrimaryNumber(false);
    }
  };

  const onChangeSecondaryNumber = (e) => {
    const inputValue = e.target.value.trim();
    setSecondaryNumber(inputValue);
    if (inputValue && UK_TELEPHONE_REGEX.test(inputValue)) {
      setValidSecondaryNumber(true);
    } else if (inputValue === "") {
      setValidSecondaryNumber(true); 
    } else {
      setValidSecondaryNumber(false);
    }
  };

  const onChangeEmail = (e) => {
    const inputValue = e.target.value.trim();
    setEmail(inputValue);
    if (inputValue && EMAIL_REGEX.test(inputValue)) {
      setValidEmail(true);
    } else if (inputValue === "") {
      setValidEmail(true); 
    } else {
      setValidEmail(false);
    }
  };

  const onCancel = () => {
    dispatch(closeModal());
  };

  const onSave = () => {
    const type = "save-location";
    const data = {
      action: type,
      vehicleReference: vehicleReference,
    };
    dispatch(setActiveConfirmModalOptions(type, data));
    dispatch(updateLocation(vehicleReference, completeData, locationType));
  };

  const addressCheck = updatedAddress || address;
  const addressLinesArray = Array.isArray(addressCheck) ? addressCheck : addressCheck?.split("\n");
  const postcode = addressLinesArray?.[addressLinesArray.length - 1];

  const locationId =
    locationType === "Location"
      ? null
      : locationType === "Driver"
        ? driverAndCustomerData?.[0]?.locationId
        : driverAndCustomerData?.[1]?.locationId;

  const completeData = {
    locationId,
    locationName,
    contactName,
    contactAddress: addressLinesArray,
    postcode,
    contactTelephone: primaryNumber,
    contactEmail: email,
    contactSMS: secondaryNumber,
    notes,
  };

  const hasAddressChanged = updatedAddress
    ? updatedAddress !== data?.contactAddress?.join("\n")
    : address !== data?.contactAddress?.join("\n");

  const allFieldsEmpty = !locationName && !contactName && !address && !primaryNumber && !secondaryNumber && !email && !notes;

  const fieldsChanged =
    locationName !== (data?.locationName || "") ||
    contactName !== (data?.contactName || "") ||
    hasAddressChanged ||
    primaryNumber !== (data?.contactTelephone || "") ||
    secondaryNumber !== (data?.contactSMS || "") ||
    email !== (data?.contactEmail || "") ||
    notes !== (data?.notes || "");

  const disableSave =
    allFieldsEmpty || 
    (!validPrimaryNumber || !validSecondaryNumber || !validEmail || !validAddress) ||
    (!fieldsChanged && allFieldsEmpty);
    
  return (
    <Fragment>
      <ModalInsert>
        <Panel
          mobileFlex
          style={{ height: "100%", borderRadius: "0.5rem" }}
          actions={[
            {
              content: "Close",
              action: onCancel,
              type: "ghost",
            },
            {
              content: "Save",
              type: "primary",
              action: onSave,
              disabled: disableSave, 
            },
          ]}
        >
          {data && 
              <LocationEditModal
                contact={contactName}
                onChangeContactName={onChangeContactName}
                address={address}
                setAddress={setAddress}
                primaryNumber={primaryNumber}
                onChangePrimaryNumber={onChangePrimaryNumber}
                validPrimaryNumber={validPrimaryNumber}
                secondaryNumber={secondaryNumber}
                onChangeSecondaryNumber={onChangeSecondaryNumber}
                validSecondaryNumber={validSecondaryNumber}
                email={email}
                onChangeEmail={onChangeEmail}
                validEmail={validEmail}
                data={data}
                setUpdatedAddress={setUpdatedAddress}
                locationType={locationType}
                locationName={locationName}
                setLocationName={setLocationName}
                onChangeLocationName={onChangeLocationName}
                setValidAddress={setValidAddress}
                notes={notes}
                onChangeNotes={onChangeNotes}
              /> }
        </Panel>
      </ModalInsert>
    </Fragment>
  );
};

export default LocationEditModalInsert;