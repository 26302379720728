import React from "react";
import SubHeaderText from "../../../views/components/SubHeaderText";
import classnames from "classnames";

const ResultsNumber = ({ className, count, unit }) => {
  const content = () => `${unit}${count !== 1 ? "s" : ""} found`;

  return (
    <div className={classnames(className)}>
      <div className="container">
        <div className="count">{count}</div>
        <SubHeaderText className="text">{content()}</SubHeaderText>
      </div>
    </div>
  );
};

export default ResultsNumber;
