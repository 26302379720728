import React from "react";
import { mainHeader } from "./const.js";
import HeaderText from "../../../views/components/HeaderText";
import CapVehicleLookup from "../CapVehicleLookup";
import CapVehicleLookupTable from "../CapVehicleLookupTable";

const VehicleLookupModal = ({
  className,
  capId,
  capCode,
  description,
  onChangeCapCode,
  onChangeCapId,
  onChangeDescription,
  onFormSubmit,
  onFormClear,
  selectedMakes,
  setSelectedMakes,
  selectedModels,
  setSelectedModels,
  isFetchingCapMake,
  isFetchingCapModel,
  isFetchingVehicleLookupData,
  capMakeOptions,
  capModelOptions,
  disableConfirm,
  disableClear,
  capVehicleData,
  handleRowSelection,
  selectedRow,
  numberOfResults,
}) => {
  return (
    <div className={className}>
      <HeaderText>{mainHeader}</HeaderText>
      <CapVehicleLookup
        capId={capId}
        capCode={capCode}
        description={description}
        onChangeCapCode={onChangeCapCode}
        onChangeCapId={onChangeCapId}
        onChangeDescription={onChangeDescription}
        onFormSubmit={onFormSubmit}
        onFormClear={onFormClear}
        selectedMakes={selectedMakes}
        setSelectedMakes={setSelectedMakes}
        selectedModels={selectedModels}
        setSelectedModels={setSelectedModels}
        isFetchingCapMake={isFetchingCapMake}
        isFetchingCapModel={isFetchingCapModel}
        capMakeOptions={capMakeOptions}
        capModelOptions={capModelOptions}
        disableConfirm={disableConfirm}
        disableClear={disableClear}
        numberOfResults={numberOfResults}
      />

      <CapVehicleLookupTable
        capVehicleData={capVehicleData}
        isFetchingVehicleLookupData={isFetchingVehicleLookupData}
        handleRowSelection={handleRowSelection}
        selectedRow={selectedRow}
      />
    </div>
  );
};

export default VehicleLookupModal;
