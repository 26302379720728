import styled from "styled-components";
import VendorVehicleRecordPublishModal from "./VendorVehicleRecordPublishModal";
import spacing from "../../../views/theme/base/spacing";

const StyledVendorVehicleRecordPublishModal = styled(VendorVehicleRecordPublishModal)`
    width: 100%;

    .publish-modal-content {
        margin: ${(props) => props.theme.SPACING.REGULAR};
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    .publish-modal-header {
        margin-top: ${(props) => props.theme.SPACING.SMALL};
        margin-bottom: ${(props) => props.theme.SPACING.SMALL};
        margin-left: ${(props) => props.theme.SPACING.REGULAR};
    }

    .publish-modal-body {
        margin-left: ${(props) => props.theme.SPACING.REGULAR};
        min-height: 27rem;
        display: flex;
        flex-direction: row;
        flex: 1;
        gap: ${(props) => props.theme.SPACING.REGULAR};
    }

    .switch-container {
        padding-left: ${spacing.REGULAR};
    }

    .row {
        display: flex;
        gap: 1rem;
        align-items: center;
        justify-content: space-between;
        margin: 0 0 ${(props) => props.theme.SPACING.SMALL} 0;
        
        & > * {
            flex: 1;
        }
    }

    .detail-list {
        width: 100%;
        display: flex;
        flex-direction: column;
        
        &:nth-of-type(1) {
            width: 45%;
        }
        &:nth-of-type(2) {
            width: 30%;
        }
        &:nth-of-type(3) {
            width: 25%;
        }

        .detail-list__row {
            width: 100%;
            display: flex;
            text-align: left;
            justify-content: space-evenly;
            margin: 0 0 ${(props) => props.theme.SPACING.SMALL} 0;

            p {
                width: 100%;
                margin: 0;
                span {
                    color: ${(props) => props.theme.COLOURS.PRIMARY.shades[60]};
                }
            }
            p:nth-of-type(2) {
                color: ${(props) => props.theme.COLOURS.PRIMARY.base};
            }
        }
    }

    .icon-container {
        gap: 1rem;
        display: flex;
        position: relative;
        align-items: center;
    }

    .icon-circle {
        vertical-align: middle;
        margin-left: ${(props) => props.theme.SPACING.XSMALL};
        margin-bottom: -0.7rem;
    }
`;

export default StyledVendorVehicleRecordPublishModal;
