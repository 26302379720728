import styled from "styled-components";
import LocationEditModal from "./LocationEditModal";
import InputLabel from "../../../views/components/InputLabel";
import fonts from "../../../views/theme/base/fonts";

const StyledLocationEditModal = styled(LocationEditModal)`
  .row {
    display: flex;
    margin: 1rem 0;

    ${InputLabel} {
      width: 30%;
      display: flex;
      font-size: ${fonts.sizes[40]};
    }
  }

  .address-area {
    textarea {
      color: ${(props) => props.theme.COLOURS.PRIMARY.base};
      font-size: ${fonts.sizes[60]};
      border: 0.2rem solid ${(props) => props.theme.COLOURS.PRIMARY.shades[20]};
      padding-left: 2rem;
    }
  }

  .notes-row {
    display: flex;
    margin: 1rem 0;

    ${InputLabel} {
      width: 33%;
      display: flex;
      font-size: ${fonts.sizes[40]};
    }

    .text-area__input {
      font-size: ${fonts.sizes[60]};
    }
  }
`;

export default StyledLocationEditModal;
