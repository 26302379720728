export const inputPlaceHolders = {
    ID: "CAP ID",
    CODE: "CAP Code",
    DESCRIPTION: "Description",
    MAKE: "Make",
    MODEL: "Model"
}

export const formButton = "Search";

export const clearFormButton = "Clear";

export const noResults = "There are no results for your selected filters."