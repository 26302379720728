export const headers = {
    receivePayment: 'Receive Payment',
    paymentReference: 'Payment Reference',
    paymentDate: 'Payment Date',
    purchasedBy: 'Purchased By',
    invoiceNumber: 'Invoice Number',
    totalGrossInvoiceValue: 'Total Gross Invoice Value',
};

export const errors = {
    validDate: 'Please enter a valid date',
};

export const actions = {
    confirm: 'Confirm',
    close: 'Close',
};
