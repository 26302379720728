import styled from "styled-components";

import SkeletonHoldModal from "./SkeletonHoldModal.jsx";
import breakpoints from "../../../theme/breakpoints";

const StyledSkeletonHoldModal = styled(SkeletonHoldModal)`
  height: 100%;

  @media (min-width: ${breakpoints.LARGE_DESKTOP}) {
    height: auto;
  }

  .panel__content > div {
    animation: shimmer 3s infinite linear;
    background-size: 100rem 100%;
  }

  .hold-modal-content {
    ${props => props.theme.ANIMATIONS["shimmerDark"]};
    height: 10rem;
    margin: ${(props) => props.theme.SPACING.REGULAR};
  } 
  .hold-modal-content-no-shimmer {
    height: 9rem;
    margin: ${(props) => props.theme.SPACING.REGULAR};
  } 

  .hold-modal-bottom {
    margin-top: auto;
  }

  .skeleton-checkbox-filter {
    display: flex;
    flex-wrap: wrap;

  > span {
    align-items: center;
    box-sizing: border-box;
    display: flex;
    padding: 1rem 1.6rem 0 0;
    width: 100%;
  }
  }
  .skeleton-checkbox-filter__checkbox {
    border: 0.2rem solid ${props => props.theme.COLOURS.PRIMARY.shades[20]};
    border-radius: 0.5rem;
    height: 2.5rem;
    margin-right: 0.5rem;
    width: 2.5rem;
  }

  .skeleton-checkbox-filter__label {
    ${props => props.theme.ANIMATIONS["shimmerDark"]};
    height: 1rem;
    width: 90%;
  }

  @keyframes shimmer {
    0% {
      background-position: -100rem 0;
    }
    100% {
      background-position: 100rem 0;
    }
  }
`;

export default StyledSkeletonHoldModal;
