import styled from "styled-components";
import VendorVehicleRecordHoldModal from "./VendorVehicleRecordHoldModal";
import spacing from "../../../views/theme/base/spacing";
import colours from "../../../views/theme/base/colours";

const StyledVendorVehicleRecordHoldModal = styled(VendorVehicleRecordHoldModal)`
  width: 90rem;

  .hold-modal-content {
    height: ${(props) => (props.workshop || !props.hasCheckboxes ? "35rem" : "60rem")};
    margin: ${(props) => props.theme.SPACING.REGULAR};
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .hold-modal-header {
    margin-top: ${(props) => props.theme.SPACING.SMALL};
    margin-bottom: ${(props) => props.theme.SPACING.SMALL};
  }

  .hold-modal-bottom {
    margin-top: auto;

    div {
      margin-top: ${(props) => props.theme.SPACING.SMALL};
      margin-bottom: ${(props) => props.theme.SPACING.SMALL};
    }

  }

  .text-area__input {
    background-color: ${colours.WHITE};
    border-radius: 0.5rem;
    border: 0.2rem solid ${colours.PRIMARY.shades[20]};
    box-sizing: border-box;
    color: ${colours.PRIMARY.base};
    padding: ${spacing.SMALL};
    display: flex;
    justify-content: flex-end;
    width: 100%;
    resize: initial;
    ${(props) => props.theme.FONTS.Text.paragraph}
  }

  .checkbox-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    overflow: auto;
    overflow-x: hidden;

    div {
      margin: 0.25rem;
    }

    span {
          word-wrap: break-word;
}

  }
`;

export default StyledVendorVehicleRecordHoldModal;
