import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import classnames from "classnames";
import PropTypes from "prop-types";
import DragDrop from "../DragDrop";
import FileUpload from "../../containers/FileUpload";
import SkeletonRows from "../Skeletons/SkeletonRows";
import BidInputError from "../BidInput/child-components/BidInputError/BidInputError";
import {
  errorMessages,
  modalSubtitle,
  modalTitle,
  primaryParagraphTextConst,
  secondaryParagraphTextConst,
} from "./const";
import { DragDropItemContent } from ".";
import { USER_TYPE_MAP } from "../../../shared/utility";
import ParagraphText from "../ParagraphText";
import HeaderText from "../HeaderText";
import ProgressBar from "../ProgressBar";
import ButtonLink from "../ButtonLink";
import { openModal } from "../../../state/reducers/global";

const DragDropModal = ({
  className,
  qaClassName,
  vehicleReferenceId,
  onSaveVehicleDocument,
  data,
  getDownloadLink,
}) => {
  const dispatch = useDispatch();

  const [validFileType, setValidFileType] = useState(true);
  const [validFileSize, setValidFileSize] = useState(true);
  const [disabled, setShowLogoDisabled] = useState(false);
  const [uploadProgress, setUploadProgress] = useState([]);

  const activeNavOption = useSelector(
    (state) => state.vendorVehicleRecord.genericActiveState.activeNavOption
  );

  const acceptedTypes = {
    "application/pdf": [".pdf"],
  };

  const primaryParagraphText = `${primaryParagraphTextConst.PDF_FILES}`;
  const secondaryParagraphText = `${secondaryParagraphTextConst.PDF_FILES}`;

  const handleBlockChange = (fileList) => {
    const newProgress = fileList.map((file) => ({
      name: file.name,
      progress: 0,
      sizeFactor: file.size / 100,
    }));

    setUploadProgress((prevProgress) => [...prevProgress, ...newProgress]);

    fileList.forEach((file) => {
      const documentData = {
        vehicleId: `${vehicleReferenceId}`,
        fileName: file.name,
        fileSize: file.size,
        file: file,
      };

      const simulateProgress = setInterval(() => {
        setUploadProgress((prevProgress) => {
          const progressIndex = prevProgress.findIndex(
            (p) => p.name === file.name
          );

          if (progressIndex === -1) return prevProgress;

          const updatedProgress = [...prevProgress];
          const currentProgress = updatedProgress[progressIndex].progress;

          if (currentProgress < 99) {
            const increment = Math.random() * 5; // Random increment between 0 and 5
            updatedProgress[progressIndex].progress = Math.min(
              currentProgress + increment,
              99
            );
          }
          if (updatedProgress[progressIndex].progress >= 99) {
            clearInterval(simulateProgress);
          }

          return updatedProgress;
        });
      }, 100);

      // Handle file upload
      const uploadPromise = onSaveVehicleDocument(documentData);

      if (uploadPromise && typeof uploadPromise.then === "function") {
        uploadPromise
          .then(() => {
            clearInterval(simulateProgress);
            setUploadProgress((prevProgress) =>
              prevProgress.filter((item) => item.name !== file.name)
            );
          })
          .catch((error) => {
            dispatch(openModal("generic-error"));
          });
      }
    });
  };

  const onDropRejected = (fileRejections) => {
    fileRejections.forEach((x) =>
      x.errors.forEach((err) => {
        if (err.code === "file-invalid-type") {
          setValidFileType(false);
          return;
        }
        if (err.code === "file-too-large") {
          setValidFileSize(false);
          return;
        }
      })
    );
    setTimeout(() => {
      setValidFileType(true);
      setValidFileSize(true);
    }, 2500);
  };

  const maxNoOfMb = 10;
  const maxFileSize = 1048576 * maxNoOfMb;

  const type = "Documentation";
  const userType = USER_TYPE_MAP.Vendor;

  useEffect(() => {
    if (data.isFetching) {
      setUploadProgress([]);
    }
  }, [data.isFetching]);

  const truncateFileName = (name, maxLength = 50) => {
    return name.length > maxLength ? `${name.slice(0, maxLength)}...` : name;
  };

  return (
    <div className={classnames(className, qaClassName)}>
      <HeaderText>{modalTitle.DOCUMENTATION}</HeaderText>
      <DragDrop
        onDropRejected={onDropRejected}
        onDrop={handleBlockChange}
        maxFileSize={maxFileSize}
        disabled={disabled}
        acceptedTypes={acceptedTypes}
        primaryParagraphText={primaryParagraphText}
        secondaryParagraphText={secondaryParagraphText}
        allowMultiple={true}
      />
      {!validFileType && (
        <BidInputError errorMessage={errorMessages.INVALID_FILE_FORMAT} />
      )}
      {!validFileSize && (
        <BidInputError
          errorMessage={errorMessages.FILE_TOO_LARGE.replace("{0}", maxNoOfMb)}
        />
      )}
      <ParagraphText primary>{modalSubtitle.DOCUMENTATION}</ParagraphText>
      <DragDropItemContent>
        {uploadProgress.length > 0 &&
          uploadProgress.map((file, index) => (
            <div key={index} style={{ display: "flex", gap: "1rem" }}>
              <ButtonLink
                key={index}
                listItem
                primary
                name={truncateFileName(file.name)}
                disabled={true}
                action={() => {
                  window.open("", "_blank");
                }}
              />
              <ProgressBar value={file.progress} />
            </div>
          ))}
        {!data.isFetching ? (
          data.documents &&
          data.documents.map((item, index) => (
            <FileUpload
              key={index}
              callingArea={activeNavOption}
              setShowLogoDisabled={setShowLogoDisabled}
              disabled={disabled}
              userType={userType}
              type={type}
              disabledTrashCan={false}
              disableBlockUpload={true}
              dragDropData={item}
              getDownloadLink={getDownloadLink}
            />
          ))
        ) : (
          <SkeletonRows nrOfRows={3} paddingNone className="skeleton-rows" />
        )}
      </DragDropItemContent>
    </div>
  );
};

DragDropModal.propTypes = {
  className: PropTypes.string,
  qaClassName: PropTypes.string,
  onClose: PropTypes.func,
};

export default DragDropModal;
