import styled from 'styled-components';
import VendorVehicleImagesModal from './VendorVehicleImagesModal';
import ButtonLink from '../../../views/components/ButtonLink/ButtonLink';
import COLOURS from '../../../views/theme/base/colours';
import SPACING from '../../../views/theme/base/spacing';
import BidInputError from '../../../views/components/BidInput/child-components/BidInputError';
import BREAKPOINTS from '../../../views/theme/breakpoints';

const StyledVendorVehicleImagesModal = styled(VendorVehicleImagesModal)`
  width: 100%;
  height: 100%;

  .switch-container {
    display: flex;
    gap: ${SPACING.SMALL};
    padding-bottom: ${SPACING.MEDIUM};
  }

  .image-container {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: ${SPACING.SMALL};
    padding-right: ${SPACING.SMALL};
    max-height: 50rem;
    overflow-Y: auto;
    overflow-X: hidden;

    @media (max-width: ${BREAKPOINTS.TABLET}) {
      grid-template-columns: repeat(3, 1fr);
    }

    @media (max-width: ${BREAKPOINTS.LARGE_PHONE}) {
      grid-template-columns: repeat(2, 1fr);
    }

    @media (max-width: ${BREAKPOINTS.PHONE}) {
      grid-template-columns: repeat(1, 1fr);
    }
  }

  .media-thumbs {
    position: relative;
    padding-bottom: 56.25%; /* 16:9 aspect ratio */
    width: 100%;
    height: 0;
    cursor: pointer; // set to move when ENABLE_DRAG_AND_DROP is true
  }

  .uploaded-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
  }

  .image-action-delete {
    position: absolute;
    top: 5px;
    right: 5px;
    cursor: pointer;
    z-index: 1;
  }

  .image-action-default {
    position: absolute;
    top: 5px;
    left: 5px;
    cursor: pointer;
    z-index: 1;
  }

  .image-placeholder {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: ${COLOURS.WHITE};
    position: absolute;
    top: 0;
    left: 0;
    border: 2px dashed ${COLOURS.PRIMARY.base};
  }

  .rotation {
    animation: rotation 2s infinite linear;
  }

  @keyframes rotation {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }

  .error-container {
    margin-top: ${SPACING.SMALL};
  }
`;

export const DragDropItemContent = styled.div`
  div {
    margin: ${SPACING.XSMALL} ${SPACING.NONE};
    display: flex;
    height: min-content;

    > div {
      margin-right: ${SPACING.SMALL};
    }
  }

  .failedItems {
    margin: 0;
    pointer-events: none;
    ${ButtonLink} {
      text-align: left;
      word-break: break-all;
      color: ${COLOURS.RED.base};
    }
    .failedFile {
      width: 45rem;
      margin-right: ${SPACING.SMALL};
    }
    ${BidInputError} {
      line-height: 2rem;
      height: 5rem;
      margin-right: 0rem;
    }
  }
`;


export default StyledVendorVehicleImagesModal;
