import Api from "../Base";
import settings from "../../clientappsettings.json";

class LocationVendor extends Api {
  baseUrl = settings.VENDORS_URL;
  headers = {
    Authorization: `Bearer ${this.getCredentials()}`,
  };
}

export class FetchLocation extends LocationVendor {
    requestUrl = `/Location/Details`;
    method = "POST";
    headers = {
      Authorization: `Bearer ${this.getCredentials()}`
    };
  
    formatResponseData(response) {
      return response?.data;
    }
  }

  export class UpdateLocation extends LocationVendor {
    method = "POST";
    requestUrl = `/Location/Update`;
    formatResponseData(response) {
      return response?.data;
    }
  }