import Api from "../Base";
import settings from "../../clientappsettings.json";

class Inspections extends Api {
  baseUrl = settings.VENDORS_URL;
  headers = {
    Authorization: `Bearer ${this.getCredentials()}`,
  };
}

export class FetchVehicleRecordInspections extends Inspections {
    requestUrl = `/Inspections/${this.getParams().vehicleId}`;
    formatResponseData(response) {
      return response?.data;
    }
  }
  