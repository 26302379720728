import styled from "styled-components";

import Loader from "./Loader";
import { StyledHeaderText } from "./../HeaderText";
import breakpoints from "../../theme/breakpoints";

export const StyledLoader = styled(Loader)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  background-color: ${props => props.invert ? props.theme.COLOURS.PRIMARY.base : props.theme.COLOURS.WHITE};
  padding: ${props => props.theme.SPACING.XLARGE} 0;
  border-radius: 0;
  height: 100%;
  width: 100%;

  @media (min-width: ${breakpoints.LARGE_DESKTOP}) {
    border-radius: 1rem;
    height: ${props => props.fullScreen ? "100vh" : "auto"};
    max-height:  ${props => props.fullScreen ? "" : "28rem"};
    max-width: ${props => props.fullScreen ? "" : "55rem"};
    width: auto;
  }

  > {
    width: 100%;
  }

  ${StyledHeaderText} {
    margin-top: ${props => props.theme.SPACING.REGULAR};
    margin-bottom: 0;
    color: ${props => props.invert ? props.theme.COLOURS.WHITE : props.theme.COLOURS.PRIMARY.base};
  }

  .rotation {
    animation: rotation 2s infinite linear;
  }

  @keyframes rotation {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
`;

export default StyledLoader;
