import React, { Fragment, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { closeModal } from "../../../state/reducers/global";
import ModalInsert from "../../../views/components/ModalInsert";
import Panel from "../../../views/components/Panel";
import clientSettings from "../../../clientappsettings.json";
import {
  saveVehicleImage,
  removeVehicleImage,
  setDefaultVehicleImage,
} from "../../../state/reducers/vendor-vehicle/vehicleImages";
import VendorVehicleImagesModal from "../../components/VendorVehicleImagesModal";
import { openModal } from "../../../state/reducers/global";
import { setActiveConfirmModalOptions } from "../../../state/reducers/vendor-vehicle/genericActiveState";
import { fetchVehiclePrimaryDetails } from "../../../state/reducers/vendor-vehicle/vehicleDetails";
import Button from "../../../views/components/Button";
import { useMediaQuery } from "react-responsive";
import breakpoints from "../../../views/theme/breakpoints";
import SkeletonVendorImagesModal from "../../../views/components/Skeletons/SkeletonVendorImagesModal";

const VendorVehicleImagesModalContainer = () => {
  const dispatch = useDispatch();

  const isDesktop = useMediaQuery({
    query: `(min-width: ${breakpoints.LARGE_DESKTOP})`,
  });

  const jwtToken = useSelector((state) => state.authentication.credentials);

  const imageData = useSelector(
    (state) => state.vendorVehicleRecord.vehicleImageDetails?.data
  );
  const imageDataFetching = useSelector(
    (state) => state.vendorVehicleRecord.vehicleImageDetails.isFetching
  );
  const imageDataSaving = useSelector(
    (state) => state.vendorVehicleRecord.vehicleImageDetails.isSaving
  );

  const vehicleReferenceId = useSelector(
    (state) =>
      state.vendorVehicleRecord.genericActiveState.activeVehicleReference
  );

  const [images, setImages] = useState(imageData);
  const [changeMade, setChangeMade] = useState(false);

  useEffect(() => {
    if (imageData) {
      setImages(imageData);
    }
  }, [imageData]);

  const onClose = () => {
    changeMade && dispatch(fetchVehiclePrimaryDetails(vehicleReferenceId));
    dispatch(closeModal());
  };

  const getBlobIdFromUrl = (index) => {
    const url = images[index];
    const blobId = url && url.split("/").pop().split("?key=")[0];
    return blobId;
  };

  const onSaveVehicleImage = (data) => {
    setChangeMade(true);
    dispatch(saveVehicleImage(data));
  };

  const onDeleteVehicleImage = (index) => {
    setChangeMade(true);
    const newImages = [...images];
    newImages.splice(index, 1);
    setImages(newImages);
    const handleData = {
      vehicleId: vehicleReferenceId,
      blobId: getBlobIdFromUrl(index),
    };
    dispatch(removeVehicleImage(handleData));
  };

  const onSetDefaultImage = (index) => {
    setChangeMade(true);
    const handleData = {
      vehicleId: vehicleReferenceId,
      blobId: getBlobIdFromUrl(index),
    };
    dispatch(setDefaultVehicleImage(handleData));
  };

  const onSetImageOrder = (data) => {
    // todo: to be implemented down the line...
    //setChangeMade(true);
    //dispatch(setImageOrder(data));
  };

  const onDeleteAllImages = () => {
    const blobIds = images.map((_, index) => getBlobIdFromUrl(index));
    const type = "delete-all-images";
    const data = {
      action: type,
      vehicleReference: vehicleReferenceId,
      images: blobIds,
    };
    dispatch(setActiveConfirmModalOptions(type, data));
    return dispatch(openModal("vendor-vehicle-record-are-you-sure-modal"));
  };

  let disableButtons =
    images?.length === 0 || imageDataFetching || imageDataSaving;

  return (
    <Fragment>
      <ModalInsert>
        <Panel mobileFlex>
          {!imageDataFetching ? (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                width: isDesktop ? "auto" : "100%",
              }}
            >
              <VendorVehicleImagesModal
                images={images}
                setImages={setImages}
                vehicleReferenceId={vehicleReferenceId}
                onSaveVehicleImage={onSaveVehicleImage}
                onDeleteVehicleImage={onDeleteVehicleImage}
                onSetDefaultImage={onSetDefaultImage}
                onSetImageOrder={onSetImageOrder}
                onDeleteAllImages={onDeleteAllImages}
                imageDataFetching={imageDataFetching}
                imageDataSaving={imageDataSaving}
              />
              <div style={{ marginTop: "1rem" }}>
                <hr />
                <div
                  style={{
                    display: "flex",
                    flexDirection: isDesktop ? "" : "column",
                    paddingTop: "1rem",
                    justifyContent: "space-between",
                  }}
                >
                  <div style={{ display: "flex", flex: 1 }}>
                    <Button
                      errorPrimary
                      content={"Delete All"}
                      action={() => onDeleteAllImages()}
                      disabled={disableButtons}
                      style={{ flex: 1 }}
                    />
                    <Button
                      content={""}
                      disabled={true}
                      style={{ flex: 1, visibility: "hidden" }}
                    />
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flex: 1,
                      justifyContent: isDesktop ? "flex-end" : "space-between",
                    }}
                  >
                    <Button
                      primaryAlt
                      download
                      content={"Image Pack"}
                      action={() => {
                        window.location.href = `${clientSettings.IMAGES_API_URL}/ImagePack/${vehicleReferenceId}?jwtToken=${jwtToken}`;
                      }}
                      disabled={disableButtons}
                      style={{ flex: 1 }}
                    />
                    <Button
                      ghost
                      content={"Close"}
                      action={onClose}
                      style={{ flex: 1 }}
                    />
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <SkeletonVendorImagesModal />
          )}
        </Panel>
      </ModalInsert>
    </Fragment>
  );
};

export default VendorVehicleImagesModalContainer;
