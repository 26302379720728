import React from "react";
import InputText from "../../../views/components/InputText/index.js";
import { clearFormButton, formButton, inputPlaceHolders } from "./const.js";
import Button from "../../../views/components/Button";
import SelectDropdown from "../../../views/containers/SelectDropdown";
import ResultsNumber from "../ResultsNumber";
import Pill from "../../../views/components/Pill";

const CapVehicleLookup = ({
  className,
  capId,
  capCode,
  description,
  onChangeCapCode,
  onChangeCapId,
  onChangeDescription,
  onFormSubmit,
  onFormClear,
  selectedMakes,
  setSelectedMakes,
  selectedModels,
  setSelectedModels,
  isFetchingCapMake,
  isFetchingCapModel,
  capMakeOptions,
  capModelOptions,
  disableConfirm,
  disableClear,
  numberOfResults,
}) => {
  const onMakeCheckboxClick = (option) => {
    setSelectedMakes({
      key: option.key,
      label: option.value,
      value: option.value,
    });
  };

  const onModelCheckboxClick = (option) => {
    setSelectedModels({
      key: option.key,
      label: option.value,
      value: option.value,
    });
  };

  const disableModelDropdown = selectedMakes === null || selectedMakes === "";

  return (
    <div className={className}>
      <div className="form-container">
        <div className="grid-container">
          <SelectDropdown
            options={capMakeOptions}
            placeholder={inputPlaceHolders.MAKE}
            onChange={(e) => onMakeCheckboxClick(e)}
            isSearchable={true}
            isFetching={isFetchingCapMake}
            width={"100%"}
            value={selectedMakes}
          />

          <SelectDropdown
            options={capModelOptions}
            placeholder={inputPlaceHolders.MODEL}
            onChange={(e) => onModelCheckboxClick(e)}
            isSearchable={true}
            isFetching={isFetchingCapModel}
            disabled={disableModelDropdown}
            width={"100%"}
            value={selectedModels}
          />

          <InputText
            name="vehicleDescription-input"
            inputType={"text"}
            value={description || ""}
            autoComplete="off"
            validValue={true}
            onChange={(e) => onChangeDescription(e.target.value)}
            placeholder={inputPlaceHolders.DESCRIPTION}
          />

          <InputText
            name="capId-input"
            inputType={"number"}
            value={capId || ""}
            autoComplete="off"
            min={1}
            validValue={true}
            onChange={(e) => onChangeCapId(e.target.value)}
            placeholder={inputPlaceHolders.ID}
          />

          <InputText
            name="capCode-input"
            inputType={"text"}
            value={capCode || ""}
            autoComplete="off"
            validValue={true}
            onChange={(e) => onChangeCapCode(e.target.value)}
            placeholder={inputPlaceHolders.CODE}
          />

          <div className="resultsAndButton-container">
            <div className="results">
              <ResultsNumber count={numberOfResults} unit="Vehicle" />
            </div>

            <div className="pillAndButton">
              <div className="pill-container">
                <Pill
                  className="formClear-button"
                  content={clearFormButton}
                  action={() => onFormClear()}
                  disabled={disableClear}
                  dark
                />
              </div>

              <Button
                secondary
                content={formButton}
                disabled={disableConfirm}
                action={() => onFormSubmit()}
                className="formSubmit-button"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CapVehicleLookup;
