import styled from "styled-components";
import ProgressBar from "./ProgressBar";

export const StyledProgressBar = styled(ProgressBar)`
  span {
    margin-left: 1rem;
  }

  progress {
    align-self: center;
`;

export default StyledProgressBar;
