const PRIMARY = {
  base: "#434C53",
  shades: {
    5: "#eeeff0",
    10: "#ecedee",
    20: "#d9dbdd",
    30: "#c7c9cb",
    40: "#b4b7ba",
    50: "#a1a6a9",
    60: "#8e9498",
    70: "#7b8287",
    80: "#697075",
    90: "#565e64",
  },
};

const ACCENT = {
  base: "#BB0A30",
  shades: {
    10: "#f8e7ea",
    20: "#f1ced6",
    30: "#ebb6c1",
    40: "#e49dac",
    50: "#dd8598",
    60: "#d66c83",
    70: "#cf546e",
    80: "#c93b59",
    90: "#c22345",
  },
};

const SECONDARY = {
  base: "#22262a",
  shades: {
    5: "#ebecec",
    10: "#e9e9ea",
    20: "#d3d4d4",
    30: "#bdbebf",
    40: "#a7a8aa",
    50: "#919395",
    60: "#7a7d7f",
    70: "#64676a",
    80: "#4e5155",
    90: "#383c3f",
  },
};

const SECONDARY_ACCENT = {
  base: "#5e0518",
  shades: {
    10: "#efe6e8",
    20: "#dfcdd1",
    30: "#cfb4ba",
    40: "#bf9ba3",
    50: "#af828c",
    60: "#9e6974",
    70: "#8e505d",
    80: "#7e3746",
    90: "#6e1e2f",
  },
};

const GREEN = {
  base: "#429D16",
  shades: {
    10: "#ECF5E7",
    20: "#D9EBD0",
    30: "#C6E1B9",
    40: "#B2D8A2",
    50: "#A0CE8A",
    60: "#8EC473",
    70: "#7ABA5B",
    80: "#68B145",
    90: "#54A62D",
  },
};

const RED = {
  base: "#F22B2B",
  shades: {
    10: "#FDE9E9",
    20: "#FCD5D5",
    30: "#FBBFBF",
    40: "#FAAAAA",
    50: "#F89595",
    60: "#F78080",
    70: "#F56A6A",
    80: "#F55555",
    90: "#F34040",
  },
};

const ORANGE = {
  base: "#FE7214",
  shades: {
    10: "#FEF0E7",
    20: "#FFE3D0",
    30: "#FED4B8",
    40: "#FFC7A1",
    50: "#FEB889",
    60: "#FEAA72",
    70: "#FE9C5A",
    80: "#FE8E43",
    90: "#FE802B",
  },
};

const TRANSPARENCIES = {
  backgroundImage: "rgba(0,0,0,0.4)",
  primary: "rgba(51,48,46,0.3)",
  primaryDark: "rgba(51,48,46,0.9)",
  green: "rgba(66,157,22,0.05)",
  white: "rgba(27, 34, 59, 0.1)",
  semiWhite: "rgba(255, 255, 255, 0.125)",
};

const COLOURS = {
  PRIMARY,
  SECONDARY,
  ACCENT,
  SECONDARY_ACCENT,
  RED,
  GREEN,
  ORANGE,
  BLACK: "#000000",
  BLUE: "#2183CD",
  BROWN: "#964B00",
  WHITE: "#FFFFFF",
  YELLOW: "#feb40c",
  SILVER: "#C0C0C0",
  GREY: "#808080",
  CREAM: "#FFFDD0",
  GOLD: "#FFD700",
  PINK: "#FFC0CB",
  PURPLE: "#A020F0",
  BEIGE: "#F5F5DC",
  BRONZE: "#CD7F32",
  MAROON: "#800000",
  TURQUOISE: "#40e0d0",
  ACTION_GREEN: "#4D9152",
  ALERT_RED: "#C33939",
  TRANSPARENCIES,
};

export default COLOURS;
