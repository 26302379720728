import React from 'react';
import propTypes from 'prop-types';

const InputText = (props) => {
  const {
    className,
    validValue,
    small,
    medium,
    withoutBorder,
    inputType,
    min,
    max,
    ...rest
  } = props;

  const textType = inputType || "text";

  const inputProps = {
    type: textType,
    className: className,
    min: textType === "number" ? min : undefined,
    max: textType === "number" ? max : undefined,
    ...rest,
  };

  return <input {...inputProps} />;
};

InputText.propTypes = {
  className: propTypes.string,
  validValue: propTypes.bool,
  small: propTypes.bool,
  medium: propTypes.bool,
  withoutBorder: propTypes.bool,
  inputType: propTypes.oneOf(['text', 'number']), 
  min: propTypes.number, 
  max: propTypes.number, 
};

export default InputText;
