import Api from "../Base";
import settings from "../../clientappsettings.json";
import { getResponseObjectProperties } from "../../shared/utility";
import get from "lodash.get";

class VehicleDetailsVendor extends Api {
  baseUrl = settings.VENDORS_URL;
  headers = {
    Authorization: `Bearer ${this.getCredentials()}`,
  };
}

export class FetchVehicleRecordPrimaryDetails extends VehicleDetailsVendor {
  requestUrl = `/Vehicle/PrimaryDetails/${this.getParams().vehicleId}`;
  formatResponseData(response) {
    const titleDetails = ["regNo", "make", "model", "derivative"];

    const keyInfoDetails = [
      "odometer",
      "odometerType",
      "odometerReadingDate",
      "odometerConfirmed",
      "dateOfRegistration",
      "fuelType",
      "transmissionType",
      "primaryColour",
      "colour",
      "bodyStyle",
      "capId",
      "capCode",
      "modelYear",
      "auctionGrade",
      "shortListCount",
      "hasVmsData",
      "allowVms",
      "hasOnlineProfile",
      "allocatedToSite",
    ];

    const otherDetails = [
      "v5Status",
      "motStatus",
      "motExpiryDate",
      "hasInspection",
      "auctionGrade",
      "capCondition",
      "capValuation",
      "capValuationDate",
      "serviceHistory",
      "mostRecentInspectionId",
    ];

    const statusDetails = [
      "currentStatus",
      "defleetStartDate",
      "dateOfReturn",
      "daysInStock",
      "estimatedReturnDate",
      "returnStatus",
      "holdStatus",
      "workshopStatus",
      "holdNotes",
      "holdReason",
      "workshopReason",
      "vendorNotes",
    ];

    return {
      vehicle360Url: get(response.data, "vehicle360Url", ""),
      galleryInfo: get(response.data, "vehicleBlobUrls", []),
      titleInfo: getResponseObjectProperties(response.data, titleDetails, true),
      keyInfo: getResponseObjectProperties(response.data, keyInfoDetails, true),
      gradeOtherInfo: getResponseObjectProperties(
        response.data,
        otherDetails,
        true
      ),
      statusInfo: getResponseObjectProperties(
        response.data,
        statusDetails,
        true
      ),
    };
  }
}

export class FetchVehicleRecordMoreInfoDetails extends VehicleDetailsVendor {
  requestUrl = `/Vehicle/MoreInfoDetails/${this.getParams().vehicleId}`;
  formatResponseData(response) {
    const otherVehicleInfoDetails = [
      "vehicleType",
      "vehicleTypeOptions",
      "damagePercentage",
      "interiorColour",
      "numberOfSeats",
      "numberOfDoors",
      "maxTorque",
      "maxSpeed",
      "engineSize",
      "power",
      "auctionGradeSiteName",
      "auctionGradeSiteType",
      "auctionGradeSiteId",
      "conditionOptions",
    ];
    const contractualInfoDetails = [
      "leaseType",
      "collectionOdometer",
      "collectionOdometerType",
      "collectionDate",
      "contractNumber",
      "contractStartDate",
      "contractEndDate",
      "contractTerm",
      "contractDistance",
      "originalPurchasePrice",
    ];
    const legalInfoDetails = [
      "cherishedPlate",
      "vin",
      "formerKeepers",
      "insuranceGroup",
      "emissions",
      "serviceHistory",
      "serviceHistoryOptions",
      "motStatusOptions",
      "v5Status",
      "v5StatusOptions",
      "v5documentReferenceNumber",
      "assetId",
    ];
    return {
      otherVehicleInfo: getResponseObjectProperties(
        response.data,
        otherVehicleInfoDetails,
        true
      ),
      contractualInfo: getResponseObjectProperties(
        response.data,
        contractualInfoDetails,
        true
      ),
      legalInfo: getResponseObjectProperties(
        response.data,
        legalInfoDetails,
        true
      ),
    };
  }
}

export class ModifyVehicleDetails extends VehicleDetailsVendor {
  baseUrl = settings.VENDORS_URL;
  requestUrl = `/Vehicle/ModifyVehicleDetails`;
  headers = {
    Authorization: `Bearer ${this.getCredentials()}`,
  };
  method = "POST";
  formatResponseData(response) {
    return response?.data;
  }
}

export class FetchAdditionalEquipment extends VehicleDetailsVendor {
  requestUrl = `/Vehicle/AdditionalEquipment/${this.getParams().vehicleId}`;
  formatResponseData(response) {
    return response?.data;
  }
}

export class UpdateAdditionalEquipment extends VehicleDetailsVendor {
  method = "POST";
  requestUrl = `/Vehicle/AdditionalEquipment/Update`;
  formatResponseData(response) {
    return response?.data;
  }
}

export class FetchAuctionGradeSites extends VehicleDetailsVendor {
  baseUrl = settings.VENDORS_URL;
  requestUrl = `/Vehicle/GetAuctionGradeSites`;
  headers = {
    Authorization: `Bearer ${this.getCredentials()}`,
  };
}

export class UpdateAuctionGradeAndSite extends VehicleDetailsVendor {
  method = "POST";
  requestUrl = `/Vehicle/UpdateAuctionGradeAndSite`;
  formatResponseData(response) {
    return response?.data;
  }
}

export class FetchGenericColour extends VehicleDetailsVendor {
  baseUrl = settings.API_URL;
  requestUrl = `/Vehicle/RetrieveDVLAData/${this.getParams().vehicleId}`;
  formatResponseData(response) {
    return response?.data;
  }
}

export class VehicleRemoveFromSale extends VehicleDetailsVendor {
  baseUrl = settings.VENDORS_URL;
  requestUrl = `/Vehicle/RemoveFromSale/${this.getParams().vehicleId}`;
  headers = {
    Authorization: `Bearer ${this.getCredentials()}`,
  };
  method = "POST";
  formatResponseData(response) {
    return response?.data;
  }
}

export class AddVehicleWorkshop extends VehicleDetailsVendor {
  baseUrl = settings.VENDORS_URL;
  requestUrl = `/Vehicle/Workshop/Add`;
  headers = {
    Authorization: `Bearer ${this.getCredentials()}`,
  };
  method = "POST";
  formatResponseData(response) {
    return response?.data;
  }
}

export class RemoveVehicleWorkshop extends VehicleDetailsVendor {
  baseUrl = settings.VENDORS_URL;
  requestUrl = `/Vehicle/Workshop/Remove`;
  headers = {
    Authorization: `Bearer ${this.getCredentials()}`,
  };
  method = "POST";
  formatResponseData(response) {
    return response?.data;
  }
}

export class GetVendorHoldReasons extends VehicleDetailsVendor {
  baseUrl = settings.VENDORS_URL;
  requestUrl = `/Vehicle/Hold/Reasons/Vendor`;
  headers = {
    Authorization: `Bearer ${this.getCredentials()}`,
  };
}

export class GetVehicleHoldReasons extends VehicleDetailsVendor {
  baseUrl = settings.VENDORS_URL;
  requestUrl = `/Vehicle/Hold/Reasons/${this.getParams().vehicleId}`;
  headers = {
    Authorization: `Bearer ${this.getCredentials()}`,
  };
}

export class AddVehicleHold extends VehicleDetailsVendor {
  baseUrl = settings.VENDORS_URL;
  requestUrl = `/Vehicle/Hold/Add`;
  headers = {
    Authorization: `Bearer ${this.getCredentials()}`,
  };
  method = "POST";
  formatResponseData(response) {
    return response?.data;
  }
}

export class ModifyVehicleHold extends VehicleDetailsVendor {
  baseUrl = settings.VENDORS_URL;
  requestUrl = `/Vehicle/Hold/Modify`;
  headers = {
    Authorization: `Bearer ${this.getCredentials()}`,
  };
  method = "POST";
  formatResponseData(response) {
    return response?.data;
  }
}

export class RemoveVehicleHold extends VehicleDetailsVendor {
  baseUrl = settings.VENDORS_URL;
  requestUrl = `/Vehicle/Hold/Remove`;
  headers = {
    Authorization: `Bearer ${this.getCredentials()}`,
  };
  method = "POST";
  formatResponseData(response) {
    return response?.data;
  }
}

export class VehicleCancelPayment extends VehicleDetailsVendor {
  baseUrl = settings.VENDORS_URL;
  requestUrl = `/Vehicle/CancelPayment/${this.getParams().vehicleId}`;
  headers = {
    Authorization: `Bearer ${this.getCredentials()}`,
  };
  method = "POST";
  formatResponseData(response) {
    return response?.data;
  }
}

export class VehicleCancelReturn extends VehicleDetailsVendor {
  baseUrl = settings.VENDORS_URL;
  requestUrl = `/Vehicle/CancelReturn/${this.getParams().vehicleId}`;
  headers = {
    Authorization: `Bearer ${this.getCredentials()}`,
  };
  method = "POST";
  formatResponseData(response) {
    return response?.data;
  }
}

export class GetVehicleImages extends VehicleDetailsVendor {
  baseUrl = settings.VENDORS_URL;
  requestUrl = `/Vehicle/MarketingImages/${this.getParams().vehicleId}`;
  headers = {
    Authorization: `Bearer ${this.getCredentials()}`,
  };
}

export class AddVehicleImage extends VehicleDetailsVendor {
  baseUrl = settings.IMAGES_API_URL;
  requestUrl = `/VehicleImage/add`;
  headers = {
    Authorization: `Bearer ${this.getCredentials()}`,
  };
  method = "POST";
  formatResponseData(response) {
    return response?.data;
  }
}

export class RemoveVehicleImage extends VehicleDetailsVendor {
  baseUrl = settings.IMAGES_API_URL;
  requestUrl = `/VehicleImage/remove`;
  headers = {
    Authorization: `Bearer ${this.getCredentials()}`,
  };
  method = "POST";
  formatResponseData(response) {
    return response?.data;
  }
}

export class SetDefaultVehicleImage extends VehicleDetailsVendor {
  baseUrl = settings.IMAGES_API_URL;
  requestUrl = `/VehicleImage/setDefault`;
  headers = {
    Authorization: `Bearer ${this.getCredentials()}`,
  };
  method = "POST";
  formatResponseData(response) {
    return response?.data;
  }
}

export class VehicleCancelDefleet extends VehicleDetailsVendor {
  baseUrl = settings.VENDORS_URL;
  requestUrl = `/Vehicle/CancelDefleet/${this.getParams().vehicleId}`;
  headers = {
    Authorization: `Bearer ${this.getCredentials()}`,
  };
  method = "POST";
  formatResponseData(response) {
    return response?.data;
  }
}

export class VehicleReceivePayment extends VehicleDetailsVendor {
  baseUrl = settings.VENDORS_URL;
  requestUrl = `/Vehicle/ReceivePayment`;
  headers = {
    Authorization: `Bearer ${this.getCredentials()}`,
  };
  method = "POST";
  formatResponseData(response) {
    return response?.data;
  }
}

export class VehiclePublish extends VehicleDetailsVendor {
  baseUrl = settings.VENDORS_URL;
  requestUrl = `/Vehicle/Publish`;
  headers = {
    Authorization: `Bearer ${this.getCredentials()}`,
  };
  method = "POST";
  formatResponseData(response) {
    return response?.data;
  }
}
