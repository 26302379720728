import React from "react";
import { headers } from "../const";
import ParagraphText from './../../../../views/components/ParagraphText';

const CapVehicleLookupTableHeader = () => {
  return (
    <div>
      <div className="profiles-container">
        <div className="profiles__table">
          <table>
            <thead>
              <tr>
                {Object.values(headers).map((header, index) => (
                  <th key={index} className="columnWidths">
                    <ParagraphText className="table-headings">
                      {header}
                    </ParagraphText>
                  </th>
                ))}
              </tr>
            </thead>
            <tbody></tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default CapVehicleLookupTableHeader;
