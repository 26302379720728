import Api from "./Base";
import settings from "../clientappsettings.json";

class Actions extends Api {
  baseUrl = settings.VENDORS_URL;
  headers = {
    Authorization: `Bearer ${this.getCredentials()}`,
  };
}

export class FetchActionItems extends Actions {
    method = "POST";
    requestUrl = `/Actions/ActionItems`;
    formatResponseData(response) {
      return response?.data;
    }
  }