import styled from "styled-components";
import BucketTypeFilter from "./BucketTypeFilter";

const StyledBucketTypeFilter = styled(BucketTypeFilter)`
  .action__container {
    height: ${(props) => (props.autoScaleOptions && "6rem") || ""};
    list-style: none;
    display: flex;
    flex-direction: row;
    border: 0.2rem solid ${(props) => props.theme.COLOURS.PRIMARY.shades[20]};
    border-radius: 0.5rem;
    padding: 0;
    background-color: ${(props) => props.theme.COLOURS.WHITE};
  }
  .action__button,
  .action__button label {
    ${(props) => props.theme.FONTS.Text["paragraphCompact"]};
    color: ${(props) => props.theme.COLOURS.PRIMARY.shades[50]};
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .action__button {
    width: ${(props) => (props.autoScaleOptions && "33.33%") || "11.3rem"};
    cursor: pointer;
    label {
      width: 100%;
      padding: ${(props) => props.theme.SPACING.REGULAR} 0;
      div {
        margin: 0 auto;
      }
      .radio-input__indicator {
        display: none;
      }
    }
    &:first-child {
      border-radius: 0.5rem 0 0 0.5rem;
    }
    &:nth-child(2) {
      border-right: 0.2rem solid
        ${(props) => props.theme.COLOURS.PRIMARY.shades[20]};
      border-left: 0.2rem solid
        ${(props) => props.theme.COLOURS.PRIMARY.shades[20]};
    }
    &:nth-child(3) {
      border-radius: 0 0.5rem 0.5rem 0;
    }
    &:hover,
    &.checked {
      background-color: ${(props) => props.theme.COLOURS.PRIMARY.base};
      & label {
        color: ${(props) => props.theme.COLOURS.WHITE};
      }
    }
  }
  .action__container {
    &.disabled,
    &.disabled .action__button {
      pointer-events: none;
      background-color: ${(props) => props.theme.COLOURS.SECONDARY.shades[20]};
      &:hover,
      &.checked {
        background-color: ${(props) =>
    props.theme.COLOURS.SECONDARY.shades[20]};
        & label {
          color: ${(props) => props.theme.COLOURS.PRIMARY.shades[50]};
        }
      }
    }
  }
`;

export default StyledBucketTypeFilter;
