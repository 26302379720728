import styled from "styled-components";
import CapVehicleLookup from "./CapVehicleLookup";

const StyledCapVehicleLookup = styled(CapVehicleLookup)`
  .form-container {
    .grid-container {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: repeat(3, auto);
      gap: 10px;
    }

    input {
      font-size: 1.6rem;
    }

    .dropdown-disabled {
      button {
        pointer-events: none;
        .selectedOption-container {
          p {
            color: ${(props) => props.theme.COLOURS.PRIMARY.shades[40]};
          }
          .dropdown__trigger-icon {
            color: ${(props) => props.theme.COLOURS.PRIMARY.shades[40]};
          }
        }
      }
    }

    .formSubmit-button {
      margin-left: 0;
      width: min-content;
      justify-self: end;
    }

    .resultsAndButton-container {
      display: flex;
      justify-content: space-between;
      padding-left: 0.6rem;

      .results {
        display: flex;
        align-items: flex-end;
      }
    }

    .pill-container {
      display: flex;
      align-items: center;
    }

    .pillAndButton {
      display: flex;
      gap: 1rem;
    }
  }
`;

export default StyledCapVehicleLookup;
