import styled from "styled-components";
import CapVehicleLookupTable from "./CapVehicleLookupTable.jsx";
import spacing from "../../../views/theme/base/spacing";
import ParagraphText from "../../../views/components/ParagraphText";
import HeaderText from "../../../views/components/HeaderText";
import Dropdown from "../../../views/components/Dropdown";
import Icon from "../../../views/components/Icon";

const StyledCapVehicleLookupTable = styled(CapVehicleLookupTable)`
  border-spacing: 0;
  margin-bottom: 0;

  .row-container {
    max-height: 20rem;
    overflow-y: auto; 
  }

  .title-container {
    background-color:${(props) => props.theme.COLOURS.PRIMARY.base};
    width: 100%;
    margin-top: 2rem;
    ${HeaderText} {
      color: ${(props) => props.theme.COLOURS.WHITE};
      padding: 1rem;
    }
  }

  .iconContainer {
  display: flex;
    justify-content: center;
    gap: 0.8rem;
    margin: 1rem 0;

    ${Icon} {
      cursor: pointer;
    }
}

  .main-text {
    display: flex;
    justify-content: space-between;
  }

  .header-section {
    margin: 2rem 0 0 0;
    gap: 4rem;
  }

  .subtitle {
    margin-right: 1rem;
    color:${(props) => props.theme.COLOURS.ACCENT.base};
    font-weight: 600;
  }

  ${Dropdown} {
    width: 35rem;
  }

  .returnedValue {
    text-align: center;
  }

  .searchIcon {
    margin-left: 1rem;
  }

  .header-section, .header-row {
    display: flex;
  }

  .header-row {
    align-items: center;
  }

  .row {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;
  }

  .columnWidths {
    align-content: center;
    width: 20%;
}

.columnWidths:nth-child(1) {
    width: 40%;
  }

  .columnWidths:last-child {
    width: 5%;
  }

  .sub-text {
    margin-top: ${spacing.SMALL};
    margin-bottom: ${spacing.SMALL};
  }

  table {
    width: 100%;
    table-layout: fixed;
  }

  th {
    padding: ${spacing.REGULAR} 0;
    margin-left: 0;
    text-align: left;
    border-bottom: 2px solid ${(props) => props.theme.COLOURS.PRIMARY.base};
    text-align: center;

    ${ParagraphText} {
      font-weight: 600;
    }
  }

  tr {
    .groupText {
    text-align: center;
  }
  }

  .table-headings {
    color: ${(props) => props.theme.COLOURS.PRIMARY.base};
  }


  th:nth-child(1) {
    border-top-left-radius: 1rem;
  }

  th:nth-child(4) {
    border-top-right-radius: 1rem;
  }
 
  tbody:nth-child(odd) {
    background-color: ${(props) => props.theme.COLOURS.PRIMARY.shades[10]};
  }

  td {
    border: hidden;
    vertical-align: middle;
    padding: 1rem 0;
  }

  td:nth-child(1) {
    padding-left: 1rem
  }


.info-options-containers-column {
  width: 100%;
  display: flex;
  flex-direction: column;

  .info-options-row {
    width: 100%;
    display: flex;
    margin: 0 0 ${(props) => props.theme.SPACING.SMALL} 0;

    p {
      width: 100%;
      margin: 0;
      span {
        color: ${(props) => props.theme.COLOURS.PRIMARY.shades[60]};
      }
    }
    p:nth-of-type(2) {
      color: ${(props) => props.theme.COLOURS.PRIMARY.base};
    }
  }
}

.button-container {
    display: flex;
    justify-content: space-between;
    gap: 10rem;
    margin-top: 2rem;
}

.switch-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.label-text {
    color: ${(props) => props.theme.COLOURS.PRIMARY.base};
    padding-left: ${(props) => props.theme.SPACING.SMALL};
}

`;

export default StyledCapVehicleLookupTable;
