import {
  FetchLocation,
  UpdateLocation,
} from "../../../api/vendor-vehicle/Location";
import { VENDOR_VEHICLE_LOCATION_MAP, } from "../../../shared/utility";
import { openModal, closeModal } from "./../global";
import { clearActiveConfirmModalOptions } from "./genericActiveState";

export const FETCH_LOCATION =
  "@location/FETCH_LOCATION";
export const FETCH_LOCATION_SUCCESS =
  "@location/FETCH_LOCATION_SUCCESS";
export const FETCH_LOCATION_ERROR =
  "@location/FETCH_LOCATION_ERROR";
export const UPDATE_LOCATION =
  "@location/UPDATE_LOCATION";
export const UPDATE_LOCATION_SUCCESS =
  "@location/UPDATE_LOCATION_SUCCESS";
export const UPDATE_LOCATION_ERROR =
  "@location/UPDATE_LOCATION_ERROR";
export const COPY_DRIVER_LOCATION_DATA =
  "@location/COPY_DRIVER_LOCATION_DATA";
export const COPY_CUSTOMER_LOCATION_DATA =
  "@location/COPY_CUSTOMER_LOCATION_DATA";

export const fetchLocation =
  (vehicleId, locationType) => (dispatch, getState) => {
    const locationTypeKey = Object.keys(VENDOR_VEHICLE_LOCATION_MAP).find(
      (key) => VENDOR_VEHICLE_LOCATION_MAP[key] === locationType
    );

    const formattedLocationTypeKey = locationTypeKey.toLowerCase();

    dispatch({
      type: FETCH_LOCATION,
      payload: { locationType: formattedLocationTypeKey },
    });

    const vehicleDetailsInstance = new FetchLocation({
      params: {
        vehicleId: vehicleId,
        locationType: locationType,
      },
      credentials: getState().authentication.credentials,
    });

    vehicleDetailsInstance.call().then(
      (response) => {
        dispatch(
          fetchLocationSuccess(formattedLocationTypeKey, response)
        );
      },
      (err) => {
        dispatch(fetchLocationError(formattedLocationTypeKey, err));
      }
    );
  };

export const fetchLocationSuccess = (locationType, data) => {
  return {
    type: FETCH_LOCATION_SUCCESS,
    payload: {
      locationType,
      data,
    },
  };
};

export const fetchLocationError = (locationType, error) => {
  return {
    type: FETCH_LOCATION_ERROR,
    payload: {
      locationType,
      error,
    },
  };
};

export const updateLocation =
  (vehicleId, locationUpdateRequest, locationType) => (dispatch, getState) => {
    dispatch({ type: UPDATE_LOCATION });
    dispatch(openModal("generic-loader-modal"));

    const contactAddressString =
      locationUpdateRequest.contactAddress?.join("\n");

    const params = {
      vehicleId: vehicleId,
      locationName: locationUpdateRequest.locationName,
      contactName: locationUpdateRequest.contactName,
      contactAddress: contactAddressString,
      postCode: locationUpdateRequest.contact,
      contactTelephone: locationUpdateRequest.contactTelephone,
      contactEmail: locationUpdateRequest.contactEmail,
      contactSMS: locationUpdateRequest.contactSMS,
      notes:locationUpdateRequest.notes,
    };

    if (locationType !== "Location") {
      params.locationId = locationUpdateRequest.locationId;
    }

    const vehicleDetailsInstance = new UpdateLocation({
      params: params,
      credentials: getState().authentication.credentials,
    });

    vehicleDetailsInstance.call().then(
      (response) => {
        dispatch(updateLocationSuccess(locationUpdateRequest, locationType));
        dispatch(openModal("vendor-vehicle-record-success-modal"));
        setTimeout(() => {
          dispatch(closeModal());
          dispatch(clearActiveConfirmModalOptions());
        }, 1000);
      },
      (err) => {
        dispatch(updateLocationError(err));
        dispatch(openModal("generic-error"));
      }
    );
  };

const updateLocationSuccess = (data, locationType) => {
  return {
    type: UPDATE_LOCATION_SUCCESS,
    payload: { data, locationType },
  };
};

const updateLocationError = (error) => {
  return {
    type: UPDATE_LOCATION_ERROR,
    payload: error,
  };
};

export const copyDriverLocationData = (
  vehicleReference,
  locationUpdateRequest
) => {
  return (dispatch) => {
    dispatch({
      type: COPY_DRIVER_LOCATION_DATA,
    });
    dispatch(
      updateLocation(vehicleReference, locationUpdateRequest, "Customer"),
    );
  };
};

export const copyCustomerLocationData = (
  vehicleReference,
  locationUpdateRequest
) => {
  return (dispatch) => {
    dispatch({
      type: COPY_CUSTOMER_LOCATION_DATA,
    });
    dispatch(updateLocation(vehicleReference, locationUpdateRequest, "Driver"));
  };
};


const initialState = {
  data: {
    history: null,
    current_location: null,
    driver_location: null,
    customer_location: null,
    defleet_location: null,
    driver_and_customer_location: null,
  },
  isFetching: {
    history: false,
    current_location: false,
    driver_location: false,
    customer_location: false,
    defleet_location: false,
    driver_and_customer_location: false,
  },
  error: null,
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_LOCATION:
      return {
        ...state,
        isFetching: {
          ...state.isFetching,
          [action.payload.locationType]: true,
        },
      };
    case FETCH_LOCATION_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          [action.payload.locationType]: action.payload.data,
        },
        isFetching: {
          ...state.isFetching,
          [action.payload.locationType]: false,
        },
      };
    case FETCH_LOCATION_ERROR:
      return {
        ...state,
        isFetching: {
          ...state.isFetching,
          [action.payload.locationType]: false,
        },
        error: action.payload,
      };

    case COPY_DRIVER_LOCATION_DATA:
      return {
        ...state,
        data: {
          ...state.data,
          driver_and_customer_location: [
            ...state.data.driver_and_customer_location,
            state.data.driver_and_customer_location[0],
          ].map((item, index) =>
            index === 1
              ? state.data.driver_and_customer_location[0]
              : item
          ),
        },
      };
    case COPY_CUSTOMER_LOCATION_DATA:
      return {
        ...state,
        data: {
          ...state.data,
          driver_and_customer_location: [
            ...state.data.driver_and_customer_location,
            state.data.driver_and_customer_location[1],
          ].map((item, index) =>
            index === 0
              ? state.data.driver_and_customer_location[1]
              : item
          ),
        },
      };
    case UPDATE_LOCATION:
      return {
        ...state,
        isFetching: {
          ...state.isFetching,
          driver_and_customer_location: true,
        },
      };
    case UPDATE_LOCATION_SUCCESS:
      return {
        ...state,
        data: {
          driver_and_customer_location:
            action.payload.locationType !== "Location"
              ? state.data.driver_and_customer_location.map(
                (location, index) => {
                  if (
                    (action.payload.locationType === "Driver" &&
                      index === 0) ||
                    (action.payload.locationType === "Customer" &&
                      index === 1)
                  ) {
                    return {
                      ...location,
                      ...action.payload.data,
                    };
                  }
                  return location;
                }
              )
              : state.data.driver_and_customer_location,
          history:
            action.payload.locationType === "Location"
              ? [
                {
                  ...action.payload.data,
                  daysAtLocation: 0,
                },
                ...(state.data.history || []),
              ]
              : state.data.history,
        },
        isFetching: {
          ...state.isFetching,
          driver_and_customer_location:
            action.payload.locationType !== "Location"
              ? false
              : state.isFetching.driver_and_customer_location,
          history:
            action.payload.locationType === "Location"
              ? false
              : state.isFetching.history,
        },
      };
    case UPDATE_LOCATION_ERROR:
      return {
        ...state,
        isFetching: {
          ...state.isFetching,
          driver_and_customer_location: false,
        },
        error: action.payload,
      };
    default:
      return state;
  }
};

export default reducer;