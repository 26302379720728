import React, { useEffect } from "react";
import AddressPicker from "../../../views/components/AddressPicker/index.js";
import { useSelector, useDispatch } from "react-redux";
import { clearSelectedAddress } from "../../../state/reducers/addressLookup.js";
import { find, capture } from "../../../state/reducers/addressLookup.js";
import InputLabel from "../../../views/components/InputLabel/index.js";
import InputText from "../../../views/components/InputText/index.js";
import { labels, mainHeader } from "./const.js";
import HeaderText from "../../../views/components/HeaderText/index.js";
import TextArea from "../../../views/components/TextArea";

const LocationEditModal = ({
  className,
  contact,
  onChangeContactName,
  address,
  setAddress,
  primaryNumber,
  onChangePrimaryNumber,
  validPrimaryNumber,
  secondaryNumber,
  onChangeSecondaryNumber,
  validSecondaryNumber,
  email,
  onChangeEmail,
  validEmail,
  setUpdatedAddress,
  locationType,
  locationName,
  setLocationName,
  onChangeLocationName,
  setValidAddress,
  notes,
  onChangeNotes
}) => {
  const dispatch = useDispatch();

  const addressLookup = useSelector((state) => state.addressLookup);

  const onFind = (text, container) => dispatch(find(text, container));
  const onCapture = (id) => dispatch(capture(id));

  useEffect(() => {
    dispatch(clearSelectedAddress());
  }, [dispatch]);

  const titleCheck =
    locationType === "Driver"
      ? mainHeader.DRIVER
      : locationType === "Customer"
        ? mainHeader.CUSTOMER
        : mainHeader.LOCATION;

  return (
    <div className={className}>
      <HeaderText>{titleCheck}</HeaderText>
      <div
        style={{
          maxHeight: "60vh",
          overflowX: "hidden",
          overflowY: "auto",
          paddingRight: "2rem",
        }}
      >
        <div className="row">
          <InputLabel>{labels.CONTACT}</InputLabel>
          <InputText
            name="contactText"
            value={contact}
            autoComplete="off"
            validValue={true}
            maxLength="50"
            onChange={(e) => onChangeContactName(e)}
          />
        </div>
        <div className="row">
          <InputLabel>{labels.LOCATION}</InputLabel>
          <InputText
            name="locationNameText"
            value={locationName}
            autoComplete="off"
            validValue={true}
            maxLength="50"
            onChange={(e) => onChangeLocationName(e)}
          />
        </div>
        <AddressPicker
          onFind={onFind}
          onCapture={onCapture}
          foundLocations={
            addressLookup.searchResult && addressLookup.searchResult.Items
          }
          selectedLocation={
            addressLookup.selectedAddress && addressLookup.selectedAddress.Items
          }
          hideButtons
          standalonePicker
          address={address}
          setAddress={setAddress}
          setUpdatedAddress={setUpdatedAddress}
          setLocationName={setLocationName}
          locationName={locationName}
          splitCompanyNameFromAddress={true}
          setValidAddress={setValidAddress}
        />
        <div className="row">
          <InputLabel>{labels.PRIMARY_NUM}</InputLabel>
          <InputText
            name="primaryNumText"
            value={primaryNumber}
            autoComplete="off"
            validValue={validPrimaryNumber || primaryNumber === ""}
            maxLength="20"
            onChange={(e) => onChangePrimaryNumber(e)}
          />
        </div>

        <div className="row">
          <InputLabel>{labels.SECONDARY_NUM}</InputLabel>
          <InputText
            name="secondaryNumText"
            value={secondaryNumber}
            autoComplete="off"
            validValue={validSecondaryNumber || secondaryNumber === ""}
            maxLength="20"
            onChange={(e) => onChangeSecondaryNumber(e)}
          />
        </div>

        <div className="row">
          <InputLabel>{labels.EMAIL}</InputLabel>
          <InputText
            name="emailText"
            value={email}
            autoComplete="off"
            validValue={validEmail || email === ""}
            maxLength="50"
            onChange={(e) => onChangeEmail(e)}
          />
        </div>
        { locationType === "Customer" && 
          <div className="notes-row">  
            <InputLabel>{labels.NOTES}</InputLabel>
              <TextArea
                resize={"none"}
                maximumCharactersBottom={200}
                rows="5"
                cols="100"
                value={notes}
                onChange={(e) => onChangeNotes(e)}
                validValue={true}
            />
          </div>
        }
        </div>
    </div>
  );
};

export default LocationEditModal;
