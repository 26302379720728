import {
    FetchVehicleRecordPurchaseInfo,
  } from "../../../api/vendor-vehicle/PurchaseInformation";
  
export const FETCH_VEHICLE_PURCHASE_INFO_DETAILS =
  "@purchaseInfo/FETCH_VEHICLE_PURCHASE_INFO_DETAILS";
export const FETCH_VEHICLE_PURCHASE_INFO_DETAILS_SUCCESS =
  "@purchaseInfo/FETCH_VEHICLE_PURCHASE_INFO_DETAILS_SUCCESS";
export const FETCH_VEHICLE_PURCHASE_INFO_DETAILS_ERROR =
  "@purchaseInfo/FETCH_VEHICLE_PURCHASE_INFO_DETAILS_ERROR";

export const fetchVehiclePurchaseInfo = (vehicleId) => (dispatch, getState) => {
    dispatch({ type: FETCH_VEHICLE_PURCHASE_INFO_DETAILS });
  
    const vehicleDetailsInstance = new FetchVehicleRecordPurchaseInfo({
      params: {
        vehicleId: vehicleId,
      },
      credentials: getState().authentication.credentials,
    });
  
    vehicleDetailsInstance.call().then(
      (response) => {
        dispatch(fetchVehiclePurchaseInfoSuccess(response));
      },
      (err) => {
        dispatch(fetchVehiclePurchaseInfoError(err));
      }
    );
  };
  
  const fetchVehiclePurchaseInfoSuccess = (data) => {
    return {
      type: FETCH_VEHICLE_PURCHASE_INFO_DETAILS_SUCCESS,
      payload: data,
    };
  };
  
  const fetchVehiclePurchaseInfoError = (error) => {
    return {
      type: FETCH_VEHICLE_PURCHASE_INFO_DETAILS_ERROR,
      payload: error,
    };
  };

const initialState = {
    data: null,
    isFetching: false,
    error: null,
};
  
export const reducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_VEHICLE_PURCHASE_INFO_DETAILS:
            return {
                ...state,
                isFetching: true,
            };
        case FETCH_VEHICLE_PURCHASE_INFO_DETAILS_SUCCESS:
            return {
                ...state,
                data: action.payload,
                isFetching: false,
            };
        case FETCH_VEHICLE_PURCHASE_INFO_DETAILS_ERROR:
            return {
                ...state,
                isFetching: false,
                error: action.payload,
            };
    default:
        return state;
    }
};

export default reducer;