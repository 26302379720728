import Api from "../Base";
import settings from "../../clientappsettings.json";
import get from "lodash.get";

class BiddingVendor extends Api {
  baseUrl = settings.VENDORS_URL;
  headers = {
    Authorization: `Bearer ${this.getCredentials()}`,
  };
}

export class FetchVehicleRecordBiddingInfo extends BiddingVendor {
    requestUrl = `/Bidding/BiddingInfo`;
    method = "POST";
    headers = {
      Authorization: `Bearer ${this.getCredentials()}`
    };
  
    formatResponseData(response) {
      return {
        bids: get(response.data, "bids", []),
        acceptedBid: get(response.data, "acceptedBid"),
        salesInfo: get(response.data, "salesInformation"),
        showPriceWhenBidding: response.data.showPriceWhenBidding,
        showReserveWhenBidding: response.data.showReserveWhenBidding,
        allowBidsBelowReserve: response.data.allowBidsBelowReserve,
        allowBuyItNow: response.data.allowBuyItNow,
      };
    }
  }

  export class AcceptBid extends BiddingVendor {
    requestUrl = `/Bidding/AcceptBid`;
    method = "POST";
    headers = {
      Authorization: `Bearer ${this.getCredentials()}`
    };
  }
  
  export class DeclineBid extends BiddingVendor {
    requestUrl = `/Bidding/DeclineBid`;
    method = "POST";
    headers = {
      Authorization: `Bearer ${this.getCredentials()}`
    };
  }