import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import Icon from "./../Icon";
import ParagraphText from "./../ParagraphText";
import Tooltip from "../Tooltip";

const Checkbox = ({
  className,
  qaClassName,
  value,
  icon,
  iconColour,
  count,
  children,
  isChecked,
  isDisabled,
  onInputChange,
  hoverText
}) => {
  const checkboxContent = (
    <div
      className={classnames(className, qaClassName, "checkbox", {
        "checkbox--checked": isChecked,
        "checkbox--disabled": isDisabled
      })}
    >
      <span className="checkbox__input">
        <input
          type="checkbox"
          id={value}
          name={value}
          checked={isChecked}
          disabled={isDisabled}
          onChange={onInputChange}
        />
        <span className="checkbox__indicator" />
      </span>
      {icon && <Icon className="checkbox__icon" {...icon} />}
      <label htmlFor={value}>
        <span className="checkbox__label">{children}</span>
      </label>
      {count ? (
        <ParagraphText className="checkbox__count">({count})</ParagraphText>
      ) : null}
      {iconColour && <Icon className="colourCheck__icon" {...iconColour} />}
    </div>
  );

  return hoverText ? (
    <Tooltip direction="right" content={hoverText} show={!!hoverText}>
      {checkboxContent}
    </Tooltip>
  ) : (
    checkboxContent
  );
};

Checkbox.propTypes = {
  className: PropTypes.string,
  qaClassname: PropTypes.string,
  value: PropTypes.string,
  iconColour: PropTypes.shape({
    type: PropTypes.string,
    strokeColour: PropTypes.string,
    strokeWidth: PropTypes.string,
    width: PropTypes.string,
    height: PropTypes.string
  }),
  icon: PropTypes.shape({
    type: PropTypes.string,
    strokeColour: PropTypes.string,
    strokeWidth: PropTypes.string,
    width: PropTypes.string,
    height: PropTypes.string
  }),
  count: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  children: PropTypes.node,
  isChecked: PropTypes.bool,
  isDisabled: PropTypes.bool,
  onInputChange: PropTypes.func,
  hoverText: PropTypes.string
};

Checkbox.defaultProps = {
  isChecked: false,
  isDisabled: false,
  onInputChange: () => {}
};

export default Checkbox;
