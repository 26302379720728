import React from "react";
import PropTypes from "prop-types";
import ParagraphText from "../../../../views/components/ParagraphText";
import InputRadio from "../../../../views/components/InputRadio";

const CapVehicleLookupTableRow = ({
  className,
  capVehicleLookupData,
  isSelected,
  onSelectRow,
}) => {
  return (
    <tbody>
      <tr className={className}>
        <td className="columnWidths">
          <ParagraphText primary className="returnedValue">
            {capVehicleLookupData.vehicleDescription || "-"}
          </ParagraphText>
        </td>

        <td className="columnWidths">
          <ParagraphText primary className="returnedValue">
            {capVehicleLookupData.introducedDate || "-"}
          </ParagraphText>
        </td>

        <td className="columnWidths">
          <ParagraphText primary className="returnedValue">
            {capVehicleLookupData.discontinuedDate || "-"}
          </ParagraphText>
        </td>

        <td className="columnWidths">
          <InputRadio
            onInputChange={onSelectRow}
            isChecked={isSelected}
          />
        </td>
      </tr>
    </tbody>
  );
};

CapVehicleLookupTableRow.propTypes = {
  className: PropTypes.string,
  capVehicleLookupData: PropTypes.object.isRequired,
};

export default CapVehicleLookupTableRow;
