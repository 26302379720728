import {
    FetchVehicleRecordInspections,
    } from "../../../api/vendor-vehicle/Inspections";
    
export const FETCH_VEHICLE_INSPECTION_DETAILS =
  "@inspections/FETCH_VEHICLE_INSPECTION_DETAILS";
export const FETCH_VEHICLE_INSPECTION_DETAILS_SUCCESS =
  "@inspections/FETCH_VEHICLE_INSPECTION_DETAILS_SUCCESS";
export const FETCH_VEHICLE_INSPECTION_DETAILS_ERROR =
  "@inspections/FETCH_VEHICLE_INSPECTION_DETAILS_ERROR";
export const SET_ACTIVE_INSPECTION_ID =
  "@inspections/SET_ACTIVE_INSPECTION_ID";
export const CLEAR_ACTIVE_INSPECTION_ID =
  "@inspections/CLEAR_ACTIVE_INSPECTION_ID";
  
export const fetchVehicleInspections = (vehicleId) => (dispatch, getState) => {
    dispatch({ type: FETCH_VEHICLE_INSPECTION_DETAILS });
  
    const vehicleDetailsInstance = new FetchVehicleRecordInspections({
      params: {
        vehicleId: vehicleId,
      },
      credentials: getState().authentication.credentials,
    });
  
    vehicleDetailsInstance.call().then(
      (response) => {
        dispatch(fetchVehicleInspectionsSuccess(response));
      },
      (err) => {
        dispatch(fetchVehicleInspectionsError(err));
      }
    );
  };
  
  const fetchVehicleInspectionsSuccess = (data) => {
    return {
      type: FETCH_VEHICLE_INSPECTION_DETAILS_SUCCESS,
      payload: data,
    };
  };
  
  const fetchVehicleInspectionsError = (error) => {
    return {
      type: FETCH_VEHICLE_INSPECTION_DETAILS_ERROR,
      payload: error,
    };
  };
  

export const setActiveInspectionId = (activeInspectionId) => {
    return {
      type: SET_ACTIVE_INSPECTION_ID,
      payload: activeInspectionId,
    };
  };
  
export const clearActiveInspectionId = () => {
    return {
      type: CLEAR_ACTIVE_INSPECTION_ID,
    };
  };
  
  
  const initialState = {
    activeInspectionId: null,
    data: null,
    isFetching: false,
    error: null,
  };
    
export const reducer = (state = initialState, action) => {
    switch (action.type) {
    case FETCH_VEHICLE_INSPECTION_DETAILS:
        return {
            ...state,
            isFetching: true,
        };
    case FETCH_VEHICLE_INSPECTION_DETAILS_SUCCESS:
        return {
            ...state,
            data: action.payload,
            isFetching: false,
        };
    case FETCH_VEHICLE_INSPECTION_DETAILS_ERROR:
        return {
            ...state,
            isFetching: false,
            error: action.payload,
        };
    case SET_ACTIVE_INSPECTION_ID:
        return {
            ...state,
            activeInspectionId: action.payload,
        };
    default:
        return state;
    }
};
  
  export default reducer;