import React, { Fragment, useState, useEffect, useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { closeModal } from "../../../state/reducers/global";
import ModalInsert from "../../../views/components/ModalInsert";
import Panel from "../../../views/components/Panel";
import VehicleLookupModal from "../../components/VehicleLookupModal";
import {
  clearVehicleLookupData,
  fetchCapModels,
  fetchVehicleLookup,
} from "./../../../state/reducers/cap";
import { updateFromVehicleLookup } from "../../../state/reducers/vendor-vehicle/vehicleDetails";

const VendorVehicleLookupModalContainer = () => {
  const dispatch = useDispatch();

  const [capCode, setCapCode] = useState("");
  const [capId, setCapId] = useState(null);
  const [description, setDescription] = useState("");
  const [selectedMakes, setSelectedMakes] = useState(null);
  const [selectedModels, setSelectedModels] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null);
  const [numberOfResults, setNumberOfResults] = useState("");

  const selectedMakeKey = selectedMakes ? selectedMakes.key : null;
  const selectedModelKey = selectedModels ? selectedModels.key : null;

  const capVehicleData = useSelector((state) => state.cap?.vehicleLookupData);
  const capMake = useSelector((state) => state.cap.capMakes);
  const capModel = useSelector(
    (state) => state.cap.capModels[`${selectedMakeKey}`]
  );

  const isFetchingCapMake = useSelector(
    (state) => state.cap.isFetchingCapMakes
  );

  const isFetchingCapModel = useSelector(
    (state) => state.cap.isFetchingCapModels
  );

  const isFetchingVehicleLookupData = useSelector(
    (state) => state.cap.isFetchingVehicleLookupData
  );

  const onCancel = () => {
    dispatch(closeModal());
  };

  const onUpdate = () => {
    dispatch(updateFromVehicleLookup(selectedVehicleData));
    dispatch(closeModal());
  };
  
  const vehicleSelectionData = useMemo(() => ({
    chosenMakeId: selectedMakeKey,
    chosenMake: selectedMakes?.value,
    chosenModelId: selectedModelKey,
    chosenModel: selectedModels?.value,
    description: description,
    capId: capId,
    capCode: capCode,
  }), [selectedMakeKey, selectedMakes, selectedModelKey, selectedModels, description, capId, capCode]);

  const onFormSubmit = useCallback(() => {
    dispatch(fetchVehicleLookup(vehicleSelectionData));
  }, [dispatch, vehicleSelectionData]);

  const onFormClear = () => {
    setCapCode("");
    setCapId(null);
    setDescription("");
    setSelectedMakes(null);
    setSelectedModels(null);
    setSelectedRow(null);
    setNumberOfResults("");
    dispatch(clearVehicleLookupData());
  };

  const onChangeCapCode = (value) => {
    setCapCode(value);
  };

  const onChangeCapId = (value) => {
    setCapId(value);
  };

  const onChangeDescription = (value) => {
    setDescription(value);
  };

  const handleRowSelection = (rowData) => {
    setSelectedRow(rowData);
  };

  const selectedVehicleData = {
    capId: selectedRow?.capId,
    capCode: selectedRow?.capCode,
    make: selectedRow?.capMake,
    model: selectedRow?.capModel,
    derivative: selectedRow?.capDerivative,
    bodyStyle: selectedRow?.bodyStyleDescription,
    transmissionType: selectedRow?.transmissionTypeDescription,
    fuelType: selectedRow?.fuelTypeDescription,
  };

  const disableConfirm =
    (!selectedMakes || !selectedModels) &&
    !capId &&
    !capCode.trim() &&
    !description.trim();

  const disableClear =
    !selectedMakes && !selectedModels && !capId && !capCode && !description;

  const disableUpdate = selectedRow === null;

  useEffect(() => {
    if (selectedMakes) {
      dispatch(fetchCapModels(selectedMakes.key));
    }
    setSelectedModels(null);
  }, [selectedMakes, dispatch]);

  useEffect(() => {
    setNumberOfResults(capVehicleData?.length);
  }, [capVehicleData]);

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "Enter" && !disableConfirm) {
        onFormSubmit();
      }
    };
  
    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [onFormSubmit, disableConfirm]);
  
  const capModelOptions =
    capModel?.map((option) => ({
      key: option.key,
      label: option.value,
      value: option.value,
    })) || [];

  const capMakeOptions = capMake?.map((option) => ({
    key: option.key,
    label: option.value,
    value: option.value,
  }));

  return (
    <Fragment>
      <ModalInsert>
        <Panel
          style={{ height: "100%", borderRadius: "0.5rem" }}
          actions={[
            {
              content: "Close",
              action: onCancel,
              type: "ghost",
            },
            {
              content: "Update",
              type: "primary",
              action: onUpdate,
              disabled: disableUpdate,
            },
          ]}
        >
          <VehicleLookupModal
            capId={capId}
            capCode={capCode}
            description={description}
            onChangeCapCode={onChangeCapCode}
            onChangeCapId={onChangeCapId}
            onChangeDescription={onChangeDescription}
            onFormSubmit={onFormSubmit}
            onFormClear={onFormClear}
            selectedMakes={selectedMakes}
            setSelectedMakes={setSelectedMakes}
            selectedModels={selectedModels}
            setSelectedModels={setSelectedModels}
            isFetchingCapMake={isFetchingCapMake}
            isFetchingCapModel={isFetchingCapModel}
            isFetchingVehicleLookupData={isFetchingVehicleLookupData}
            capMakeOptions={capMakeOptions}
            capModelOptions={capModelOptions}
            disableConfirm={disableConfirm}
            disableClear={disableClear}
            capVehicleData={capVehicleData}
            handleRowSelection={handleRowSelection}
            selectedRow={selectedRow}
            numberOfResults={numberOfResults}
          />
        </Panel>
      </ModalInsert>
    </Fragment>
  );
};

export default VendorVehicleLookupModalContainer;
