import React from "react";
import PropTypes from "prop-types";
import Panel from "../../Panel";
import ModalInsert from "../../ModalInsert";

const SkeletonVendorImagesModal = (props) => {
  return (
    <div className={props.className}>
      <ModalInsert width="90rem">
        <Panel>
          <div style={{ display: "grid" }}>
            <div className="skeleton-image-container">
              {[...Array(1)].map((item, index) => (
                <span key={`skeleton-image-container-${index}`}>
                  <span className="skeleton-image" />
                  <span className="skeleton-image" />
                  <span className="skeleton-image" />
                  <span className="skeleton-image" />
                </span>
              ))}
            </div>
          </div>
        </Panel>
        <div className="modal-bottom">
          <Panel
            style={{ height: "100%", borderRadius: "0.5rem" }}
            padding="REGULAR"
            actions={[
              {
                content: "Close",
                action: () => {},
                type: "ghost",
              },
            ]}
          />
        </div>
      </ModalInsert>
    </div>
  );
};

SkeletonVendorImagesModal.propTypes = {
  className: PropTypes.string,
};

export default SkeletonVendorImagesModal;
