const PRIMARY = {
  base: "#004666",
  shades: {
    5: "#F4F5F7",
    10: "#e6edf0",
    20: "#ccdae0",
    30: "#b3c8d1",
    40: "#99b5c2",
    50: "#80a3b3",
    60: "#6690a3",
    70: "#4d7e94",
    80: "#336b85",
    90: "#1a5975",
  },
};

const ACCENT = {
  base: "#217b99",
  shades: {
    10: "#e9f2f5",
    20: "#d3e5eb",
    30: "#bcd7e0",
    40: "#a6cad6",
    50: "#90bdcc",
    60: "#7ab0c2",
    70: "#64a3b8",
    80: "#4d95ad",
    90: "#3788a3",
  },
};

const SECONDARY = {
  base: "#003147",
  shades: {
    5: "#F4F5F7",
    10: "#e6eaed",
    20: "#ccd6da",
    30: "#b3c1c8",
    40: "#99adb5",
    50: "#8098a3",
    60: "#668391",
    70: "#4d6f7e",
    80: "#335a6c",
    90: "#1a4659",
  },
};

const SECONDARY_ACCENT = {
  base: "#0d313d",
  shades: {
    5: "#F4F5F7",
    10: "#e7eaec",
    20: "#cfd6d8",
    30: "#b6c1c5",
    40: "#9eadb1",
    50: "#86989e",
    60: "#6e838b",
    70: "#566f77",
    80: "#3d5a64",
    90: "#254650",
  },
};

const GREEN = {
  base: "#429D16",
  shades: {
    10: "#ECF5E7",
    20: "#D9EBD0",
    30: "#C6E1B9",
    40: "#B2D8A2",
    50: "#A0CE8A",
    60: "#8EC473",
    70: "#7ABA5B",
    80: "#68B145",
    90: "#54A62D",
  },
};

const RED = {
  base: "#F22B2B",
  shades: {
    10: "#FDE9E9",
    20: "#FCD5D5",
    30: "#FBBFBF",
    40: "#FAAAAA",
    50: "#F89595",
    60: "#F78080",
    70: "#F56A6A",
    80: "#F55555",
    90: "#F34040",
  },
};

const ORANGE = {
  base: "#FE7214",
  shades: {
    10: "#FEF0E7",
    20: "#FFE3D0",
    30: "#FED4B8",
    40: "#FFC7A1",
    50: "#FEB889",
    60: "#FEAA72",
    70: "#FE9C5A",
    80: "#FE8E43",
    90: "#FE802B",
  },
};

const TRANSPARENCIES = {
  backgroundImage: "rgba(0,0,0,0.7)",
  primary: "rgba(51,48,46,0.3)",
  primaryDark: "rgba(51,48,46,0.9)",
  green: "rgba(66,157,22,0.05)",
  white: "rgba(27, 34, 59, 0.1)",
  semiWhite: "rgba(255, 255, 255, 0.125)",
};

const COLOURS = {
  PRIMARY,
  SECONDARY,
  ACCENT,
  SECONDARY_ACCENT,
  RED,
  GREEN,
  ORANGE,
  BLACK: "#000000",
  BLUE: "#2183CD",
  BROWN: "#964B00",
  WHITE: "#FFFFFF",
  YELLOW: "#feb40c",
  SILVER: "#C0C0C0",
  GREY: "#808080",
  CREAM: "#FFFDD0",
  GOLD: "#FFD700",
  PINK: "#FFC0CB",
  PURPLE: "#A020F0",
  BEIGE: "#F5F5DC",
  BRONZE: "#CD7F32",
  MAROON: "#800000",
  TURQUOISE: "#40e0d0",
  ACTION_GREEN: "#4D9152",
  ALERT_RED: "#C33939",
  TRANSPARENCIES,
};

export default COLOURS;
