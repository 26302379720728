import { combineReducers } from "redux";
import global from "./global";
import authentication from "./authentication";
import search from "./search";
import filter from "./filter";
import vehicle from "./vehicle";
import purchase from "./purchase";
import pricing from "./pricing";
import orders from "./orders";
import bid from "./bid";
import cap from "./cap";
import messages from "./messages";
import termsAndConditions from "./termsAndConditions";
import bidsAndRequests from "./bidsAndRequests";
import purchasesArchive from "./purchasesArchive";
import shortlist from "./shortlist";
import stocklist from "./stocklist";
import salesBrochure from "./salesBrochure";
import toolbar from "./toolbar";
import addressLookup from "./addressLookup";
import regSearch from "./regSearch";
import pagination from "./pagination";
import invoices from "./invoices";
import activityLogs from "./activityLogs";
import buyerInformation from "./buyerInformation";
import forgottenCredentials from "./forgottenCredentials";
import passwordChange from "./passwordChange";
import vehicleNote from "./vehicleNote";
import helpCentre from "./helpCentre";
import funding from "./funding";
import tariff from "./tariff";
import inspection from "./inspection";
import claims from "./claims";
import claimsVendor from "./claimsVendor";
import claimsVendorDashboard from "./claimsVendorDashboard";
import claimDetailsVendor from "./claimDetailsVendor";
import claimActivityLog from "./claimActivityLog";
import showroom from "./showroom";
import campaignManagement from "./campaignManagement";
import notificationPreference from "./notificationPreference";
import vendorVehicleRecord from "./vendorVehicleRecord";
import vendorInformation from "./vendorInformation";
import auction from "./auction";
import actions from "./actions";

export default combineReducers({
  authentication,
  global,
  filter,
  regSearch,
  toolbar,
  search,
  vehicle,
  showroom,
  purchase,
  pricing,
  orders,
  bid,
  cap,
  messages,
  termsAndConditions,
  bidsAndRequests,
  purchasesArchive,
  shortlist,
  stocklist,
  salesBrochure,
  addressLookup,
  pagination,
  invoices,
  activityLogs,
  buyerInformation,
  forgottenCredentials,
  passwordChange,
  vehicleNote,
  helpCentre,
  funding,
  tariff,
  inspection,
  claims,
  claimsVendor,
  claimsVendorDashboard,
  claimDetailsVendor,
  claimActivityLog,
  campaignManagement,
  notificationPreference,
  vendorVehicleRecord,
  vendorInformation,
  auction,
  actions
});
