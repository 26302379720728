import {
    FetchVehicleRecordProfiles,
  } from "../../../api/vendor-vehicle/Profiling";
  
export const FETCH_VEHICLE_RECORD_PROFILES =
  "@profiles/FETCH_VEHICLE_RECORD_PROFILES";
export const FETCH_VEHICLE_RECORD_PROFILES_SUCCESS =
  "@profiles/FETCH_VEHICLE_RECORD_PROFILES_SUCCESS";
export const FETCH_VEHICLE_RECORD_PROFILES_ERROR =
  "@profiles/FETCH_VEHICLE_RECORD_PROFILES_ERROR";
  
export const fetchVehicleRecordProfiles =
    (vehicleId) => (dispatch, getState) => {
      dispatch({ type: FETCH_VEHICLE_RECORD_PROFILES });
  
      const vehicleDetailsInstance = new FetchVehicleRecordProfiles({
        params: {
          vehicleId: vehicleId,
        },
        credentials: getState().authentication.credentials,
      });
  
      vehicleDetailsInstance.call().then(
        (response) => {
          dispatch(fetchVehicleRecordProfilesSuccess(response));
        },
        (err) => {
          dispatch(fetchVehicleRecordProfilesError(err));
        }
      );
    };
  
  const fetchVehicleRecordProfilesSuccess = (data) => {
    return {
      type: FETCH_VEHICLE_RECORD_PROFILES_SUCCESS,
      payload: data,
    };
  };
  
  const fetchVehicleRecordProfilesError = (error) => {
    return {
      type: FETCH_VEHICLE_RECORD_PROFILES_ERROR,
      payload: error,
    };
  };

const initialState = {
    profiles: null,
    daysOnSale: null,
    buyNowPrice: null,
    isFetching: false,
    error: null,
};
  
export const reducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_VEHICLE_RECORD_PROFILES:
            return {
                ...state,
                isFetching: true,
            };
        case FETCH_VEHICLE_RECORD_PROFILES_SUCCESS:
            return {
                ...state,
                profiles: action.payload?.profiles,
                daysOnSale: action.payload?.daysOnSale,
                buyNowPrice: action.payload?.buyNowPrice,
                isFetching: false,
            };
        case FETCH_VEHICLE_RECORD_PROFILES_ERROR:
            return {
                ...state,
                isFetching: false,
                error: action.payload,
            };
    default:
        return state;
    }
};

export default reducer;