import React, { useEffect, useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  addVehicleHold,
  modifyVehicleHold,
  removeVehicleHold,
} from "../../../state/reducers/vendor-vehicle/vehicleHold";
import { closeModal } from "../../../state/reducers/global";
import { setActiveConfirmModalOptions } from "../../../state/reducers/vendor-vehicle/genericActiveState";
import VendorVehicleRecordHoldModal from "../../components/VendorVehicleRecordHoldModal";

const useCheckboxes = (vendorHoldReasons, vehicleHoldReasons) => {
  const [checkboxes, setCheckboxes] = useState({});

  useEffect(() => {
    const initialiseCheckboxes = () => {
      const vendorHoldReasonsList = vendorHoldReasons.reduce((acc, reason) => {
        acc[reason.holdReason] = false;
        return acc;
      }, {});

      const vehicleHoldReasonsList = Object.keys(vehicleHoldReasons).reduce(
        (acc, key) => {
          acc[vehicleHoldReasons[key]] = false;
          return acc;
        },
        {}
      );

      const updatedCheckboxes = { ...vendorHoldReasonsList };
      Object.keys(vehicleHoldReasonsList).forEach((reason) => {
        if (vendorHoldReasonsList.hasOwnProperty(reason)) {
          updatedCheckboxes[reason] = true;
        }
      });

      setCheckboxes(updatedCheckboxes);
    };

    initialiseCheckboxes();
  }, [vendorHoldReasons, vehicleHoldReasons]);

  const checkBoxHandler = (checkbox) => {
    setCheckboxes((prevCheckboxes) => ({
      ...prevCheckboxes,
      [checkbox]: !prevCheckboxes[checkbox],
    }));
  };

  return [checkboxes, checkBoxHandler];
};

const VendorVehicleRecordHoldModalContainer = ({ className }) => {
  const dispatch = useDispatch();
  const [isActionProcessing, setIsActionProcessing] = useState(false);
  const vehicleReference = useSelector(
    (state) =>
      state.vendorVehicleRecord.genericActiveState.activeVehicleReference
  );
  const holdType = useSelector(
    (state) =>
      state.vendorVehicleRecord.genericActiveState.activeConfirmModalOptions
        .type
  );
  const { vendorHoldDetails, vehicleHoldDetails } = useSelector(
    (state) => state.vendorVehicleRecord.vehicleHold || {}
  );
  const isFetchingVendorHoldDetails = useSelector(
    (state) =>
      state.vendorVehicleRecord.vehicleHold.vendorHoldDetails.isFetching
  );
  const isFetchingVehicleHoldDetails = useSelector(
    (state) =>
      state.vendorVehicleRecord.vehicleHold.vehicleHoldDetails.isFetching
  );

  const vendorHoldReasons = useMemo(
    () => vendorHoldDetails?.data?.data || [],
    [vendorHoldDetails]
  );
  const vehicleHoldReasons = useMemo(
    () => vehicleHoldDetails?.data?.data?.holdReasons || {},
    [vehicleHoldDetails]
  );
  const vehicleAdditionalHoldReason = useMemo(
    () => vehicleHoldDetails?.data?.data?.additionalHoldReason || "",
    [vehicleHoldDetails]
  );
  const initialVehicleHoldReasons = Object.values(vehicleHoldReasons);

  const [additionalHoldReason, setAdditionalHoldReason] = useState("");
  const [hasAdditionalHoldReasonChanged, setHasAdditionalHoldReasonChanged] =
    useState(false);

  const [checkboxes, checkBoxHandler] = useCheckboxes(
    vendorHoldReasons,
    vehicleHoldReasons
  );

  const handleAdditionalHoldReasonChange = (e) => {
    const value = e.target.value;
    setAdditionalHoldReason(
      value.length <= 100 ? value : value.substring(0, 100)
    );
    setHasAdditionalHoldReasonChanged(true);
    if (value === "") {
      setAdditionalHoldReason("");
      setHasAdditionalHoldReasonChanged(true);
    }
  };

  const onCancel = () => {
    dispatch(closeModal());
  };

  const onConfirmHandle = () => {
    const getHoldReasonCodes = (filterFn) =>
      Object.keys(checkboxes)
        .filter(filterFn)
        .map(
          (reason) =>
            vendorHoldReasons.find((r) => r.holdReason === reason)
              ?.holdReasonCode
        )
        .filter(Boolean);

    const selectedHoldReasons = getHoldReasonCodes(
      (checkbox) => checkboxes[checkbox]
    );
    const newlySelectedHoldReasons = getHoldReasonCodes(
      (checkbox) =>
        checkboxes[checkbox] && !initialVehicleHoldReasons.includes(checkbox)
    );
    const unselectedHoldReasons = getHoldReasonCodes(
      (checkbox) =>
        !checkboxes[checkbox] && initialVehicleHoldReasons.includes(checkbox)
    );

    const additionalHoldReasonChangedToEmpty =
      additionalHoldReason.trim() === "" &&
      (vehicleAdditionalHoldReason?.trim() || "") !== "";

    if (
      unselectedHoldReasons.length > 0 ||
      (hasAdditionalHoldReasonChanged && additionalHoldReasonChangedToEmpty)
    ) {
      dispatch(
        removeVehicleHold(
          vehicleReference,
          false,
          unselectedHoldReasons,
          hasAdditionalHoldReasonChanged && additionalHoldReasonChangedToEmpty
        )
      );
    }
    if (
      holdType === "hold" &&
      (newlySelectedHoldReasons.length > 0 ||
        (hasAdditionalHoldReasonChanged && !additionalHoldReasonChangedToEmpty))
    ) {
      const type = "hold";
      const data = {
        vehicleReference,
        action: type,
      };
      dispatch(setActiveConfirmModalOptions(type, data));
      dispatch(
        addVehicleHold(
          vehicleReference,
          selectedHoldReasons,
          additionalHoldReason
        )
      );
    }
    if (
      holdType === "modify-hold" &&
      (newlySelectedHoldReasons.length > 0 ||
        (hasAdditionalHoldReasonChanged && !additionalHoldReasonChangedToEmpty))
    ) {
      const type = "modify-hold";
      const data = {
        vehicleReference,
        action: type,
      };
      dispatch(setActiveConfirmModalOptions(type, data));
      dispatch(
        modifyVehicleHold(
          vehicleReference,
          selectedHoldReasons,
          additionalHoldReason
        )
      );
    }
    setIsActionProcessing(true);
  };

  const checkboxOrTextareaEmpty = () => {
    return (
      checkboxes &&
      Object.values(checkboxes).every((isChecked) => !isChecked) &&
      additionalHoldReason.trim() === "" &&
      vehicleAdditionalHoldReason === ""
    );
  };

  return (
    <VendorVehicleRecordHoldModal
      className={className}
      isActionProcessing={isActionProcessing}
      checkboxes={checkboxes}
      hasCheckboxes={Object.keys(checkboxes).length > 0}
      checkBoxHandler={checkBoxHandler}
      additionalHoldReason={additionalHoldReason}
      vehicleAdditionalHoldReason={vehicleAdditionalHoldReason}
      handleAdditionalHoldReasonChange={handleAdditionalHoldReasonChange}
      onCancel={onCancel}
      onConfirmHandle={onConfirmHandle}
      checkboxOrTextareaEmpty={checkboxOrTextareaEmpty}
      isFetchingVendorHoldDetails={isFetchingVendorHoldDetails}
      isFetchingVehicleHoldDetails={isFetchingVehicleHoldDetails}
      holdType={holdType}
    />
  );
};
export default VendorVehicleRecordHoldModalContainer;
