import {
  FetchVehicleRecordSalesInfo,
  UpdateSalesInformation
} from "../../../api/vendor-vehicle/SalesInformation";
import { openModal, closeModal } from "../global";
import { clearActiveConfirmModalOptions } from "./genericActiveState";
import { convertToNumber } from "../../../shared/utility";

export const FETCH_VEHICLE_SALES_INFO_DETAILS =
  "@salesInfo/FETCH_VEHICLE_SALES_INFO_DETAILS";
export const FETCH_VEHICLE_SALES_INFO_DETAILS_SUCCESS =
  "@salesInfo/FETCH_VEHICLE_SALES_INFO_DETAILS_SUCCESS";
export const FETCH_VEHICLE_SALES_INFO_DETAILS_ERROR =
  "@salesInfo/FETCH_VEHICLE_SALES_INFO_DETAILS_ERROR";
export const UPDATE_SALES_INFORMATION =
  "@salesInfo/UPDATE_SALES_INFORMATION";
export const UPDATE_SALES_INFORMATION_SUCCESS =
  "@salesInfo/UPDATE_SALES_INFORMATION_SUCCESS";
export const UPDATE_SALES_INFORMATION_ERROR =
  "@salesInfo/UPDATE_SALES_INFORMATION_ERROR";

export const fetchVehicleSalesInfo = (vehicleId) => (dispatch, getState) => {
  dispatch({ type: FETCH_VEHICLE_SALES_INFO_DETAILS });

  const vehicleDetailsInstance = new FetchVehicleRecordSalesInfo({
    params: {
      vehicleId: vehicleId,
    },
    credentials: getState().authentication.credentials,
  });

  vehicleDetailsInstance.call().then(
    (response) => {
      dispatch(fetchVehicleSalesInfoSuccess(response));
    },
    (err) => {
      dispatch(fetchVehicleSalesInfoError(err));
    }
  );
};

const fetchVehicleSalesInfoSuccess = (data) => {
  return {
    type: FETCH_VEHICLE_SALES_INFO_DETAILS_SUCCESS,
    payload: data,
  };
};

const fetchVehicleSalesInfoError = (error) => {
  return {
    type: FETCH_VEHICLE_SALES_INFO_DETAILS_ERROR,
    payload: error,
  };
};

const initialState = {
  data: null,
  isFetching: false,
  error: null,
};


export const updateSalesInformation =
  (vehicleId, salesInformationRequest, salesText, salesTextAction) => (dispatch, getState) => {
    dispatch({ type: UPDATE_SALES_INFORMATION });
    dispatch(openModal("generic-loader-modal"));

    const parseCurrency = (value) => {
      if (typeof value === 'string') {
        return parseFloat(value.replace(/[£,]/g, '')) || 0;
      }
      return null;
    };

    const additionalInfo = {
      thirdPartyValuation: getState().vendorVehicleRecord.salesInformation.data.salesInfo.thirdPartyValuation !== parseCurrency(salesInformationRequest.editThirdPartyValuation) ? parseCurrency(salesInformationRequest.editThirdPartyValuation) : null,
      saleCategory: getState().vendorVehicleRecord.salesInformation.data.salesInfo.saleCategory !== salesInformationRequest.editSalesCategory ? salesInformationRequest.editSalesCategory : null,
      vatQualified: getState().vendorVehicleRecord.salesInformation.data.salesInfo.vatStatus !== salesInformationRequest.editVatStatus ? salesInformationRequest.editVatStatus : null,
      salesAccountId: getState().vendorVehicleRecord.salesInformation.data.salesInfo.salesAccountId !== salesInformationRequest.editSalesAccountId ? convertToNumber(salesInformationRequest.editSalesAccountId) : null,
    };

    const params = {
      vehicleId: vehicleId,
      buyNow: getState().vendorVehicleRecord.salesInformation.data.salesInfo.buyNowPrice !== parseCurrency(salesInformationRequest.editBuyNowPrice) ? parseCurrency(salesInformationRequest.editBuyNowPrice) : null,
      bidStartAt: getState().vendorVehicleRecord.salesInformation.data.salesInfo.bidStartAtPrice !== parseCurrency(salesInformationRequest.editBidStartAtPrice) ? parseCurrency(salesInformationRequest.editBidStartAtPrice) : null,
      onlineReserve: getState().vendorVehicleRecord.salesInformation.data.salesInfo.onlineReservePrice !== parseCurrency(salesInformationRequest.editOnlineReservePrice) ? parseCurrency(salesInformationRequest.editOnlineReservePrice) : null,
      auctionReserve: getState().vendorVehicleRecord.salesInformation.data.salesInfo.auctionReservePrice !== parseCurrency(salesInformationRequest.editAuctionReservePrice) ? parseCurrency(salesInformationRequest.editAuctionReservePrice) : null,
      capValuation: getState().vendorVehicleRecord.salesInformation.data.salesInfo.currentValuation !== parseCurrency(salesInformationRequest.editCapValuation) ? parseCurrency(salesInformationRequest.editCapValuation) : null,
      residualValue: getState().vendorVehicleRecord.salesInformation.data.salesInfo.residualValue !== parseCurrency(salesInformationRequest.editResidualValue) ? parseCurrency(salesInformationRequest.editResidualValue) : null,
      damageTotal: getState().vendorVehicleRecord.salesInformation.data.salesInfo.vehicleDamageTotal !== parseCurrency(salesInformationRequest.editVehicleDamageTotal) ? parseCurrency(salesInformationRequest.editVehicleDamageTotal) : null,
      retailPrice: getState().vendorVehicleRecord.salesInformation.data.salesInfo.retailPrice !== parseCurrency(salesInformationRequest.editRetailPrice)
        ? (parseCurrency(salesInformationRequest.editRetailPrice) !== 0 ? parseCurrency(salesInformationRequest.editRetailPrice) : null)
        : null,

      salesText: getState().vendorVehicleRecord.salesInformation.data.salesInfo.salesText !== salesText ? salesText : null,
      salesTextAction: salesTextAction !== 0 ? salesTextAction : null,

      additionalInfo: additionalInfo,
    };

    const vehicleDetailsInstance = new UpdateSalesInformation({
      params: params,
      credentials: getState().authentication.credentials,
    });

    vehicleDetailsInstance.call().then(
      (response) => {
        dispatch(updateSalesInformationSuccess(response));
        dispatch(openModal("vendor-vehicle-record-success-modal"));
        setTimeout(() => {
          dispatch(closeModal());
          dispatch(clearActiveConfirmModalOptions());
        }, 1000);
        dispatch(fetchVehicleSalesInfo(vehicleId))
      },
      (err) => {
        dispatch(updateSalesInformationError(err));
        dispatch(clearActiveConfirmModalOptions());
        dispatch(openModal("generic-error"));
      }
    );
  };

const updateSalesInformationSuccess = (data) => {
  return {
    type: UPDATE_SALES_INFORMATION_SUCCESS,
    payload: data,
  };
};

const updateSalesInformationError = (error) => {
  return {
    type: UPDATE_SALES_INFORMATION_ERROR,
    payload: error,
  };
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_VEHICLE_SALES_INFO_DETAILS:
      return {
        ...state,
        isFetching: true,
      };
    case FETCH_VEHICLE_SALES_INFO_DETAILS_SUCCESS:
      return {
        ...state,
        data: action.payload,
        isFetching: false,
      };
    case FETCH_VEHICLE_SALES_INFO_DETAILS_ERROR:
      return {
        ...state,
        isFetching: false,
        error: action.payload,
      };
    case UPDATE_SALES_INFORMATION:
      return {
        ...state,
        salesInformation: {
          ...state.salesInformation,
          isFetching: true,
          error: null,
        },
      };

    case UPDATE_SALES_INFORMATION_SUCCESS:
      return {
        ...state,
        salesInformation: {
          ...state.salesInformation,
          isFetching: false,
          error: null,
        },
      };

    case UPDATE_SALES_INFORMATION_ERROR:
      return {
        ...state,
        salesInformation: {
          ...state.salesInformation,
          isFetching: false,
          error: action.payload,
        },
      };
    default:
      return state;
  }
};

export default reducer;

