import React from "react";
import Loader from "../Loader";

const LoadingTransition = () => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
        width: "100%",
        justifyContent: "center",
        alignItems: "center",
        background: "#27345F",
      }}
    >
      <Loader invert fullScreen title="Loading..." />
    </div>
  );
};

export default LoadingTransition;
