const PRIMARY = {
  base: "#33302e",
  shades: {
    5: "#F1F1F1",
    10: "#EAEAEA",
    20: "#D6D6D5",
    30: "#C1C0C0",
    40: "#ADACAB",
    50: "#999796",
    60: "#858382",
    70: "#706E6C",
    80: "#5C5958",
    90: "#474442",
  },
};

const ACCENT = {
  base: "#4ba82e",
  shades: {
    10: "#EDF6EA",
    20: "#DBEED5",
    30: "#C9E4C0",
    40: "#B7DCAB",
    50: "#A5D396",
    60: "#93CB82",
    70: "#81C26C",
    80: "#6FB958",
    90: "#5DB042",
  },
};

const SECONDARY = {
  base: "#292625",
  shades: {
    5: "#F1F1F1",
    10: "#eae9e9",
    20: "#d4d4d3",
    30: "#bfbebe",
    40: "#a9a8a8",
    50: "#949392",
    60: "#7f7d7c",
    70: "#696766",
    80: "#545151",
    90: "#3e3c3b",
  },
};

const SECONDARY_ACCENT = {
  base: "#357620",
  shades: {
    10: "#ebf1e9",
    20: "#d7e4d2",
    30: "#c2d6bc",
    40: "#aec8a6",
    50: "#9abb90",
    60: "#86ad79",
    70: "#729f63",
    80: "#5d914d",
    90: "#498436",
  },
};

const GREEN = {
  base: "#429D16",
  shades: {
    10: "#ECF5E7",
    20: "#D9EBD0",
    30: "#C6E1B9",
    40: "#B2D8A2",
    50: "#A0CE8A",
    60: "#8EC473",
    70: "#7ABA5B",
    80: "#68B145",
    90: "#54A62D",
  },
};

const RED = {
  base: "#F22B2B",
  shades: {
    10: "#FDE9E9",
    20: "#FCD5D5",
    30: "#FBBFBF",
    40: "#FAAAAA",
    50: "#F89595",
    60: "#F78080",
    70: "#F56A6A",
    80: "#F55555",
    90: "#F34040",
  },
};

const ORANGE = {
  base: "#FE7214",
  shades: {
    10: "#FEF0E7",
    20: "#FFE3D0",
    30: "#FED4B8",
    40: "#FFC7A1",
    50: "#FEB889",
    60: "#FEAA72",
    70: "#FE9C5A",
    80: "#FE8E43",
    90: "#FE802B",
  },
};

const TRANSPARENCIES = {
  backgroundImage: "rgba(51,48,46,0.7)",
  primary: "rgba(51,48,46,0.3)",
  primaryDark: "rgba(51,48,46,0.9)",
  green: "rgba(66,157,22,0.05)",
  white: "rgba(27, 34, 59, 0.1)",
  semiWhite: "rgba(255, 255, 255, 0.125)",
};

const COLOURS = {
  PRIMARY,
  SECONDARY,
  ACCENT,
  SECONDARY_ACCENT,
  RED,
  GREEN,
  ORANGE,
  BLACK: "#000000",
  BLUE: "#2183CD",
  BROWN: "#964B00",
  WHITE: "#FFFFFF",
  YELLOW: "#feb40c",
  SILVER: "#C0C0C0",
  GREY: "#808080",
  CREAM: "#FFFDD0",
  GOLD: "#FFD700",
  PINK: "#FFC0CB",
  PURPLE: "#A020F0",
  BEIGE: "#F5F5DC",
  BRONZE: "#CD7F32",
  MAROON: "#800000",
  TURQUOISE: "#40e0d0",
  TRANSPARENCIES,
  ACTION_GREEN: "#4D9152",
  ALERT_RED: "#C33939",
};

export default COLOURS;
