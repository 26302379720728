import React, { useState, useRef, useEffect } from "react";
import PropTypes from "prop-types";
import { DayPicker } from "react-day-picker";
import "react-day-picker/dist/style.css";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import Overlay from "./child-components/Overlay";
import ParagraphText from "../ParagraphText";
import DatePickerInput from "./child-components/DatePickerInput";
import { addYears } from "date-fns";

dayjs.extend(customParseFormat);

const Datepicker = (props) => {
  const {
    className,
    label,
    dateFormat,
    defaultDate,
    getDate,
    endOfTheDay,
    disabled,
    placeholder,
    setValidValue = () => {},
    noDateInPast,
  } = props;

  const formatDate = (date, format) => {
    if (endOfTheDay) return dayjs(date).endOf("day").format(format);
    return dayjs(date).startOf("day").format(format);
  };

  const displayDateFormat = "DD/MM/YYYY";

  const [value, setValue] = useState(
    defaultDate ? formatDate(defaultDate, displayDateFormat) : ""
  );

  const [calendarOpen, setCalendarOpen] = useState(false);
  const [preformattedDate, setPreformattedDate] = useState(null);

  const inputRef = useRef();

  const parseDate = (str, format) => {
    const parsed = dayjs(str, format).toDate();
    return parsed;
  };

  const handleInputClick = () => {
    setCalendarOpen(true);
  };

  const handleDayClick = (date) => {
    setPreformattedDate(date);
    getDate(formatDate(date, dateFormat));
    setValue(formatDate(date, displayDateFormat));
    setCalendarOpen(false);
  };

  const handleOutsideClick = (event) => {
    if (!event.target.closest(".rdp")) {
      setCalendarOpen(false);
    }
  };

  // Use useEffect to update the date when defaultDate changes
  useEffect(() => {
    if (defaultDate) {
      setValue(formatDate(defaultDate, displayDateFormat));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultDate]);

  const handleInputChange = (e) => {
    const inputValue = e.target.value;
    setValue(inputValue);
    const formats = [
      "D.M.YY",
      "D/M/YY",
      "D M YY",
      "D.M.YYYY",
      "D/M/YYYY",
      "D M YYYY",
      "DD.MM.YY",
      "DD/MM/YY",
      "DD MM YY",
      "DD.MM.YYYY",
      "DD/MM/YYYY",
      "DD MM YYYY",
      "YYYY-MM-DD",
      "YYYY/MM/DD",
      "YYYY MM DD",
      "YY-MM-DD",
      "YY/MM/DD",
      "YY MM DD",
    ];

    const specialDates = {};
    for (let i = 1; i <= 1000; i++) {
      specialDates[`+${i}`] = dayjs().add(i, "day");
      specialDates[`-${i}`] = dayjs().subtract(i, "day");
    }
    specialDates.t = dayjs();
    specialDates.y = dayjs().subtract(1, "day");
    specialDates.n = dayjs().add(1, "day");
    specialDates.today = dayjs();
    specialDates.yesterday = dayjs().subtract(1, "day");
    specialDates.tomorrow = dayjs().add(1, "day");

    if (specialDates[inputValue.toLowerCase()]) {
      const specialDate = specialDates[inputValue.toLowerCase()];
      const formattedDate = formatDate(specialDate.toDate(), displayDateFormat);
      setPreformattedDate(specialDate.toDate());
      getDate(formatDate(specialDate.toDate(), dateFormat));
      setValue(formattedDate);
      return;
    }
    let parsedDate = null;

    for (let format of formats) {
      parsedDate = dayjs(inputValue, format, true);
      if (parsedDate.isValid()) {
        break;
      }
    }
    if (parsedDate.isValid()) {
      const formattedDate = formatDate(parsedDate.toDate(), displayDateFormat);
      setPreformattedDate(parsedDate.toDate());
      getDate(formatDate(parsedDate.toDate(), dateFormat));
      setValue(formattedDate);
      setValidValue(true);
    } else {
      setValidValue(false);
    }
  };

  return (
    <div className={className}>
      {label && <ParagraphText>{label}</ParagraphText>}
      <div ref={inputRef}>
        <DatePickerInput
          value={value}
          onClick={handleInputClick}
          onChange={(e) => setValue(e.target.value)}
          onBlur={handleInputChange}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              handleInputChange(e);
            }
          }}
          placeholder={placeholder || displayDateFormat}
          disabled={disabled}
        />
        {calendarOpen && (
          <div className="dayPicker-container" onClick={handleOutsideClick}>
            <DayPicker
              mode="single"
              format={dateFormat}
              formatDate={formatDate}
              parseDate={parseDate}
              overlayComponent={(props) => (
                <Overlay {...props} inputRef={inputRef} />
              )}
              showOutsideDays
              weekdaysShort={["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"]}
              weekStartsOn={1}
              onDayClick={(date) => handleDayClick(date)}
              selected={preformattedDate}
              onClickOutside={handleOutsideClick}
              disabled={disabled || noDateInPast ? { before: new Date() } : {}}
              captionLayout="dropdown"
              fromYear={noDateInPast ? "" : 2000}
              toDate={addYears(new Date(), 10)}
              defaultMonth={
                value ? parseDate(value, displayDateFormat) : new Date()
              }
              fixedWeeks
            />
          </div>
        )}
      </div>
    </div>
  );
};

Datepicker.defaultProps = {
  dateFormat: "YYYY/MM/DD HH:mm:ss",
};

Datepicker.propTypes = {
  className: PropTypes.string,
  dateFormat: PropTypes.string,
  defaultDate: PropTypes.oneOfType([
    PropTypes.instanceOf(Date),
    PropTypes.string,
  ]),
  getDate: PropTypes.func,
  disabled: PropTypes.bool,
};

export default Datepicker;
