import {
  FetchVehicleRecordEventHistory,
  AddEventHistory,
} from "../../../api/vendor-vehicle/EventHistory";
import { openModal, closeModal } from "./../global";
import { clearActiveConfirmModalOptions } from "./genericActiveState";

export const FETCH_VEHICLE_EVENT_HISTORY =
  "@eventHistory/FETCH_VEHICLE_EVENT_HISTORY";
export const FETCH_VEHICLE_EVENT_HISTORY_SUCCESS =
  "@eventHistory/FETCH_VEHICLE_EVENT_HISTORY_SUCCESS";
export const FETCH_VEHICLE_EVENT_HISTORY_ERROR =
  "@eventHistory/FETCH_VEHICLE_EVENT_HISTORY_ERROR";
export const ADD_EVENT_HISTORY =
  "@eventHistory/ADD_EVENT_HISTORY";
export const ADD_EVENT_HISTORY_SUCCESS =
  "@eventHistory/ADD_EVENT_HISTORY_SUCCESS";
export const ADD_EVENT_HISTORY_ERROR =
  "@eventHistory/ADD_EVENT_HISTORY_ERROR";

export const fetchVehicleEventHistory = (vehicleId) => (dispatch, getState) => {
  dispatch({ type: FETCH_VEHICLE_EVENT_HISTORY });

  const vehicleDetailsInstance = new FetchVehicleRecordEventHistory({
    params: {
      vehicleId: vehicleId,
    },
    credentials: getState().authentication.credentials,
  });

  vehicleDetailsInstance.call().then(
    (response) => {
      dispatch(fetchVehicleEventHistorySuccess(response));
    },
    (err) => {
      dispatch(fetchVehicleEventHistoryError(err));
    }
  );
};

const fetchVehicleEventHistorySuccess = (data) => {
  return {
    type: FETCH_VEHICLE_EVENT_HISTORY_SUCCESS,
    payload: data,
  };
};

const fetchVehicleEventHistoryError = (error) => {
  return {
    type: FETCH_VEHICLE_EVENT_HISTORY_ERROR,
    payload: error,
  };
};

export const addEventHistory =
  (vehicleId, eventDescription) => (dispatch, getState) => {
    dispatch({ type: ADD_EVENT_HISTORY });
    dispatch(openModal("generic-loader-modal"));

    const vehicleDetailsInstance = new AddEventHistory({
      params: {
        vehicleId: vehicleId,
        eventDescription: eventDescription,
      },
      credentials: getState().authentication.credentials,
    });

    vehicleDetailsInstance.call().then(
      (response) => {
        dispatch(addEventHistorySuccess(response));
        dispatch(openModal("vendor-vehicle-record-success-modal"));
        dispatch(fetchVehicleEventHistory(vehicleId));
        setTimeout(() => {
          dispatch(closeModal());
          dispatch(clearActiveConfirmModalOptions());
        }, 1000);
      },
      (err) => {
        dispatch(addEventHistoryError(err));
        dispatch(openModal("generic-error"));
      }
    );
  };

const addEventHistorySuccess = (data) => {
  return {
    type: ADD_EVENT_HISTORY_SUCCESS,
    payload: {
      data,
    },
  };
};

const addEventHistoryError = (error) => {
  return {
    type: ADD_EVENT_HISTORY_ERROR,
    payload: {
      error,
    },
  };
};

const initialState = {
  data: null,
  isFetching: false,
  error: null,
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_VEHICLE_EVENT_HISTORY:
      return {
        ...state,
        isFetching: true,
      };
    case FETCH_VEHICLE_EVENT_HISTORY_SUCCESS:
      return {
        ...state,
        data: action.payload,
        isFetching: false,
      };
    case FETCH_VEHICLE_EVENT_HISTORY_ERROR:
      return {
        ...state,
        isFetching: false,
        error: action.payload,
      };
    case ADD_EVENT_HISTORY_SUCCESS:
      return {
        ...state,
        isSaving: false,
        error: null,
      };
    case ADD_EVENT_HISTORY_ERROR:
      return {
        ...state,
        isSaving: false,
        error: action.payload,
      };
    case ADD_EVENT_HISTORY:
      return {
        ...state,
        isSaving: true,
      };
    default:
      return state;
  }
};

export default reducer;