import Api from "./Base";
import settings from "../clientappsettings.json";

class Cap extends Api {
  baseUrl = settings.CAP_URL;
  headers = {
    Authorization: `Bearer ${this.getCredentials()}`,
  };
}

export class FetchMakes extends Cap {
  requestUrl = `/Cap/makes`;
}

export class FetchRanges extends Cap {
    requestUrl = `/Cap/ranges/${this.getParams().makeId}`;
}

export class FetchTrims extends Cap {
  requestUrl = `/Cap/trims/${this.getParams().rangeId}`;
}

export class FetchModels extends Cap {
  requestUrl = `/Cap/models/${this.getParams().makeId}`;
}

export class VehicleLookup extends Cap {
  requestUrl = `/Cap/vehicleLookup`;
  method = "POST";
}

export default Cap;
