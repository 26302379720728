import { GetVendorHoldReasons, GetVehicleHoldReasons, AddVehicleHold, ModifyVehicleHold, RemoveVehicleHold } from "../../../api/vendor-vehicle/VehicleDetails";
import { genericFetchEventForNavigation } from "../vendorVehicleRecord";
import { clearActiveConfirmModalOptions } from "./genericActiveState";
import { openModal, closeModal } from "../global";

export const FETCH_VENDOR_HOLD_REASONS =
    "@vehicleHold/FETCH_VENDOR_HOLD_REASONS";
export const FETCH_VENDOR_HOLD_REASONS_SUCCESS =
    "@vehicleHold/FETCH_VENDOR_HOLD_REASONS_SUCCESS";
export const FETCH_VENDOR_HOLD_REASONS_ERROR =
    "@vehicleHold/FETCH_VENDOR_HOLD_REASONS_ERROR";
export const FETCH_VEHICLE_HOLD_REASONS =
    "@vehicleHold/FETCH_VEHICLE_HOLD_REASONS";
export const FETCH_VEHICLE_HOLD_REASONS_SUCCESS =
    "@vehicleHold/FETCH_VEHICLE_HOLD_REASONS_SUCCESS";
export const FETCH_VEHICLE_HOLD_REASONS_ERROR =
    "@vehicleHold/FETCH_VEHICLE_HOLD_REASONS_ERROR";
export const ADD_VEHICLE_HOLD
    = "@vehicleHold/ADD_VEHICLE_HOLD";
export const ADD_VEHICLE_HOLD_SUCCESS
    = "@vehicleHold/ADD_VEHICLE_HOLD_SUCCESS";
export const ADD_VEHICLE_HOLD_ERROR
    = "@vehicleHold/ADD_VEHICLE_HOLD_ERROR";
export const MODIFY_VEHICLE_HOLD
    = "@vehicleHold/MODIFY_VEHICLE_HOLD";
export const MODIFY_VEHICLE_HOLD_SUCCESS
    = "@vehicleHold/MODIFY_VEHICLE_HOLD_SUCCESS";
export const MODIFY_VEHICLE_HOLD_ERROR
    = "@vehicleHold/MODIFY_VEHICLE_HOLD_ERROR";
export const REMOVE_VEHICLE_HOLD
    = "@vehicleHold/REMOVE_VEHICLE_HOLD";
export const REMOVE_VEHICLE_HOLD_SUCCESS
    = "@vehicleHold/REMOVE_VEHICLE_HOLD_SUCCESS";
export const REMOVE_VEHICLE_HOLD_ERROR
    = "@vehicleHold/REMOVE_VEHICLE_HOLD_ERROR";

const fetchVendorHoldReasonsSuccess = (data) => {
    return {
        type: FETCH_VENDOR_HOLD_REASONS_SUCCESS,
        payload: data,
    };
};

const fetchVendorHoldReasonsError = (error) => {
    return {
        type: FETCH_VENDOR_HOLD_REASONS_ERROR,
        payload: error,
    };
};

export const fetchVendorHoldReasons = () => (dispatch, getState) => {
    dispatch({ type: FETCH_VENDOR_HOLD_REASONS });

    const vehicleDetailsInstance = new GetVendorHoldReasons({
        credentials: getState().authentication.credentials,
    });

    vehicleDetailsInstance.call().then(
        (response) => {
            dispatch(fetchVendorHoldReasonsSuccess(response));
        },
        (err) => {
            dispatch(fetchVendorHoldReasonsError(err));
        }
    );
};

const fetchVehicleHoldReasonsSuccess = (data) => {
    return {
        type: FETCH_VEHICLE_HOLD_REASONS_SUCCESS,
        payload: data
    };
};

const fetchVehicleHoldReasonsError = (error) => {
    return {
        type: FETCH_VEHICLE_HOLD_REASONS_ERROR,
        payload: error,
    };
};

export const fetchVehicleHoldReasons = (vehicleId) => (dispatch, getState) => {
    dispatch({ type: FETCH_VEHICLE_HOLD_REASONS });
    const vehicleDetailsInstance = new GetVehicleHoldReasons({
        params: {
            vehicleId: vehicleId,
        },
        credentials: getState().authentication.credentials,
    });

    vehicleDetailsInstance.call().then(
        (response) => {
            dispatch(fetchVehicleHoldReasonsSuccess(response));
        },
        (err) => {
            dispatch(fetchVehicleHoldReasonsError(err));
        }
    );
};

const addVehicleHoldSuccess = (data) => {
    return {
        type: ADD_VEHICLE_HOLD_SUCCESS,
        payload: data,
    };
};

const addVehicleHoldError = (error) => {
    return {
        type: ADD_VEHICLE_HOLD_ERROR,
        payload: error,
    };
};

export const addVehicleHold = (vehicleId, holdReasons, additionalHoldReason) => (dispatch, getState) => {
    dispatch({ type: ADD_VEHICLE_HOLD });
    dispatch(openModal("generic-loader-modal"));

    const vehicleDetailsInstance = new AddVehicleHold({
        params: {
            vehicleId: vehicleId,
            holdReasonCodes: holdReasons,
            additionalHoldReason: additionalHoldReason,
        },
        credentials: getState().authentication.credentials,
    });

    vehicleDetailsInstance.call().then(
        (response) => {
            dispatch(addVehicleHoldSuccess(response));
            dispatch(openModal("vendor-vehicle-record-success-modal"));
            dispatch(genericFetchEventForNavigation(null, vehicleId));
            setTimeout(() => {
                dispatch(closeModal());
                dispatch(clearActiveConfirmModalOptions());
            }, 1000);
        },
        (err) => {
            dispatch(addVehicleHoldError(err));
            dispatch(openModal("generic-error"));
        }
    );
};

const modifyVehicleHoldSuccess = (data) => {
    return {
        type: MODIFY_VEHICLE_HOLD_SUCCESS,
        payload: data,
    };
};

const modifyVehicleHoldError = (error) => {
    return {
        type: MODIFY_VEHICLE_HOLD_ERROR,
        payload: error,
    };
};

export const modifyVehicleHold = (vehicleId, holdReasons, additionalHoldReason) => (dispatch, getState) => {
    dispatch({ type: MODIFY_VEHICLE_HOLD });
    dispatch(openModal("generic-loader-modal"));

    const vehicleDetailsInstance = new ModifyVehicleHold({
        params: {
            vehicleId: vehicleId,
            holdReasonCodes: holdReasons,
            additionalHoldReason: additionalHoldReason,
        },
        credentials: getState().authentication.credentials,
    });

    vehicleDetailsInstance.call().then(
        (response) => {
            dispatch(modifyVehicleHoldSuccess(response));
            dispatch(openModal("vendor-vehicle-record-success-modal"));
            dispatch(genericFetchEventForNavigation(null, vehicleId));
            setTimeout(() => {
                dispatch(closeModal());
                dispatch(clearActiveConfirmModalOptions());
            }, 1000);
        },
        (err) => {
            dispatch(modifyVehicleHoldError(err));
            dispatch(openModal("generic-error"));
        }
    );
};

const removeVehicleHoldSuccess = (data) => {
    return {
        type: REMOVE_VEHICLE_HOLD_SUCCESS,
        payload: data,
    };
};

const removeVehicleHoldError = (error) => {
    return {
        type: REMOVE_VEHICLE_HOLD_ERROR,
        payload: error,
    };
};

export const removeVehicleHold = (vehicleId, removeHold = true, holdReasons, removeAdditionalHoldReason = true) => (dispatch, getState) => {
    dispatch({ type: REMOVE_VEHICLE_HOLD });
    dispatch(openModal("generic-loader-modal"));

    const vehicleDetailsInstance = new RemoveVehicleHold({
        params: {
            vehicleId: vehicleId,
            removeHold: removeHold,
            ...(holdReasons && { holdReasonCodes: holdReasons }),
            removeAdditionalHoldReason: removeAdditionalHoldReason
        },
        credentials: getState().authentication.credentials,
    });

    vehicleDetailsInstance.call().then(
        (response) => {
            dispatch(removeVehicleHoldSuccess(response));
            dispatch(genericFetchEventForNavigation(null, vehicleId));
            dispatch(openModal("vendor-vehicle-record-success-modal"));
            setTimeout(() => {
                dispatch(closeModal());
                dispatch(clearActiveConfirmModalOptions());
            }, 1000);
        },
        (err) => {
            dispatch(removeVehicleHoldError(err));
            dispatch(clearActiveConfirmModalOptions());
            dispatch(openModal("generic-error"));
        }
    );
};

const initialState = {
    vendorHoldDetails: {
        data: null,
        isFetching: false,
        error: null,
    },
    vehicleHoldDetails: {
        data: null,
        isFetching: false,
        error: null,
    }
};

export const reducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_VENDOR_HOLD_REASONS:
            return {
                ...state,
                vendorHoldDetails: {
                    ...state.vendorHoldDetails,
                    isFetching: true,
                },
            };
        case FETCH_VENDOR_HOLD_REASONS_SUCCESS:
            return {
                ...state,
                vendorHoldDetails: {
                    ...state.vendorHoldDetails,
                    data: action.payload,
                    isFetching: false,
                },
            };
        case FETCH_VENDOR_HOLD_REASONS_ERROR:
            return {
                ...state,
                vendorHoldDetails: {
                    ...state.vendorHoldDetails,
                    isFetching: false,
                    error: action.payload,
                },
            };
        case FETCH_VEHICLE_HOLD_REASONS:
            return {
                ...state,
                vehicleHoldDetails: {
                    ...state.vehicleHoldDetails,
                    isFetching: true,
                },
            };
        case FETCH_VEHICLE_HOLD_REASONS_SUCCESS:
            return {
                ...state,
                vehicleHoldDetails: {
                    ...state.vehicleHoldDetails,
                    data: action.payload,
                    isFetching: false,
                },
            };
        case FETCH_VEHICLE_HOLD_REASONS_ERROR:
            return {
                ...state,
                vehicleHoldDetails: {
                    ...state.vehicleHoldDetails,
                    isFetching: false,
                    error: action.payload,
                },
            };
        case ADD_VEHICLE_HOLD:
            return {
                ...state,
                holdDetails: {
                    ...state.holdDetails,
                    isUpdating: true,
                },
            };
        case ADD_VEHICLE_HOLD_SUCCESS:
            return {
                ...state,
                holdDetails: {
                    ...state.holdDetails,
                    data: {
                        ...state.holdDetails.data,
                        equipmentText: action.payload,
                    },
                    isUpdating: false,
                },
            };
        case ADD_VEHICLE_HOLD_ERROR:
            return {
                ...state,
                holdDetails: {
                    ...state.holdDetails,
                    isUpdating: false,
                    error: action.payload,
                },
            };
        case REMOVE_VEHICLE_HOLD:
            return {
                ...state,
                holdDetails: {
                    ...state.holdDetails,
                    isUpdating: true,
                },
            };
        case REMOVE_VEHICLE_HOLD_SUCCESS:
            return {
                ...state,
                holdDetails: {
                    ...state.holdDetails,
                    data: {
                        ...state.holdDetails.data,
                        equipmentText: action.payload,
                    },
                    isUpdating: false,
                },
            };
        case REMOVE_VEHICLE_HOLD_ERROR:
            return {
                ...state,
                holdDetails: {
                    ...state.holdDetails,
                    isUpdating: false,
                    error: action.payload,
                },
            };
        default:
            return state;
    }
};

export default reducer;