import Api from "../Base";
import settings from "../../clientappsettings.json";

class EventHistoryVendor extends Api {
  baseUrl = settings.VENDORS_URL;
  headers = {
    Authorization: `Bearer ${this.getCredentials()}`,
  };
}

export class FetchVehicleRecordEventHistory extends EventHistoryVendor {
  requestUrl = `/EventHistory/${this.getParams().vehicleId}`;
  formatResponseData(response) {
    return response?.data;
  }
}

export class AddEventHistory extends EventHistoryVendor {
  requestUrl = `/EventHistory/add`;
  method = "POST";
  headers = {
    Authorization: `Bearer ${this.getCredentials()}`
  };

  formatResponseData(response) {
    return response?.data;
  }
}