import ResultsNumber from "./ResultsNumber";
import styled from "styled-components";
import SubHeaderText from "../../../views/components/SubHeaderText";
import colours from "./../../../views/theme/base/colours";

const StyledResultsNumber = styled(ResultsNumber)`
  .container {
    height: 4.5rem;
    display: flex;
    justify-content: left;
    align-items: baseline;
  }
  .count {
    color: ${colours.PRIMARY.base};
    margin-right: 0.5rem;
  }

  ${SubHeaderText} {
    font-size: 1.6rem;
    color: ${colours.PRIMARY.shades[40]};
  }
`;

export default StyledResultsNumber;
