import {
  FetchVehicleRecordBiddingInfo,
  AcceptBid,
  DeclineBid,
} from "../../../api/vendor-vehicle/Bidding";
import { openModal, closeModal } from "./../global";
import { clearActiveConfirmModalOptions } from "./genericActiveState";

export const FETCH_VEHICLE_BIDDING_INFO_DETAILS =
  "@biddingInfo/FETCH_VEHICLE_BIDDING_INFO_DETAILS";
export const FETCH_VEHICLE_BIDDING_INFO_DETAILS_SUCCESS =
  "@biddingInfo/FETCH_VEHICLE_BIDDING_INFO_DETAILS_SUCCESS";
export const FETCH_VEHICLE_BIDDING_INFO_DETAILS_ERROR =
  "@biddingInfo/FETCH_VEHICLE_BIDDING_INFO_DETAILS_ERROR";
export const DECLINE_BID =
  "@biddingInfo/DECLINE_BID";
export const DECLINE_BID_SUCCESS =
  "@biddingInfo/DECLINE_BID_SUCCESS";
export const DECLINE_BID_ERROR =
  "@biddingInfo/DECLINE_BID_ERROR";
export const ACCEPT_BID =
  "@biddingInfo/ACCEPT_BID";
export const ACCEPT_BID_SUCCESS =
  "@biddingInfo/ACCEPT_BID_SUCCESS";
export const ACCEPT_BID_ERROR =
  "@biddingInfo/ACCEPT_BID_ERROR";

export const fetchVehicleBiddingInfo =
  (vehicleId, isAllBids = false) =>
    (dispatch, getState) => {
      dispatch({ type: FETCH_VEHICLE_BIDDING_INFO_DETAILS });

      const vehicleDetailsInstance = new FetchVehicleRecordBiddingInfo({
        params: {
          vehicleId: vehicleId,
          isAllBids: isAllBids,
        },
        credentials: getState().authentication.credentials,
      });

      vehicleDetailsInstance.call().then(
        (response) => {
          dispatch(fetchVehicleBiddingInfoSuccess(response));
        },
        (err) => {
          dispatch(fetchVehicleBiddingInfoError(err));
        }
      );
    };

const fetchVehicleBiddingInfoSuccess = (data) => {
  return {
    type: FETCH_VEHICLE_BIDDING_INFO_DETAILS_SUCCESS,
    payload: data,
  };
};

const fetchVehicleBiddingInfoError = (error) => {
  return {
    type: FETCH_VEHICLE_BIDDING_INFO_DETAILS_ERROR,
    payload: error,
  };
};


export const acceptBid = (vehicleId, bidId) => (dispatch, getState) => {
  dispatch({ type: ACCEPT_BID });
  dispatch(openModal("generic-loader-modal"));

  const vehicleDetailsInstance = new AcceptBid({
    params: {
      vehicleId: vehicleId,
      bidId: bidId,
    },
    credentials: getState().authentication.credentials,
  });

  vehicleDetailsInstance.call().then(
    (response) => {
      dispatch(acceptBidSuccess(bidId));
      dispatch(openModal("vendor-vehicle-record-success-modal"));
      setTimeout(() => {
        dispatch(closeModal());
        dispatch(clearActiveConfirmModalOptions());
      }, 1000);
    },
    (err) => {
      dispatch(acceptBidError(err));
      dispatch(clearActiveConfirmModalOptions());
      dispatch(openModal("generic-error"));
    }
  );
};

const acceptBidSuccess = (bidId) => {
  return {
    type: ACCEPT_BID_SUCCESS,
    payload: {
      bidId: bidId,
    },
  };
};

const acceptBidError = (error) => {
  return {
    type: ACCEPT_BID_ERROR,
    payload: error,
  };
};

export const declineBid = (vehicleId, bidId) => (dispatch, getState) => {
  dispatch({ type: DECLINE_BID });
  dispatch(openModal("generic-loader-modal"));

  const vehicleDetailsInstance = new DeclineBid({
    params: {
      vehicleId: vehicleId,
      bidId: bidId,
    },
    credentials: getState().authentication.credentials,
  });

  vehicleDetailsInstance.call().then(
    (response) => {
      dispatch(declineBidSuccess(bidId));
      dispatch(openModal("vendor-vehicle-record-success-modal"));
      setTimeout(() => {
        dispatch(closeModal());
        dispatch(clearActiveConfirmModalOptions());
      }, 1000);
    },
    (err) => {
      dispatch(declineBidError(err));
      dispatch(clearActiveConfirmModalOptions());
      dispatch(openModal("generic-error"));
    }
  );
};

const declineBidSuccess = (bidId) => {
  return {
    type: DECLINE_BID_SUCCESS,
    payload: {
      bidId: bidId,
    },
  };
};

const declineBidError = (error) => {
  return {
    type: DECLINE_BID_ERROR,
    payload: error,
  };
};

const initialState = {
  bids: null,
  acceptedBid: null,
  salesInformation: null,
  showPriceWhenBidding: null,
  showReserveWhenBidding: null,
  allowBidsBelowReserve: null,
  allowBuyItNow: null,
  isFetching: false,
  isDeclineBid: false,
  isAcceptBid: false,
  error: null,
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_VEHICLE_BIDDING_INFO_DETAILS:
      return {
        ...state,
        isFetching: true,
      };
    case FETCH_VEHICLE_BIDDING_INFO_DETAILS_SUCCESS:
      return {
        ...state,
        bids: action.payload?.bids,
        acceptedBid: action.payload?.acceptedBid,
        salesInformation: action.payload?.salesInfo,
        showPriceWhenBidding: action.payload?.showPriceWhenBidding,
        showReserveWhenBidding: action.payload?.showReserveWhenBidding,
        allowBidsBelowReserve: action.payload?.allowBidsBelowReserve,
        allowBuyItNow: action.payload?.allowBuyItNow,
        isFetching: false,
      };

    case FETCH_VEHICLE_BIDDING_INFO_DETAILS_ERROR:
      return {
        ...state,
        isFetching: false,
        error: action.payload,
      };
    case ACCEPT_BID:
      return {
        ...state,
        isAcceptBid: true,
      };
    case ACCEPT_BID_SUCCESS:
      return {
        ...state,
        isAcceptBid: false,
        bids: state.bids.map((bid) => ({
          ...bid,
          bidStatus:
            bid.bidId === action.payload.bidId ? "Accepted" : bid.bidStatus,
        })),
      };
    case ACCEPT_BID_ERROR:
      return {
        ...state,
        isAcceptBid: false,
        error: action.payload,
      };
    case DECLINE_BID:
      return {
        ...state,
        isDeclineBid: true,
      };
    case DECLINE_BID_SUCCESS:
      return {
        ...state,
        isDeclineBid: false,
        bids: state.bids.map((bid) => ({
          ...bid,
          bidStatus:
            bid.bidId === action.payload.bidId ? "Declined" : bid.bidStatus,
        })),
      };
    case DECLINE_BID_ERROR:
      return {
        ...state,
        isDeclineBid: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default reducer;