import React, { useContext } from "react";
import { useSelector, useDispatch } from "react-redux";
import { closeModal } from "../../../state/reducers/global";
import Modal from "../../components/Modal";
import ConfirmOrderModalInsert from "./../ConfirmOrderModalInsert";
import StocklistDownloadModalInsert from "../StocklistDownloadModalInsert";
import LogoutModalInsert from "./../LogoutModalInsert";
import LoaderModalInsert from "../LoaderModalInsert";
import PendingPurchase from "../PendingPurchase";
import ConfirmBidModal from "../ConfirmBidModal";
import Bid from "../Bid";
import IframeCamContainer from "../IframeCamContainer";
import VehicleGallery from "../VehicleGallery";
import VehicleUnavailable from "../VehicleUnavailable";
import VehicleUnavailableNewTab from "../VehicleUnavailableNewTab";
import GenericError from "./../GenericError";
import AddDeliveryAddressModalInsert from "./../AddDeliveryAddressModalInsert";
import UpdateDeliveryAddressModalInsert from "./../UpdateDeliveryAddressModalInsert";
import AccountAdministrationAddressModalInsert from "./../AccountAdministrationAddressModalInsert";
import ConfirmSuspendedUserModalInsert from "./../ConfirmSuspendedUserModalInsert";
import ClaimItemAddressModalInsert from "../ClaimItemAddressModalInsert";
import ClaimsAdminRegSearchModalInsert from "../ClaimsAdminRegSearchModalInsert";
import SalesBrochureModalInsert from "./../SalesBrochureModalInsert";
import ShowroomModalInsert from "./../ShowroomModalInsert";
import InvoiceDownloadModalInsert from "./../InvoiceDownloadModalInsert";
import DealerFundingRequestModalInsert from "../DealerFundingRequestModalInsert";
import DealerFundingRequestSuccessModalInsert from "../DealerFundingRequestSuccessModalInsert";
import DealerFundingRequestErrorModalInsert from "../DealerFundingRequestErrorModalInsert";
import LogisticsStatusModalInsert from "./../LogisticsStatusModalInsert";
import ClaimSubmissionModalInsert from "./../ClaimSubmissionModalInsert";
import { ThemeContext } from "styled-components";
import { useMediaQuery } from "react-responsive";
import breakpoints from "../../theme/breakpoints";
import TariffModalInsert from "./../TariffModalInsert/index";
import FaultGallery from "../FaultGallery";
import {
  ClaimCallbackNoteContextProvider,
  ClaimCallbackNoteValidationContextProvider,
} from "../../components/ClaimRequestCallback/ClaimCallBackForm/context/context";
import ClaimCallBackInsert from "../ClaimCallBackInsert";
import ClaimDeleteModalInsert from "../ClaimDeleteModalInsert";
import ClaimActivityLogModalInsert from "./../ClaimActivityLogModalInsert";
import VendorDeclineModalInsert from "../VendorDeclineModalInsert";
import VendorDeclineDecisionModalInsert from "../VendorDeclineClaimDecision";
import VendorInformationRequestDecision from "../VendorInformationRequestDecision";
import VendorInformationRequestModalInsert from "../VendorInformationRequestModalInsert";
import VendorReviewCompleteModalInsert from "../VendorReviewCompleteModalInsert";
import VendorReviewCompleteDecision from "../VendorReviewCompleteDecision";
import { ReviewCompleteReasonContextProvider } from "./../../components/VendorReviewComplete/VendorReviewCompleteMessage/context/context";
import {
  DeclineClaimReasonContextProvider,
  DeclineClaimReasonValidationContextProvider,
} from "../../components/VendorDeclineClaim/VendorDeclineClaimMessage/context/context";
import {
  InformationRequestReasonContextProvider,
  InformationRequestReasonValidationContextProvider,
} from "../../components/VendorInformationRequest/VendorInformationRequestMessage/context/context";
import ClaimCloseModalInsert from "../ClaimCloseModalInsert";
import CompleteCallbackRequestModalInsert from "../../../vendor/containers/ConfirmCallbackRequestModalInsert";
import MakeOfferModalInsert from "../../../vendor/containers/MakeOfferModalInsert";
import CancelSaleModalInsert from "../../../vendor/containers/CancelSaleModalInsert";
import VendorCancelSaleDecision from "../VendorCancelSaleDecision";
import AcceptSaleModalInsert from "../AcceptSaleModalInsert";
import AcceptSaleDecision from "../AcceptSaleDecision";
import ClaimCompleteModalInsert from "../../../vendor/containers/ClaimCompleteModalInsert";
import CampaignAddVehiclesModalInsert from "../../../vendor/containers/CampaignAddVehiclesModalInsert";
import CampaignModalInsert from "../../../vendor/containers/CampaignModalInsert";
import PromotionModal from "../PromotionModal";
import VendorAddNoteModalInsert from "../VendorAddNoteModalInsert";
import { VendorAddNoteReasonContextProvider } from "../../components/VendorAddNote/context/context";
import KeyboardShortcutsModalInsert from "../KeyboardShortcutsModalInsert";
import UnsubscribeNotificationsModalInsert from "../../../vendor/containers/UnsubscribeNotificationsModalInsert";
import AboutSessionModalInsert from "../AboutSessionModalInsert";
import DragDropModalInsert from "../DragDropModalInsert";
import VendorVehicleRecordGenericAreYouSureModal from "../VendorVehicleRecordGenericAreYouSureModal";
import VendorVehicleRecordGenericSuccessModal from "../../components/VendorVehicleRecordGenericSuccessModal";
import GenericTextAreaModal from "../../components/GenericTextAreaModal/GenericTextAreaModal";
import VendorVehicleRecordHoldModalContainer from "../../../vendor/containers/VendorVehicleRecordHoldModalContainer";
import LocationEditModalInsert from "../../../vendor/containers/LocationEditModalInsert";
import VendorVehicleImagesModalContainer from "../../../vendor/containers/VendorVehicleImagesModalContainer";
import VendorVehicleRecordReceivePaymentModalContainer from "../../../vendor/containers/VendorVehicleRecordReceivePaymentModalContainer";
import VendorVehicleLookupModalContainer from "../../../vendor/containers/VendorVehicleLookupModalContainer";
import VendorVehicleRecordPublishModalContainer from "../../../vendor/containers/VendorVehicleRecordPublishModalContainer";

const ModalContainer = (props) => {
  const isOpen = useSelector((state) => state.global.modals.isOpen);
  const modalId = useSelector((state) => state.global.modals.modalId);
  const dispatch = useDispatch();
  const onCloseModalClick = () => dispatch(closeModal());
  let isDismissible = true;
  const theme = useContext(ThemeContext);
  let bgColor = theme.COLOURS.WHITE;
  let contentOverflow = "hidden";
  let modalWidth = null;
  let modalHeight = null;
  let Content = () => null;
  let fullScreen = false;
  const isDesktop = useMediaQuery({
    query: `(min-width: ${breakpoints.LARGE_DESKTOP})`,
  });

  const modalWidths = {
    small: "55rem",
    medium: "72rem",
    large: "96rem",
    auto: "auto",
  };

  switch (modalId) {
    case "bid":
      Content = () => <Bid />;
      modalWidth = isDesktop ? modalWidths.large : "100%";
      break;
    case "confirm-order":
      Content = () => <ConfirmOrderModalInsert />;
      modalWidth = isDesktop ? modalWidths.large : "100%";
      break;
    case "confirm-purchase-loader":
      Content = () => (
        <LoaderModalInsert
          title={"Completing your purchase..."}
          content={"We are just processing your purchase, please wait."}
        />
      );
      isDismissible = false;
      break;
    case "confirm-bid-loader":
      Content = () => (
        <LoaderModalInsert
          title={"Placing your bid"}
          content={"We are just processing your bid, please wait."}
        />
      );
      isDismissible = false;
      break;
    case "confirm-bid":
      Content = () => <ConfirmBidModal />;
      modalWidth = isDesktop ? modalWidths.large : "100%";
      isDismissible = false;
      break;
    case "pending-purchase":
      Content = () => <PendingPurchase />;
      isDismissible = false;
      break;
    case "vehicle-unavailable":
      Content = () => <VehicleUnavailable />;
      isDismissible = false;
      break;
    case "vehicle-unavailable-new-tab":
      Content = () => <VehicleUnavailableNewTab />;
      isDismissible = false;
      break;
    case "generic-error":
      Content = () => <GenericError />;
      isDismissible = false;
      break;
    case "trade-member-already-bid":
      Content = () => (
        <GenericError
          headerText="Sorry, this vehicle is unavailable"
          bodyText="The vehicle you were looking at has been requested to purchase from another member in your Trade Buyer Group. Please contact your Trade Buyer Group user for further details."
        />
      );
      isDismissible = false;
      break;
    case "stocklist-download":
      Content = () => <StocklistDownloadModalInsert />;
      modalWidth = isDesktop ? modalWidths.small : "100%";
      break;
    case "add-delivery-address":
      Content = () => <AddDeliveryAddressModalInsert />;
      modalWidth = isDesktop ? modalWidths.medium : "100%";
      contentOverflow = "visible";
      bgColor = theme.COLOURS.PRIMARY.shades[5];
      break;
    case "update-delivery-address":
      Content = () => <UpdateDeliveryAddressModalInsert />;
      modalWidth = isDesktop ? modalWidths.medium : "100%";
      contentOverflow = "visible";
      bgColor = theme.COLOURS.PRIMARY.shades[5];
      break;
    case "account-administration-address":
      Content = () => <AccountAdministrationAddressModalInsert />;
      modalWidth = isDesktop ? modalWidths.medium : "100%";
      contentOverflow = "visible";
      bgColor = theme.COLOURS.PRIMARY.shades[5];
      break;
    case "claim-vehicle-address":
      Content = () => <ClaimItemAddressModalInsert />;
      modalWidth = isDesktop ? modalWidths.medium : "100%";
      contentOverflow = "visible";
      bgColor = theme.COLOURS.PRIMARY.shades[5];
      break;
    case "claim-registration-number-search":
      Content = () => <ClaimsAdminRegSearchModalInsert />;
      modalWidth = isDesktop ? modalWidths.large : "100%";
      contentOverflow = "visible";
      bgColor = theme.COLOURS.PRIMARY.shades[5];
      break;
    case "confirm-claim":
      Content = () => <ClaimSubmissionModalInsert />;
      modalWidth = isDesktop ? modalWidths.medium : "100%";
      contentOverflow = "visible";
      bgColor = theme.COLOURS.PRIMARY.shades[5];
      break;
    case "logout":
      Content = () => <LogoutModalInsert />;
      modalWidth = isDesktop ? modalWidths.small : "100%";
      break;
    case "cam360":
      Content = () => <IframeCamContainer />;
      modalWidth = "100vw";
      fullScreen = true;
      break;
    case "gallery":
      Content = () => <VehicleGallery />;
      modalWidth = "100vw";
      fullScreen = true;
      break;
    case "fault-gallery":
      Content = () => <FaultGallery />;
      modalWidth = "100vw";
      fullScreen = true;
      break;
    case "confirm-suspended-user":
      Content = () => <ConfirmSuspendedUserModalInsert />;
      modalWidth = isDesktop ? modalWidths.medium : "100%";
      contentOverflow = "visible";
      bgColor = theme.COLOURS.PRIMARY.shades[5];
      break;
    case "sales-brochure":
      Content = () => <SalesBrochureModalInsert />;
      modalWidth = isDesktop ? modalWidths.large : "100%";
      break;
    case "showroom":
      Content = () => <ShowroomModalInsert />;
      modalWidth = isDesktop ? modalWidths.large : "100%";
      break;
    case "invoice-download":
      Content = () => <InvoiceDownloadModalInsert />;
      modalWidth = isDesktop ? modalWidths.small : "100%";
      break;
    case "dealer-funding-request":
      Content = () => <DealerFundingRequestModalInsert />;
      modalWidth = isDesktop ? modalWidths.large : "100%";
      break;
    case "dealer-funding-request-loader":
      Content = () => (
        <LoaderModalInsert
          title={"Applying for funding"}
          content={"We are just processing your funding request, please wait."}
        />
      );
      isDismissible = false;
      break;
    case "dealer-funding-request-success":
      Content = () => <DealerFundingRequestSuccessModalInsert />;
      modalWidth = isDesktop ? modalWidths.large : "100%";
      break;
    case "dealer-funding-request-error":
      Content = () => <DealerFundingRequestErrorModalInsert />;
      modalWidth = isDesktop ? modalWidths.large : "100%";
      break;
    case "logistics-status":
      Content = () => <LogisticsStatusModalInsert />;
      modalWidth = isDesktop ? modalWidths.auto : "100%";
      break;
    case "logistics-status-loader":
      Content = () => (
        <LoaderModalInsert
          title={"Tracking logistics status"}
          content={"We are just processing your tracking request, please wait."}
        />
      );
      isDismissible = false;
      break;
    case "tariff-modal":
      Content = () => <TariffModalInsert />;
      modalWidth = isDesktop ? modalWidths.medium : "100%";
      break;
    case "request-call-back":
      Content = () => (
        <ClaimCallbackNoteContextProvider>
          <ClaimCallbackNoteValidationContextProvider>
            <ClaimCallBackInsert />
          </ClaimCallbackNoteValidationContextProvider>
        </ClaimCallbackNoteContextProvider>
      );
      modalWidth = isDesktop ? modalWidths.auto : "100%";
      break;
    case "delete-decision":
      Content = () => <ClaimDeleteModalInsert />;
      modalWidth = isDesktop ? modalWidths.small : "100%";
      break;
    case "activity-log-modal":
      Content = () => <ClaimActivityLogModalInsert />;
      modalWidth = isDesktop ? modalWidths.large : "100%";
      break;
    case "vendor-decline-modal":
      Content = () => (
        <DeclineClaimReasonContextProvider>
          <DeclineClaimReasonValidationContextProvider>
            <VendorDeclineModalInsert />
          </DeclineClaimReasonValidationContextProvider>
        </DeclineClaimReasonContextProvider>
      );
      break;
    case "vendor-decline-decision":
      Content = () => <VendorDeclineDecisionModalInsert />;
      modalWidth = isDesktop ? modalWidths.small : "100%";
      break;
    case "vendor-information-request-modal":
      Content = () => (
        <InformationRequestReasonContextProvider>
          <InformationRequestReasonValidationContextProvider>
            <VendorInformationRequestModalInsert />
          </InformationRequestReasonValidationContextProvider>
        </InformationRequestReasonContextProvider>
      );
      break;
    case "vendor-information-request-decision":
      Content = () => <VendorInformationRequestDecision />;
      modalWidth = isDesktop ? modalWidths.small : "100%";
      break;
    case "vendor-review-complete-modal":
      Content = () => (
        <ReviewCompleteReasonContextProvider>
          <VendorReviewCompleteModalInsert />
        </ReviewCompleteReasonContextProvider>
      );
      break;
    case "vendor-review-complete-decision":
      Content = () => <VendorReviewCompleteDecision />;
      modalWidth = isDesktop ? modalWidths.small : "100%";
      break;
    case "close-decision":
      Content = () => <ClaimCloseModalInsert />;
      modalWidth = isDesktop ? modalWidths.small : "100%";
      break;
    case "complete-callback":
      Content = () => <CompleteCallbackRequestModalInsert />;
      modalWidth = isDesktop ? modalWidths.auto : "100%";
      break;
    case "make-offer":
      Content = () => <MakeOfferModalInsert />;
      modalWidth = isDesktop ? modalWidths.auto : "100%";
      break;
    case "vendor-cancel-sale-modal":
      Content = () => <CancelSaleModalInsert />;
      modalWidth = isDesktop ? modalWidths.auto : "100%";
      break;
    case "vendor-cancel-sale-decision":
      Content = () => <VendorCancelSaleDecision />;
      modalWidth = isDesktop ? modalWidths.auto : "100%";
      break;
    case "accept-sale-modal":
      Content = () => <AcceptSaleModalInsert />;
      modalWidth = isDesktop ? modalWidths.auto : "100%";
      break;
    case "accept-sale-decision":
      Content = () => <AcceptSaleDecision />;
      modalWidth = isDesktop ? modalWidths.auto : "100%";
      break;
    case "vendor-complete-claim-modal":
      Content = () => <ClaimCompleteModalInsert />;
      modalWidth = isDesktop ? modalWidths.small : "100%";
      break;
    case "campaign-add-vehicles":
      Content = () => <CampaignAddVehiclesModalInsert />;
      modalWidth = isDesktop ? modalWidths.auto : "100%";
      break;
    case "claim-already-raised":
      Content = () => (
        <GenericError
          headerText="Claim Already Raised"
          bodyText="A claim has already been raised against this purchase."
        />
      );
      isDismissible = false;
      break;
    case "campaign-modal":
      Content = () => <CampaignModalInsert />;
      modalWidth = isDesktop ? modalWidths.small : "100%";
      break;
    case "promotion":
      Content = () => <PromotionModal />;
      modalWidth = isDesktop ? modalWidths.large : "100%";
      modalHeight = isDesktop ? "60rem" : "100%";
      break;
    case "vendor-add-note-modal":
      Content = () => (
        <VendorAddNoteReasonContextProvider>
          <VendorAddNoteModalInsert />
        </VendorAddNoteReasonContextProvider>
      );
      modalWidth = isDesktop ? modalWidths.auto : "100%";
      break;
    case "unsubscribe-notifications-modal":
      Content = () => <UnsubscribeNotificationsModalInsert />;
      modalWidth = isDesktop ? modalWidths.small : "100%";
      break;
    case "keyboard-shortcuts":
      Content = () => <KeyboardShortcutsModalInsert />;
      modalWidth = isDesktop ? modalWidths.large : "100%";
      break;
    case "about-session":
      Content = () => <AboutSessionModalInsert />;
      modalWidth = isDesktop ? modalWidths.large : "100%";
      modalHeight = isDesktop ? "60rem" : "100%";
      break;
    case "drag-drop-modal":
      Content = () => <DragDropModalInsert />;
      modalWidth = isDesktop ? modalWidths.large : "100%";
      break;
    case "vendor-vehicle-record-are-you-sure-modal":
      Content = () => <VendorVehicleRecordGenericAreYouSureModal />;
      modalWidth = isDesktop ? modalWidths.auto : "100%";
      break;
    case "vendor-vehicle-record-success-modal":
      Content = () => <VendorVehicleRecordGenericSuccessModal />;
      modalWidth = isDesktop ? modalWidths.auto : "100%";
      isDismissible = false;
      break;
    case "generic-text-area-modal":
      Content = () => <GenericTextAreaModal />;
      modalWidth = isDesktop ? modalWidths.auto : "100%";
      break;
    case "vendor-vehicle-record-hold-modal":
      Content = () => <VendorVehicleRecordHoldModalContainer />;
      modalWidth = isDesktop ? modalWidths.auto : "100%";
      isDismissible = false;
      break;
    case "location-edit-modal":
      Content = () => <LocationEditModalInsert />;
      modalWidth = isDesktop ? modalWidths.large : "100%";
      break;
    case "generic-loader-modal":
      Content = () => <LoaderModalInsert />;
      modalWidth = isDesktop ? modalWidths.auto : "100%";
      isDismissible = false;
      break;
    case "vendor-vehicle-images-modal":
      Content = () => <VendorVehicleImagesModalContainer />;
      modalWidth = isDesktop ? modalWidths.large : "100%";
      break;
    case "vendor-vehicle-record-dvla-error-modal":
      Content = () => (
        <GenericError
          headerText="Generic Colour Retrieval Failed"
          bodyText="The vehicle generic colour could not be retrieved. Please try again later."
        />
      );
      isDismissible = false;
      break;
    case "vendor-vehicle-record-receive-payment-modal":
      Content = () => <VendorVehicleRecordReceivePaymentModalContainer />;
      isDismissible = false;
      break;
    case "vendor-vehicle-lookup-modal":
      Content = () => <VendorVehicleLookupModalContainer />;
      modalWidth = isDesktop ? modalWidths.large : "100%";
      isDismissible = false;
      break;
    case "vendor-vehicle-record-publish-modal":
      Content = () => <VendorVehicleRecordPublishModalContainer />;
      modalWidth = isDesktop ? modalWidths.large : "100%";
      isDismissible = false;
      break;
    default:
      Content = () => <GenericError />;
      isDismissible = false;
      break;
  }
  return (
    <Modal
      isOpen={isOpen}
      modalId={modalId}
      onCloseModalClick={onCloseModalClick}
      role={props.role}
      modalLabel={props.modalLabel}
      isDismissible={isDismissible}
      bgColor={bgColor}
      contentOverflow={contentOverflow}
      modalWidth={modalWidth}
      fullScreen={fullScreen}
      modalHeight={modalHeight}
    >
      <Content />
    </Modal>
  );
};

export default ModalContainer;
