const PRIMARY = {
  base: "#33302e",
  shades: {
    5: "#ececec",
    10: "#EAEAEA",
    20: "#D6D6D5",
    30: "#C1C0C0",
    40: "#ADACAB",
    50: "#999796",
    60: "#858382",
    70: "#706E6C",
    80: "#5C5958",
    90: "#474442",
  },
};

const ACCENT = {
  base: "#ea5d1a",
  shades: {
    10: "#FCEEE8",
    20: "#FBDFD1",
    30: "#F8CEBA",
    40: "#F7BEA3",
    50: "#F4AE8C",
    60: "#F29E76",
    70: "#F08D5E",
    80: "#EE7D48",
    90: "#EC6D30",
  },
};

const SECONDARY = {
  base: "#0F0F0F",
  shades: {
    5: "#e9e9e9",
    10: "#e7e7e7",
    20: "#cfcfcf",
    30: "#b7b7b7",
    40: "#9f9f9f",
    50: "#878787",
    60: "#6f6e6e",
    70: "#575656",
    80: "#3f3e3e",
    90: "#272626",
  },
};

const SECONDARY_ACCENT = {
  base: "#bb4a15",
  shades: {
    10: "#f8ede8",
    20: "#f1dbd0",
    30: "#ebc9b9",
    40: "#e4b7a1",
    50: "#dda58a",
    60: "#d69273",
    70: "#cf805b",
    80: "#c96e44",
    90: "#c25c2c",
  },
};

const GREEN = {
  base: "#429D16",
  shades: {
    10: "#ECF5E7",
    20: "#D9EBD0",
    30: "#C6E1B9",
    40: "#B2D8A2",
    50: "#A0CE8A",
    60: "#8EC473",
    70: "#7ABA5B",
    80: "#68B145",
    90: "#54A62D",
  },
};

const RED = {
  base: "#F22B2B",
  shades: {
    10: "#FDE9E9",
    20: "#FCD5D5",
    30: "#FBBFBF",
    40: "#FAAAAA",
    50: "#F89595",
    60: "#F78080",
    70: "#F56A6A",
    80: "#F55555",
    90: "#F34040",
  },
};

const ORANGE = {
  base: "#FE7214",
  shades: {
    10: "#FEF0E7",
    20: "#FFE3D0",
    30: "#FED4B8",
    40: "#FFC7A1",
    50: "#FEB889",
    60: "#FEAA72",
    70: "#FE9C5A",
    80: "#FE8E43",
    90: "#FE802B",
  },
};

const TRANSPARENCIES = {
  backgroundImage: "rgba(0,0,0,0.7)",
  primary: "rgba(51,48,46,0.3)",
  primaryDark: "rgba(51,48,46,0.9)",
  green: "rgba(66,157,22,0.05)",
  white: "rgba(27, 34, 59, 0.1)",
  semiWhite: "rgba(255, 255, 255, 0.125)",
};

const COLOURS = {
  PRIMARY,
  SECONDARY,
  ACCENT,
  SECONDARY_ACCENT,
  RED,
  GREEN,
  ORANGE,
  BLACK: "#000000",
  BLUE: "#2183CD",
  BROWN: "#964B00",
  WHITE: "#FFFFFF",
  YELLOW: "#feb40c",
  SILVER: "#C0C0C0",
  GREY: "#808080",
  CREAM: "#FFFDD0",
  GOLD: "#FFD700",
  PINK: "#FFC0CB",
  PURPLE: "#A020F0",
  BEIGE: "#F5F5DC",
  BRONZE: "#CD7F32",
  MAROON: "#800000",
  TURQUOISE: "#40e0d0",
  ACTION_GREEN: "#4D9152",
  ALERT_RED: "#C33939",
  TRANSPARENCIES,
};

export default COLOURS;
