export const SET_ACTIVE_NAVIGATION_OPTION =
  "@genericActiveState/SET_ACTIVE_NAVIGATION_OPTION";
export const CLEAR_ACTIVE_NAVIGATION_OPTION =
  "@genericActiveState/CLEAR_ACTIVE_NAVIGATION_OPTION";
export const SET_ACTIVE_CONFIRM_MODAL_OPTIONS =
  "@genericActiveState/SET_ACTIVE_CONFIRM_MODAL_OPTIONS";
export const CLEAR_ACTIVE_CONFIRM_MODAL_OPTIONS =
  "@genericActiveState/CLEAR_ACTIVE_CONFIRM_MODAL_OPTIONS";
export const SET_ACTIVE_GENERIC_TEXT_AREA_MODAL_OPTIONS =
  "@genericActiveState/SET_ACTIVE_GENERIC_TEXT_AREA_MODAL_OPTIONS";
export const CLEAR_ACTIVE_GENERIC_TEXT_AREA_MODAL_OPTIONS =
  "@genericActiveState/CLEAR_ACTIVE_GENERIC_TEXT_AREA_MODAL_OPTIONS";
export const SET_ACTIVE_VEHICLE_REFERENCE =
  "@genericActiveState/SET_ACTIVE_VEHICLE_REFERENCE";
export const SET_ACTIVE_LOCATION_TYPE =
  "@genericActiveState/SET_ACTIVE_LOCATION_TYPE";
export const CLEAR_ACTIVE_LOCATION_TYPE =
  "@genericActiveState/CLEAR_ACTIVE_LOCATION_TYPE";

export const setActiveNavigationOption = (activeNavOption) => {
  return {
    type: SET_ACTIVE_NAVIGATION_OPTION,
    payload: activeNavOption,
  };
};

export const setActiveVehicleReference = (vehicleReference) => {
  return {
    type: SET_ACTIVE_VEHICLE_REFERENCE,
    payload: vehicleReference,
  };
};

export const setActiveLocationType = (locationType) => {
  return {
    type: SET_ACTIVE_LOCATION_TYPE,
    payload: locationType,
  };
};

export const clearActiveLocationType = () => {
  return {
    type: CLEAR_ACTIVE_LOCATION_TYPE,
  };
};

export const clearActiveNavigationOption = () => {
  return {
    type: CLEAR_ACTIVE_NAVIGATION_OPTION,
  };
};

export const setActiveConfirmModalOptions = (type, data) => (dispatch) => {
  dispatch({
    type: SET_ACTIVE_CONFIRM_MODAL_OPTIONS,
    payload: { type, data },
  });
};

export const clearActiveConfirmModalOptions = () => (dispatch) => {
  dispatch({
    type: CLEAR_ACTIVE_CONFIRM_MODAL_OPTIONS,
  });
};

export const setActiveGenericTextAreaModalOptions =
  (type, data) => (dispatch) => {
    dispatch({
      type: SET_ACTIVE_GENERIC_TEXT_AREA_MODAL_OPTIONS,
      payload: { type, data },
    });
  };

export const clearActiveGenericTextAreaModalOptions = () => (dispatch) => {
  dispatch({
    type: CLEAR_ACTIVE_GENERIC_TEXT_AREA_MODAL_OPTIONS,
  });
};

const initialState = {
  activeNavOption: null,
  activeVehicleReference: null,
  activeConfirmModalOptions: {
    type: null,
    data: null,
  },
  activeGenericTextAreaModalOptions: {
    type: null,
    data: null,
  },
  locationType: null,
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_ACTIVE_NAVIGATION_OPTION:
      return {
        ...state,
        activeNavOption: action.payload,
      };
    case SET_ACTIVE_VEHICLE_REFERENCE:
      return {
        ...state,
        activeVehicleReference: action.payload,
      };
    case SET_ACTIVE_LOCATION_TYPE:
      return {
        ...state,
        locationType: action.payload,
      };
    case CLEAR_ACTIVE_LOCATION_TYPE:
      return {
        ...state,
        locationType: "",
      };
    case SET_ACTIVE_CONFIRM_MODAL_OPTIONS:
      return {
        ...state,
        activeConfirmModalOptions: {
          ...state.activeConfirmModalOptions,
          type: action.payload.type,
          data: action.payload.data,
        },
      };
    case CLEAR_ACTIVE_CONFIRM_MODAL_OPTIONS:
      return {
        ...state,
        activeConfirmModalOptions: initialState.activeConfirmModalOptions,
      };

    case SET_ACTIVE_GENERIC_TEXT_AREA_MODAL_OPTIONS:
      return {
        ...state,
        activeGenericTextAreaModalOptions: {
          ...state.activeGenericTextAreaModalOptions,
          type: action.payload.type,
          data: action.payload.data,
        },
      };
    case CLEAR_ACTIVE_GENERIC_TEXT_AREA_MODAL_OPTIONS:
      return {
        ...state,
        activeGenericTextAreaModalOptions:
          initialState.activeGenericTextAreaModalOptions,
      };
      case CLEAR_ACTIVE_NAVIGATION_OPTION: 
      return {
        ...state,
        activeNavOption: null,
      }
    default:
      return state;
  }
};

export default reducer;