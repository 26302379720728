import styled from "styled-components";

import SkeletonVendorImagesModal from "./SkeletonVendorImagesModal.jsx";
import breakpoints from "../../../theme/breakpoints";

const StyledSkeletonVendorImagesModal = styled(SkeletonVendorImagesModal)`
  height: 100%;
  max-height: 50rem;

  @media (min-width: ${breakpoints.LARGE_DESKTOP}) {
    height: auto;
  }

  .modal-bottom {
    margin-top: auto;
  }

  .skeleton-image-container {
    display: flex;
    flex-wrap: wrap;

    > span {
      align-items: center;
      box-sizing: border-box;
      display: flex;
      padding: 1rem 1.6rem 0 0;
      width: 100%;
      gap: 1rem;
      padding-right: 1rem;
    }
  }

  .skeleton-image {
    ${props => props.theme.ANIMATIONS["shimmerDark"]};
    width: 100%;
    height: 12rem; 
    max-height: 56.25vw;

  @keyframes shimmer {
    0% {
      background-position: -100rem 0;
    }
    100% {
      background-position: 100rem 0;
    }
  }
`;

export default StyledSkeletonVendorImagesModal;
