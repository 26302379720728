import Exclude from "./Exclude";
import Include from "./Include";
import ChevronDown from "./ChevronDown";
import ChevronLeft from "./ChevronLeft";
import LogoWhite from "./LogoWhite";
import LogoBlue from "./LogoBlue";
import Search from "./Search";
import User from "./User";
import Lock from "./Lock";
import Danger from "./Danger";
import Cam360 from "./Cam360";
import Car4x4 from "./Car4x4";
import AlertCircle from "./AlertCircle";
import AlertCircleAlt from "./AlertCircleAlt";
import AlertCircleSolid from "./AlertCircleSolid";
import AlertTriangle from "./AlertTriangle";
import CheckCircle from "./CheckCircle";
import ChevronRight from "./ChevronRight";
import ChevronUp from "./ChevronUp";
import CarConvertible from "./CarConvertible";
import ArrowDownload from "./ArrowDownload";
import Envelope from "./Envelope";
import ErrorCircle from "./ErrorCircle";
import CarEstate from "./CarEstate";
import FilterConical from "./FilterConical";
import ArrowForward from "./ArrowForward";
import CarHatchback from "./CarHatchback";
import Picture from "./Picture";
import InfoCircle from "./InfoCircle";
import InfoCircleSolid from "./InfoCircleSolid";
import ListView from "./ListView";
import ArrowRefresh from "./ArrowRefresh";
import ArrowMatches from "./ArrowMatches";
import SpeechBubble from "./SpeechBubble";
import NavBurger from "./NavBurger";
import CarPickup from "./CarPickup";
import Printer from "./Printer";
import QuestionCircle from "./QuestionCircle";
import CarSaloon from "./CarSaloon";
import Settings from "./Settings";
import ListSort from "./ListSort";
import Star from "./Star";
import Stopwatch from "./Stopwatch";
import Dash from "./Dash";
import Trash from "./Trash";
import ActionCircleSolid from "./ActionCircleSolid";
import Dot from "./Dot";
import Hammer from "./Hammer";
import Purchase from "./Purchase";
import Loading from "./Loading";
import Stack from "./Stack";
import LogoMfl from "./LogoMfl";
import LogoSeat from "./LogoSeat";
import LogoVWCV from "./LogoVWCV";
import LogoVW from "./LogoVW";
import LogoDN from "./LogoDN";
import Calendar from "./Calendar";
import AddNote from "./AddNote";
import ViewNote from "./ViewNote";
import ExclamationMark from "./ExclamationMark";
import Wheel from "./Wheel";
import CarFromTop from "./CarFromTop";
import VanFromTop from "./VanFromTop";
import FileTypePDF from "./FileTypePDF";
import Copy from "./Copy";
import CalendarDots from "./CalendarDots";
import DownloadSquare from "./DownloadSquare";
import NotificationBell from "./NotificationBell";
import EnvelopeOpen from "./EnvelopeOpen";
import FuelPump from "./FuelPump";
import EVStation from "./EVStation";
import Speedometer from "./Speedometer";
import Gearbox from "./GearBox";
import EqualsCircle from "./EqualsCircle";
import Palette from "./Palette"
import Edit from "./Edit"
import Upload from "./Upload";
import AtSign from "./AtSign";
import Phone from "./Phone";
import Home from "./Home";
import EditPen from "./EditPen"
import List from "./List"
import ArrowUp from "./ArrowUp";

const obj = {
  include: Include,
  exclude: Exclude,
  "logo-white": LogoWhite,
  "logo-blue": LogoBlue,
  "logo-dn": LogoDN,
  search: Search,
  user: User,
  lock: Lock,
  "chevron-down": ChevronDown,
  "chevron-left": ChevronLeft,
  danger: Danger,
  cam360: Cam360,
  "car-4x4": Car4x4,
  "alert-circle": AlertCircle,
  "alert-circle-alt": AlertCircleAlt,
  "alert-circle-solid": AlertCircleSolid,
  "alert-triangle": AlertTriangle,
  "check-circle": CheckCircle,
  "chevron-right": ChevronRight,
  "chevron-up": ChevronUp,
  "car-convertible": CarConvertible,
  "arrow-download": ArrowDownload,
  envelope: Envelope,
  envelopeOpen: EnvelopeOpen,
  "error-circle": ErrorCircle,
  "car-estate": CarEstate,
  "filter-conical": FilterConical,
  "arrow-forward": ArrowForward,
  "car-hatchback": CarHatchback,
  picture: Picture,
  "info-circle": InfoCircle,
  "info-circle-solid": InfoCircleSolid,
  "list-view": ListView,
  "arrow-refresh": ArrowRefresh,
  "arrow-matches": ArrowMatches,
  "speech-bubble": SpeechBubble,
  "nav-burger": NavBurger,
  "car-pickup": CarPickup,
  printer: Printer,
  "question-circle": QuestionCircle,
  "car-saloon": CarSaloon,
  settings: Settings,
  "list-sort": ListSort,
  star: Star,
  stopwatch: Stopwatch,
  dash: Dash,
  trash: Trash,
  "action-circle-solid": ActionCircleSolid,
  dot: Dot,
  hammer: Hammer,
  purchase: Purchase,
  loading: Loading,
  stack: Stack,
  "logo-mfl": LogoMfl,
  "logo-seat": LogoSeat,
  "logo-vw": LogoVW,
  "logo-vwcv": LogoVWCV,
  calendar: Calendar,
  "add-note": AddNote,
  "view-note": ViewNote,
  "exclamation-mark": ExclamationMark,
  wheel: Wheel,
  "car-from-top": CarFromTop,
  "van-from-top": VanFromTop,
  pdf: FileTypePDF,
  copy: Copy,
  calendarDots: CalendarDots,
  downloadSquare: DownloadSquare,
  notificationBell: NotificationBell,
  fuelPump: FuelPump,
  evStation: EVStation,
  speedometer: Speedometer,
  gearbox: Gearbox,
  "equals-circle": EqualsCircle,
  upload: Upload,
  palette: Palette,
  edit: Edit,
  atSign: AtSign,
  phone: Phone,
  home: Home,
  editPen: EditPen,
  list: List,
  "arrow-up": ArrowUp,
};

export default obj;
