import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import VendorVehicleRecordPublishModal from "../../components/VendorVehicleRecordPublishModal";
import { setActiveConfirmModalOptions } from "../../../state/reducers/vendor-vehicle/genericActiveState";
import { vehicleMenuAction } from "../../../state/reducers/vendor-vehicle/vehicleDetails";
import { fetchVehicleRecordProfiles } from "../../../state/reducers//vendor-vehicle/profiles";

const VendorVehicleRecordPublishModalContainer = ({ className }) => {
  const dispatch = useDispatch();
  const vehicleReference = useSelector(
    (state) =>
      state.vendorVehicleRecord.genericActiveState.activeVehicleReference
  );
  const salesInformationData = useSelector(
    (state) => state.vendorVehicleRecord.salesInformation.data
  );
  const salesInformationFetching = useSelector(
    (state) => state.vendorVehicleRecord.salesInformation.isFetching
  );

  const profileInformationData = useSelector(
    (state) => state.vendorVehicleRecord?.profileSummary?.profiles
  );
  const profileInformationFetching = useSelector(
    (state) => state.vendorVehicleRecord?.profileSummary?.isFetching
  );

  const primaryInformationData = useSelector(
    (state) => state.vendorVehicleRecord?.vehicleDetails?.primaryDetails?.data
  );
  const primaryInformationFetching = useSelector(
    (state) =>
      state.vendorVehicleRecord?.vehicleDetails?.primaryDetails?.isFetching
  );

  useEffect(() => {
    if (
      vehicleReference &&
      !profileInformationData &&
      !profileInformationFetching
    ) {
      dispatch(fetchVehicleRecordProfiles(vehicleReference));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vehicleReference, profileInformationData, profileInformationFetching]);

  const onConfirmHandle = (publishData) => {
    const type = "publish";
    const data = {
      vehicleReference,
      action: type,
    };

    dispatch(setActiveConfirmModalOptions(type, data));
    dispatch(
      vehicleMenuAction({
        actionType: data.action,
        vehicleId: data.vehicleReference,
        actionData: publishData,
      })
    );
  };

  return (
    <VendorVehicleRecordPublishModal
      className={className}
      vehicleReference={vehicleReference}
      salesInformationData={salesInformationData}
      salesInformationFetching={salesInformationFetching}
      onConfirmHandle={onConfirmHandle}
      profileInformationData={profileInformationData}
      profileInformationFetching={profileInformationFetching}
      primaryInformationData={primaryInformationData}
      primaryInformationFetching={primaryInformationFetching}
    />
  );
};
export default VendorVehicleRecordPublishModalContainer;
