import VendorVehicleRecordGenericSuccessModal from "./VendorVehicleRecordGenericSuccessModal";
import styled from "styled-components";

const StyledVendorVehicleRecordGenericConfirmationModal = styled(VendorVehicleRecordGenericSuccessModal)`
  .confirmation-container {
    min-height: 40vh;
    max-height: 45vh;
    width: 75rem;
    margin: 0 3rem 0 3rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

export default StyledVendorVehicleRecordGenericConfirmationModal;
