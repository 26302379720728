import React, {Fragment} from "react";
import { useDispatch, useSelector } from "react-redux";
import { closeModal } from "../../../state/reducers/global";
import ModalInsert from "../../components/ModalInsert";
import Panel from "../../components/Panel";
import DragDropModal from "../../components/DragDropModal/DragDropModal";
import clientSettings from "../../../clientappsettings.json";
import { saveVehicleDocumentation } from "../../../state/reducers/vehicle";

const DragDropModalInsert = () => {
  const dispatch = useDispatch();

  const onClose = () => {
    dispatch(closeModal());
  };

  const data = useSelector(state => state.vehicle.documentsData);
  const jwtToken = useSelector(state => state.authentication.credentials);
  const vehicleReferenceId = useSelector((state) => state.vendorVehicleRecord.genericActiveState.activeVehicleReference);

const getDownloadLink = (vehicleDocumentId, documentTitle) => {
  return `${clientSettings.IMAGES_API_URL}/documents/${vehicleDocumentId}/${documentTitle}?jwtToken=${jwtToken}`;
};

const onSaveVehicleDocument = (data) => {
  dispatch(saveVehicleDocumentation(data));
};

  return (
    <Fragment>
      <ModalInsert>
          <Panel
            mobileFlex
            style={{ height: "100%", borderRadius: "0.5rem" }}
            actions={[
              {
                content: "Close",
                action: onClose,
                type: "ghost",
              },
            ]}
          >
            <DragDropModal data={data} vehicleReferenceId={vehicleReferenceId} onSaveVehicleDocument={onSaveVehicleDocument} getDownloadLink={getDownloadLink}/>
          </Panel>
      </ModalInsert>
    </Fragment>
  );
};

export default DragDropModalInsert;
