import {
    FetchActionItems,
  } from "../../api/Actions";

import { 
    ActionAreaEnum, 
    getActionAreaTypeFromDescription, 
    getActionAreaValueFromDescription 
} from "../../shared/utility";

export const FETCH_ACTION_ITEMS =
  "@actions/FETCH_ACTION_ITEMS";
export const FETCH_ACTION_ITEMS_SUCCESS =
  "@actions/FETCH_ACTION_ITEMS_SUCCESS";
export const FETCH_ACTION_ITEMS_ERROR =
  "@actions/FETCH_ACTION_ITEMS_ERROR";
export const CLEAR_ACTIONS_BY_AREA_TYPE =
   "@actions/CLEAR_ACTIONS_BY_AREA_TYPE";
export const CLEAR_ACTIONS =
    "@actions/CLEAR_ACTIONS";

export const fetchActionItems = (vehicleId, actionAreaDescription) => (dispatch, getState) => {
    dispatch({ type: FETCH_ACTION_ITEMS });
    dispatch(clearActionsByAreaType(getActionAreaTypeFromDescription(actionAreaDescription)));

    const actionsInstance = new FetchActionItems({
      params: {
        vehicleId: vehicleId,
        actionAreaType: getActionAreaValueFromDescription(actionAreaDescription)
      },
      credentials: getState().authentication.credentials,
    });
  
    actionsInstance.call().then(
      (response) => {
        dispatch(fetchActionItemsSuccess(getActionAreaTypeFromDescription(actionAreaDescription),response));
      },
      (err) => {
        dispatch(fetchActionItemsError(err));
      }
    );
  };
  
  const fetchActionItemsSuccess = (actionAreaKey, data) => {
    return {
      type: FETCH_ACTION_ITEMS_SUCCESS,
      payload: {
        actionAreaKey,
        data,
      },
    };
  };
  
  const fetchActionItemsError = (error) => {
    return {
      type: FETCH_ACTION_ITEMS_ERROR,
      payload: error,
    };
  };

export const clearActionsByAreaType = (actionAreaKey) => {
    return {
      type: CLEAR_ACTIONS_BY_AREA_TYPE,
      payload: {
        actionAreaKey, 
      },
    };
  };

export const clearActions = () => {
    return {
        type: CLEAR_ACTIONS,
    };
};
  

const getActionAreas = () => {
    const actions = {};
    for (const key in ActionAreaEnum) {
      if (ActionAreaEnum.hasOwnProperty(key)) {
        actions[key] = null; 
      }
    }
    return actions;
};
  

const initialState = {
    isFetching: false,
    error: null,
    actionAreas:{
        ...getActionAreas(),
    }
};

export const reducer = (state = initialState, action) => {
    switch (action.type) {
      case FETCH_ACTION_ITEMS:
        return {
          ...state,
            isFetching: true,
        };
      case FETCH_ACTION_ITEMS_SUCCESS:
        const { actionAreaKey, data } = action.payload;
            return {
                ...state,
                isFetching: false,
                actionAreas: {
                ...state.actionAreas,
                [actionAreaKey]: {
                    ...state.actionAreas[actionAreaKey],
                    ...data,
                },
            },
        };
      case FETCH_ACTION_ITEMS_ERROR:
        return {
          ...state,
            isFetching: false,
            error: action.payload,
        };
      case CLEAR_ACTIONS_BY_AREA_TYPE:
        return {
            ...state,
            actionAreas: {
            ...state.actionAreas,
            [action.payload.actionAreaKey]: {
                ...initialState.actionAreas[action.payload.actionAreaKey],
            },
            },
        };
      case CLEAR_ACTIONS:
            return {
              ...state,
              ...initialState,
            };
      default:
        return state;
    }
  };
  
  export default reducer;
  
  