import Api from "../Base";
import settings from "../../clientappsettings.json";

class Profiling extends Api {
  baseUrl = settings.VENDORS_URL;
  headers = {
    Authorization: `Bearer ${this.getCredentials()}`,
  };
}

export class FetchVehicleRecordProfiles extends Profiling {
    requestUrl = `/Profiling/Profiles/${this.getParams().vehicleId}`;
    formatResponseData(response) {
      return response?.data;
    }
  }